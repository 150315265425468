import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import CreditCardRow from '../../Rows/CreditCard/CreditCardRow.component';

import { useStyles } from './CreditCardTable.styles';

const CreditCardTable = ({ cards }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cellHeader}>
              Tarjeta principal
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Nombre en la tarjeta
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Vencimiento
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cards.map(card => (
            <CreditCardRow key={card.id} card={card} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreditCardTable;
