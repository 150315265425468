import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  medicalPlans: {
    biochemical: [],
    nutritional: [],
  },
};

const patientSlice = createSlice({
  name: 'wnPatient',
  initialState,
  reducers: {
    setWnPatientData: (state, action) => {
      state.data = action.payload;
    },
    setWnPatientBiochemicalPlans: (state, action) => {
      state.medicalPlans.biochemical = action.payload;
    },
    setWnPatientNutritionalPlans: (state, action) => {
      state.medicalPlans.nutritional = action.payload;
    },
  },
});

export const {
  setWnPatientData,
  setWnPatientBiochemicalPlans,
  setWnPatientNutritionalPlans,
} = patientSlice.actions;
export const WNPatientReducer = patientSlice.reducer;
