import { batch, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setEditableTarget, setWarningMode } from 'redux/slices/forms.slice';
import { useGetDeleteOfficeAffectedPatients } from 'hooks/nutritionist.hook';
import { nutritionistOfficesSelector } from '../../../redux/slices/user.selector';

const OfficesRow = ({ office }) => {
  const { refetch } = useGetDeleteOfficeAffectedPatients(office._id);
  const offices = useSelector(nutritionistOfficesSelector);
  const dispatch = useDispatch();

  const onEditHandler = () => {
    refetch();
    batch(() => {
      dispatch(setWarningMode('editable'));
      dispatch(setEditableTarget(office));
      dispatch(
        setModalStatus({
          name: 'AFFECTED_PATIENTS_WARNING',
          isActive: true,
        }),
      );
    });
  };

  const onDeleteHandler = () => {
    if (offices.length === 1) {
      dispatch(
        setModalStatus({
          name: 'AVOID_TO_DELETE_OFFICE_WARNING',
          isActive: true,
        }),
      );
      return;
    }
    refetch();
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'AFFECTED_PATIENTS_WARNING',
          isActive: true,
        }),
      );
      dispatch(setEditableTarget(office));
      dispatch(setWarningMode('delete'));
    });
  };

  return (
    <TableRow>
      {/* ---- ACCIONES ---- */}
      <TableCell align="center">
        <Tooltip title="Editar">
          <IconButton onClick={onEditHandler} size="small">
            <CreateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton onClick={onDeleteHandler} size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>

      {/* ---- NOMBRE ---- */}
      <TableCell align="center">
        <Typography variant="caption">{office.name}</Typography>
      </TableCell>

      {/* ---- MODALIDAD ---- */}
      <TableCell align="center">
        <Typography variant="caption">
          {
            {
              online: 'Online',
              onsite: 'Presencial',
              home: 'A domicilio',
            }[office.mode]
          }
        </Typography>
      </TableCell>

      {/* ---- DIRECCIÓN ---- */}
      <TableCell align="center">
        <Typography variant="caption">
          {!isEmpty(office?.address?.mapUrl)
            ? 'N/A'
            : office?.address?.fullAddress}
        </Typography>
      </TableCell>

      {/* ---- INSTRUCCIONES ---- */}
      <TableCell align="center">
        <Typography variant="caption">{office.address.mapUrl}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default OfficesRow;
