import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import AddOpeningHourOfficeScheduleForm from '../../../Forms/AddOpeningHourOfficeSchedule/AddOpeningHourOfficeScheduleForm.component';
import { wnUserEdtiableOfficeHoursSelector } from 'redux/slices/user/user.selector';

const EditNutritionstOfficeHoursPanel = () => {
  const schedule = useSelector(wnUserEdtiableOfficeHoursSelector);

  return (
    <Box>
      <AddOpeningHourOfficeScheduleForm
        mode="UPDATE"
        schedule={schedule.data}
      />
    </Box>
  );
};

export default EditNutritionstOfficeHoursPanel;
