import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import ServicesRow from '../../Rows/Services/ServicesRow.component';

import { useStyles } from './ServicesTable.styles';

const ServicesTable = ({ services }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell align="center" className={classes.header}>
              Acciones
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Nombre
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Duración
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Precio
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Modalidad
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Descripción
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map(service => (
            <ServicesRow key={service._id} service={service} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServicesTable;
