import { useForm } from 'react-hook-form';
import {
  WellnubTextInput,
  WellnubSelectInput,
  WellnubDateInput,
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import { useSignUp } from 'hooks/authentication.hook';

import { useStyles } from './SignUpForm.styles';

const GENDERS = [
  { id: 'gender-male', value: 'male', label: 'Hombre' },
  { id: 'gender-female', value: 'female', label: 'Mujer' },
];

const SignUpForm = () => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      name: '',
      lastName: '',
      email: '',
      // birthdate: new Date(),
      sex: '',
      password: '',
      passwordConfirm: '',
      professionalLicense: '',
      countryCode: '52',
      phoneNumber: '',
    },
  });

  const { mutate: signUp, isLoading } = useSignUp();

  const [password] = watch(['password']);

  const classes = useStyles();

  const onSubmitHandler = handleSubmit(data => {
    const { passwordConfirm, professionalLicense, ...restData } = data;
    signUp({
      ...restData,
      nutritionistProfile: {
        professionalLicense,
      },
    });
  });

  return (
    <form onSubmit={onSubmitHandler}>
      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Datos generales
        </Typography>
        <Grid container spacing={4}>
          {/* ---- NAME ---- */}
          <Grid item xs={12} sm={4}>
            <WellnubTextInput
              fullWidth
              name="name"
              type="text"
              label="Nombre"
              helperText="Escribe tu nombre"
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
              }}
            />
          </Grid>
          {/* ---- LAST NAME ---- */}
          <Grid item xs={12} sm={4}>
            <WellnubTextInput
              fullWidth
              type="text"
              name="lastName"
              label="Apellidos"
              helperText="Escribe tus apellidos"
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
              }}
            />
          </Grid>
          {/* ---- EMAIL ---- */}
          <Grid item xs={12} sm={4}>
            <WellnubTextInput
              fullWidth
              type="email"
              name="email"
              label="Email"
              helperText="Escribe tu correo electrónico"
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
              }}
            />
          </Grid>
          {/* ---- BIRTHDATE ---- */}
          <Grid item xs={12} sm={4}>
            {/*<WellnubDateInput*/}
            {/*  fullWidth*/}
            {/*  name="birthdate"*/}
            {/*  label="Fecha de nacimiento"*/}
            {/*  format="dd/MM/yyyy"*/}
            {/*  helperText="Escribe tu fecha de nacimiento"*/}
            {/*  control={control}*/}
            {/*  rules={{*/}
            {/*    required: 'Este campo es obligatorio',*/}
            {/*  }}*/}
            {/*/>*/}
          </Grid>
          {/* ---- PHONENUMBER ---- */}
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <WellnubCountryCodeInput
                  name="countryCode"
                  label="Región"
                  variant="standard"
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <WellnubNumberInput
                  fullWidth
                  name="phoneNumber"
                  label="Celular"
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio',
                    pattern: {
                      value: /[0-9]{10}/,
                      message: 'Escribe un número válido',
                    },
                  }}
                  format="## #### ####"
                  helperText="Celular de contacto a 10 dígitos"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* ---- GENDER ---- */}
          <Grid item xs={12} sm={4}>
            <WellnubSelectInput
              fullWidth
              name="sex"
              label="Sexo"
              control={control}
              options={GENDERS}
              rules={{
                required: 'Este campo es obligatorio',
              }}
              helperText="Selecciona tu sexo"
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Contraseña
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              name="password"
              type="password"
              label="Contraseña"
              rules={{
                required: 'Este campo es obligatorio',
                minLength: {
                  value: 6,
                  message: 'La contraseña debe de tener al menos 6 caracteres',
                },
              }}
              control={control}
              helperText="Escribe tu contraseña"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              name="passwordConfirm"
              type="password"
              label="Contraseña"
              rules={{
                required: 'Este campo es obligatorio',
                validate: value =>
                  value === password || 'Las contraseñas no coinciden',
              }}
              control={control}
              helperText="Escribe tu contraseña"
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitleNoSpace}>
          Cédula profesional
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          Te pedimos que nos compartas tu cédula profesional al ser una
          plataforma exclusiva para nutriólogos. Si por algún motivo aún no
          cuentas con ella, escribe “en trámite”.
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              name="professionalLicense"
              label="Cédula profesional"
              type="text"
              rules={{
                required: 'Este campo es obligatorio',
              }}
              control={control}
              helperText="Escribe tu cédula profesional"
            />
          </Grid>
        </Grid>
      </div>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          Registrarme
        </Button>
      </Box>
    </form>
  );
};

export default SignUpForm;
