import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import Button from '../../Button/Button.component';

import { useActivation } from 'hooks/patient.hook';

const ActivatePatientConfirmation = () => {
  const { _id } = useSelector(state => state.patient.data);

  const { mutate, isLoading } = useActivation();

  const onConfirmHandler = () => {
    mutate(_id);
  };

  return (
    <Box>
      <Typography>¿Confirmas que deseas activar a este paciente?</Typography>
      <Typography>Esta acción tomará uno de tus slots disponibles</Typography>
      <Box mt={2} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmHandler}
          loading={isLoading}
        >
          Confirmar
        </Button>
      </Box>
    </Box>
  );
};

export default ActivatePatientConfirmation;
