import { Box, Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setModalStatus } from "redux/slices/modal.slice";

const CompleteServicesWarningPanel = () => {
    const dispatch = useDispatch()

    const onConfirmHandler = () => {
        dispatch(
            setModalStatus({
                name: 'PROFILE_SERVICES',
                isActive: false,
            })
        );
    };

    return (
        <Box>
            <Typography variant="h6" style={{ textTransform: 'uppercase', textAlign: 'center' }}>Mis servicios</Typography>

            <Typography style={{ paddingTop: 8 }}>Debes agregar al menos un servicio para tener un perfil completo en Wellnub</Typography>

            <Box textAlign="center" mt={2}>
                <Button variant="contained" color="primary" onClick={onConfirmHandler}>Confirmar</Button>
            </Box>
        </Box>
    )
}

export default CompleteServicesWarningPanel;