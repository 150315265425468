import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';

import { Box, CircularProgress, Typography } from '@material-ui/core';

import { useImageUpload } from 'hooks/imageUpload.hook';

import { setPhotoBase64 } from 'redux/slices/cloud.slice';

import Button from '../Button/Button.component';
import Cropper from '../Cropper/Cropper.component';

import { useStyles } from './ImageUploader.styles';

const ImageUploader = ({ name }) => {
  const [isCroppable, setIsCroppable] = useState(false);

  const { path } = useSelector(state => state.cloud[name]);

  const dispatch = useDispatch();

  const { mutate: uploadImage, isLoading } = useImageUpload(name);

  const classes = useStyles({
    url: path,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      const [file] = acceptedFiles;
      const data = new FormData();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        dispatch(setPhotoBase64(reader.result));
      };
      data.append('file', file);
      data.append('upload_preset', 'sluw6zqk');
      uploadImage(data);
    },
  });

  const onOpenCropperHandler = () => {
    setIsCroppable(true);
  };

  const onCloseCropperHandler = () => {
    setIsCroppable(false);
  };

  return (
    <>
      <Box {...getRootProps()} className={classes.dropArea}>
        <Box>
          <input {...getInputProps()} />
        </Box>
        {isLoading && (
          <Box className={classes.progress}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!isLoading && (
          <Box className="placeholder">
            <Typography variant="body2">
              Click o arrastrar para subir una imagen
            </Typography>
          </Box>
        )}
        <Box className={classes.preview} />
      </Box>
      <Box pt={2} textAlign="right">
        <Button
          variant="outlined"
          color="primary"
          disabled={!path}
          onClick={onOpenCropperHandler}
        >
          Ajustar imagen
        </Button>
      </Box>
      {isCroppable && (
        <Cropper
          name={name}
          isOpen={isCroppable}
          closeHandler={onCloseCropperHandler}
        />
      )}
    </>
  );
};

export default ImageUploader;
