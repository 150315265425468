import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Box, Grid } from '@material-ui/core';

import { WellnubTextInput } from '@wellnub/web-common';

import { addMedicalProfileMedication } from 'redux/slices/forms.slice';

import Button from '../../Button/Button.component';

const AddMedicationForm = () => {
  const { control, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    const medication = getValues('medication');
    dispatch(addMedicalProfileMedication(medication));
    setTimeout(() => {
      setValue('medication.name', '');
      setValue('medication.dose', '');
      setValue('medication.timeUsing', '');
      setValue('medication.note', '');
    }, 100);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            name="medication.name"
            variant="outlined"
            label="Medicamento"
            control={control}
            helperText="Nombre del medicamento"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="medication.dose"
            label="Dosis"
            control={control}
            helperText="Dosis"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="medication.timeUsing"
            label="Tiempo c/medicamento"
            control={control}
            helperText="Tiempo con el medicamento"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            multiline
            rows={2}
            name="medication.note"
            variant="outlined"
            label="Nota"
            control={control}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button variant="outlined" color="primary" onClick={onSubmitHandler}>
          Agregar
        </Button>
      </Box>
    </>
  );
};

export default AddMedicationForm;
