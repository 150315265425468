import { wellnub } from '../wellnub.api';

export const requestSignIn = credentials =>
  wellnub.post('/v1/auth/login?role=nutritionist', credentials);

export const requestSignUp = data =>
  wellnub.post('/v1/nutritionist/user/register', data);

export const resetPassword = email =>
  wellnub.post('/v1/auth/tempPassword', email);

export const requestLoggedId = payload =>
  wellnub.patch('/v1/nutritionist/user/checkIn', payload);
