import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import { requestImageUpload } from 'network/services/imageUpload.service';

import { setPhotoPath } from 'redux/slices/cloud.slice';
import { useSnackbar } from 'notistack';
import { createFile, getBase64Image } from '../utils/helpers';

export const useImageUpload = key => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestImageUpload, {
    onSuccess: ({ data }) => {
      dispatch(setPhotoPath(data.imageURL));
    },
    onError: () => {
      enqueueSnackbar(
        'No se pudo subir la foto, intenta de nuevo con una foto distinta',
        {
          variant: 'warning',
        },
      );
    },
  });
};
