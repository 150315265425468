import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const serviceListSlice = createSlice({
  name: 'serviceList',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setList } = serviceListSlice.actions;

export const serviceListReducer = serviceListSlice.reducer;
