import { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    eventWrapper: {
      fontSize: '14px !important',
      color: props => {
        if (props.next === 'nutritionist' && ['pending', 'rescheduled'].includes(props.status)) {
          return '#0b1015 !important';
        }
        return '#fafafa !important';
      },
      border: props => {
        if (props.next === 'nutritionist' && ['pending', 'rescheduled'].includes(props.status)) {
          return `1px solid ${
            props.variant === 'online'
              ? '#377990 !important'
              : '#409037 !important'
          }`;
        }
        return 'none';
      },
      backgroundColor: props => {
        if (props.status === 'accepted') {
          return props.variant === 'online'
            ? '#377990 !important'
            : '#409037 !important';
        }
        if (props.next === 'client' && ['pending', 'rescheduled'].includes(props.status)) {
          return props.variant === 'online'
            ? '#9bc4d2 !important'
            : '#94c691 !important';
        }
        return 'initial !important';
      },
      transition: 'all linear 100ms !important',
      '&:hover': {
        cursor: 'pointer !important',
      },
    },
  };
});

const CalendarEventWrapper = ({ children, event }) => {
  const classes = useStyles({
    variant: event?.mode,
    createdBy: event?.createdByRole,
    status: event?.status,
    next: event?.nextActionRole,
  });

  return cloneElement(children, {
    className: `${children.props.className} ${classes.eventWrapper}`,
  });
};
export default CalendarEventWrapper;