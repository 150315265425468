import { useMutation } from 'react-query';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { wellnub } from 'network/wellnub.api';
import {
  requestLoggedId,
  requestSignIn,
  requestSignUp,
  resetPassword,
} from 'network/services/authentication.service';

import { setAuthentication } from 'redux/slices/authentication.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import {
  setUserId,
  setUserStatus,
  setFistSignIn,
  setUserIsProfileComplete,
} from 'redux/slices/user.slice';

// ---- SOLICITAR INICIO DE SESIÓN ----
export const useSignIn = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestSignIn, {
    onSuccess: ({ data }) => {
      wellnub.defaults.headers.common.Authorization = `Bearer ${data.token.accessToken}`;
      localStorage.setItem('accessToken', data.token.accessToken);
      localStorage.setItem('refreshToken', data.token.refreshToken);
      localStorage.setItem('authentication', 'true');
      localStorage.setItem('status', data.user.status);
      localStorage.setItem('userID', data.user.id);
      localStorage.setItem('email', data.user.email);
      localStorage.setItem('isFirstSignIn', !data.user.hasLoggedIn);
      localStorage.setItem('isProfileCompleted', data.user.isProfileCompleted);
      batch(() => {
        dispatch(setAuthentication(true));
        dispatch(setUserStatus(data.user.status));
        dispatch(setUserId(data.user.id));
        dispatch(setFistSignIn(!data.user.hasLoggedIn));
        dispatch(setUserIsProfileComplete(data.user.isProfileCompleted));
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        });
      }
    },
  });
};

// ---- SOLICITA CREAR UN NUEVO REGISTRO ----
export const useSignUp = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { replace } = useHistory();

  return useMutation(requestSignUp, {
    onSuccess: ({ data }) => {
      replace('/thankyou');
      // enqueueSnackbar(
      //   'Registro exitoso, tu cuenta será verificada, recibirás un email cuando esté lista',
      //   {
      //     variant: 'success',
      //   },
      // );

      // wellnub.defaults.headers.common.Authorization = `Bearer ${data.token.accessToken}`;
      // localStorage.setItem('accessToken', data.token.accessToken);
      // localStorage.setItem('authentication', 'true');
      // localStorage.setItem('status', data.user.status);
      // localStorage.setItem('userID', data.user.id);
      // dispatch(setAuthentication(true));
      // dispatch(setUserStatus(data.user.status));
      // dispatch(setUserId(data.user.id));
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        });
      } else {
        enqueueSnackbar('Algo salió mal. Intenta más tarde', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        });
      }
    },
  });
};

// ---- SOLICITAR RESTABLECER CONTRASEÑA ----
export const useResetPassword = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(resetPassword, {
    onSuccess: ({ data }) => {
      enqueueSnackbar(
        'Se ha enviado al correo registrado una contraseña temporal para iniciar sesión',
        {
          variant: 'success',
        },
      );
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'PASSWORD_RESET',
          isActive: false,
        }),
      );
    },
  });
};

// ---- SOLICITAR CAMBIO DE ESTATUS POR PRIMER INICIO DE SESIÓN ----
export const useHasLoggedIn = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { replace } = useHistory();

  return useMutation(requestLoggedId, {
    onSuccess: () => {
      dispatch(setFistSignIn(false));
      enqueueSnackbar(
        'Tus preferencias se han actualizado, puedes usar tu cuenta con normalidad',
        {
          variant: 'success',
        },
      );
      replace('/dashboard/patients');
    },
  });
};
