import { Box, Drawer, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import AddCardForm from '../../Forms/AddCard/AddCardForm.component';

import { useDrawer } from 'hooks/drawers.hook';

import { DRAWERS } from 'utils/constants';

import { useStyles } from './CreditCardDrawer.styles';

const CreditCardDrawer = () => {
  const { visibility, handleClose } = useDrawer(DRAWERS.addCard);

  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Typography
          variant="h5"
          className={`${classes.title} ${classes.center}`}
        >
          Agrega una nueva tarjeta
        </Typography>
      </Box>
      <Box pt={4}>
        <AddCardForm />
      </Box>
    </Drawer>
  );
};

export default CreditCardDrawer;
