import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import { AppBar, Tab, Tabs, useMediaQuery } from '@material-ui/core';

import { useStyles } from './SettingsNavigation.styles';

const ProfileNavigation = () => {
  const { profile } = useSelector(state => state.user);

  const [shouldBeDisabled, setShouldBeDisabled] = useState(false);

  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const isMobileSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles({
    isMobileSize,
  });

  const getTabValue = () => {
    if (pathname.includes('profile')) return 'profile';
    if (pathname.includes('virtual-agenda')) return 'virtual-agenda';
    return 'profile';
  };

  useEffect(() => {
    if (!isEmpty(profile)) {
      const {
        nutritionistProfile: { services },
        hasLoggedIn,
      } = profile;
      const hasToCompleteProfile = isEmpty(services);
      setShouldBeDisabled(hasToCompleteProfile || !hasLoggedIn);
    }
  }, [profile]);

  return (
    <AppBar
      position="static"
      color="transparent"
      classes={{ root: classes.appBar }}
    >
      <Tabs
        variant={isMobileSize ? 'scrollable' : 'fullWidth'}
        scrollButtons={isMobileSize ? 'on' : 'off'}
        value={getTabValue()}
        indicatorColor="primary"
      >
        <Tab
          value="profile"
          component={Link}
          label="Mi perfil"
          to={`${url}/profile`}
          classes={{
            wrapper: classes.tab,
          }}
        />
        {/*{profile.nutritionistProfile.canUseAgenda && (*/}
        <Tab
          value="virtual-agenda"
          component={Link}
          label="Agenda Virtual"
          disabled={!profile.nutritionistProfile.canUseAgenda}
          to={`${url}/virtual-agenda`}
          classes={{
            wrapper: classes.tab,
          }}
        />
        {/*)}*/}
      </Tabs>
    </AppBar>
  );
};

export default ProfileNavigation;
