import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Button, TabPanel } from 'components';

import { usePatients } from 'hooks/patient.hook';
import {
  useGetLinkRequests,
  usePasses,
  usePlanUsers,
} from 'hooks/nutritionist.hook';

import { setModalStatus } from 'redux/slices/modal.slice';
import { getInvitationsBadge } from 'redux/slices/invitations/invitations.selector';
import { getActivePatientsBadgeSelector } from 'redux/slices/patients/patients.selector';

import { ActivePatientsPage } from './Active/ActivePatients.page';
import { InvitationsPage } from './Invitations/Invitations.page';
import { InactivePatientsPage } from './Inactive/InactivePatients.page';

import { useStyles } from './Patients.styles';
import { useGetNutritionistAppointments } from '../../hooks/virtualAgenda.hook';
import {
  setIsAddedFromVirtualAgenda,
  setPatientDataForRegister,
} from '../../redux/slices/patient.slice';

const PatientsPage = () => {
  const hasInvitationsTabBadge = useSelector(getInvitationsBadge);
  const hasActivePatientsTabBadge = useSelector(getActivePatientsBadgeSelector);

  const { isLoading, isError } = usePatients();

  const {
    data: planResponse,
    isLoading: isPlanLoading,
    isError: isErrorPlan,
  } = usePlanUsers();

  const {
    data: passes,
    isLoading: isLoadingPasses,
    isError: isErrorPasses,
  } = usePasses();

  const { isLoading: isLoadingRequests, isError: isErrorRequests } =
    useGetLinkRequests();

  const {
    isLoading: isLoadingNutritionistAppointments,
    isError: isErrorOnNutritionistAppointmens,
  } = useGetNutritionistAppointments();

  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();

  const { push } = useHistory();

  const classes = useStyles({
    hasInvitationsTabBadge,
    hasActivePatientsTabBadge,
  });

  const onAddPatientHandler = event => {
    event.preventDefault();
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'PATIENT_VERIFICATION',
          isActive: true,
        }),
      );
      dispatch(setIsAddedFromVirtualAgenda(false));
      dispatch(setPatientDataForRegister({}));
    });
  };

  const onRedirectPurchaseHandler = () => {
    push('/settings/profile/market/passes');
  };

  const onChangeTabHandler = (_, newValue) => {
    setActiveTab(newValue);
  };

  if (
    isLoading ||
    isPlanLoading ||
    isLoadingPasses ||
    isLoadingRequests ||
    isLoadingNutritionistAppointments
  ) {
    return (
      <Box pt={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (
    isError ||
    isErrorPasses ||
    isErrorPlan ||
    isErrorRequests ||
    isErrorOnNutritionistAppointmens
  ) {
    return (
      <Box pt={4} textAlign="center">
        <Typography>No se ha podido cargar la información</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Mis pacientes
      </Typography>
      <Box py={2}>
        <Typography className={classes.center}>
          Aquí podrás administrar tus pacientes. Agrega nuevos pacientes, dalos
          de alta o de baja, asígnales análisis Wellnub - InBody&reg;{' '}
          <span className={classes.block}>
            y entra a ver el detalle y progreso de cada paciente
          </span>
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        component="section"
        className={`${classes.section} ${classes.center}`}
      >
        <Grid
          item
          md={6}
          sm={6}
          xs={12}
          className={` ${classes.flexCenter} ${classes.flexColumn}`}
        >
          <Typography
            variant="h6"
            component="h3"
            className={`${classes.uppercase} ${classes.subtitle}`}
          >
            Pases Wellnub-InBody&reg; de escaneo corporal disponibles
          </Typography>
          <Box className={classes.alertWrapper}>
            <Avatar
              className={classes.avatar}
              onClick={onRedirectPurchaseHandler}
            >
              {passes.data.activePasses}
            </Avatar>
            {passes.data.activePasses <= 2 && (
              <Alert severity="warning" className={classes.alert}>
                {passes.data.activePasses === 0
                  ? 'Tus pases se han agotado'
                  : 'Tus pases están por agotarse'}
                , compra más{' '}
                <span
                  className={classes.asLink}
                  onClick={onRedirectPurchaseHandler}
                >
                  aquí
                </span>
              </Alert>
            )}
            {!isEmpty(passes.data.returnedPasses) && (
              <Grid container spacing={1} style={{ marginTop: 16 }}>
                {passes.data.returnedPasses.map(item => (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Te hemos devuelto un pase de {item.patient.name}{' '}
                      {item.patient.lastName}
                    </Alert>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid item md={6} sm={6} xs={12} className={classes.flexCenter}>
          <Button
            variant="contained"
            color="primary"
            onClick={onAddPatientHandler}
          >
            Agregar paciente
          </Button>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <AppBar position="static" classes={{ root: classes.appbar }}>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={onChangeTabHandler}
          indicatorColor="primary"
          classes={{ root: classes.tabs }}
        >
          <Tab
            label="Pacientes Activos &nbsp;"
            classes={{
              root: classes.tab,
              selected: classes.selected,
              wrapper: classes.withActiveTabBadge,
            }}
          />
          <Tab
            label="Solicitudes Pendientes &nbsp;"
            classes={{
              root: classes.tab,
              selected: classes.selected,
              wrapper: classes.withInvitationsTabBadge,
            }}
          />
          <Tab
            label="Pacientes Inactivos"
            classes={{ root: classes.tab, selected: classes.selected }}
          />
        </Tabs>
      </AppBar>

      <Box component="section" mt={4}>
        <TabPanel value={activeTab} index={0}>
          <ActivePatientsPage
            current={planResponse.data.activatedUsers}
            total={planResponse.data.usersInPlan}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <InvitationsPage />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <InactivePatientsPage />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default PatientsPage;