import { useMemo } from 'react';
import { useStyles } from './Notification.styles';
import { Avatar, Typography } from '@material-ui/core';
import { NOTIFICATION_TYPES } from 'utils/constants';
import EventIcon from '@material-ui/icons/Event';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { useHistory } from 'react-router-dom';
import { useMarkAsSeenNotification } from '../../hooks/nutritionist.hook';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WarningIcon from '@material-ui/icons/Warning';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
const Notification = ({ notification }) => {
  const classes = useStyles({
    actionable: notification.actionable,
    wasSeen: notification.wasSeen,
  });

  const { replace } = useHistory();

  const { mutate: markAsSeen } = useMarkAsSeenNotification();

  const Icon = useMemo(
    () =>
      ({
        [NOTIFICATION_TYPES.APPOINTMENT.RESCHEDULE]: EventIcon,
        [NOTIFICATION_TYPES.APPOINTMENT.CANCEL]: EventBusyIcon,
        [NOTIFICATION_TYPES.INBODY.NEW]: AssessmentIcon,
        [NOTIFICATION_TYPES.INBODY.MISSED]: AssignmentLateIcon,
        [NOTIFICATION_TYPES.PATIENT.INBODY_PASS]: LocationOnIcon,
        [NOTIFICATION_TYPES.PATIENT.RESCHEDULE_PASS]: EventNoteIcon,
        [NOTIFICATION_TYPES.GENERAL.ADD_PASSES]: AddCircleIcon,
        [NOTIFICATION_TYPES.REQUESTS.LINK]: GroupAddIcon,
        [NOTIFICATION_TYPES.ADMIN.PARTNER_LOW_STOCK]: WarningIcon,
        [NOTIFICATION_TYPES.ADMIN.NEW_NUTRITIONIST]: PersonAddIcon,
        [NOTIFICATION_TYPES.ADMIN.ACTIVATED_NUTRITIONIST]: VerifiedUserIcon,
        [NOTIFICATION_TYPES.ADMIN.COMPLETED_NUTRITIONIST]: GroupAddIcon,
      }[notification.type] || AnnouncementIcon),
    [notification],
  );

  const onClickHandler = () => {
    if (!notification.actionable) {
      return;
    }
    switch (notification.type) {
      case NOTIFICATION_TYPES.REQUESTS.LINK:
        replace('/dashboard/patients');
        markAsSeen(notification._id);
        break;
      case NOTIFICATION_TYPES.INBODY.NEW:
        replace(`/dashboard/patients/${notification.patient}/progress/inbody`);
        markAsSeen(notification._id);
        break;
      case NOTIFICATION_TYPES.APPOINTMENT.RESCHEDULE:
        replace('/dashboard/virtual-agenda');
        markAsSeen(notification._id);
        break;
      default:
        break;
    }
  };

  return (
    <article
      className={`${classes.notification} ${
        notification.actionable && classes.actionable
      }`}
      onClick={onClickHandler}
    >
      <Avatar className={classes.avatar}>
        <Icon style={{ fontSize: 20 }} />
      </Avatar>
      <Typography variant="body2">{notification.text}</Typography>
    </article>
  );
};

export default Notification;