import { object, string } from 'yup';

const PHONE_NUMBER_FORMAT_VALIDATION = /[0-9]{10}/;
const ERROR_MESSAGE = 'Este campo es requerido';

export const PatientCreationFormSchema = object({
  name: string().required(ERROR_MESSAGE),
  lastName: string().required(ERROR_MESSAGE),
  email: string().required(ERROR_MESSAGE),
  phoneNumber: string()
    .matches(PHONE_NUMBER_FORMAT_VALIDATION, 'Escribe un número válido')
    .required(ERROR_MESSAGE),
});
