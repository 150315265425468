import { number, object, string, array } from 'yup';

const REQUIRED_MESSAGE = 'Este campo es obligatorio';

// addMethod(string, 'isExpertiseEmpty', function (errorMessage) {
//   return this.test(
//     'test-is-expertise-empty',
//     errorMessage,
//     function (expertise) {
//       const { createError, path } = this;
//       console.log('Expertise: ', expertise);
//       return (
//         (expertise && expertise.length > 0) ||
//         createError({ path, message: errorMessage })
//       );
//     },
//   );
// });

export const FormSchema = object({
  professionalLicense: string().required(REQUIRED_MESSAGE),
  mainDegree: string().required('Debes de ingresar un título principal'),
  expertise: object({
    title: string(),
    school: string(),
    graduationYear: string().required(REQUIRED_MESSAGE),
  }),
  certification: object({
    title: string(),
    school: string(),
  }),
  types: array().min(1, 'Debes seleccionar al menos una opción'),
  diets: array().min(1, 'Debes seleccionar al menos una opción'),
  specialities: array()
    .min(1, 'Debes seleccionar al menos una opción')
    .max(5, 'Solo puedes seleccionar hasta 5 especialidades'),
});
