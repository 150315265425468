import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setInvitations: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setInvitations } = invitationsSlice.actions;

export const InvitationsReducer = invitationsSlice.reducer;
