import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import { compareDesc } from 'date-fns';

import {
  Box,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { PatientList } from 'components';

import { getActivePatientsSelector } from 'redux/slices/patients/patients.selector';

const useStyles = makeStyles(theme => ({
  helperBox: {
    padding: theme.spacing('5px', 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.primary.light}`,
  },
  searchInput: {
    transition: 'transform ease 150ms',
    transformOrigin: 'left',
    transform: ({ isSearchActive }) =>
      isSearchActive ? 'scaleX(1)' : 'scaleX(0)',
  },
  searchText: {
    marginTop: '-16px',
  },
}));

export const ActivePatientsPage = ({ current, total }) => {
  const [list, setList] = useState([]);
  const [order, setOrder] = useState('alphabetic');
  const [isSearchActive, setIsSearchActive] = useState(false);

  const [activeSearch, setActiveSearch] = useState('');

  const patients = useSelector(getActivePatientsSelector);

  const classes = useStyles({ isSearchActive });

  const searchChangeHandler = event => {
    setActiveSearch(event.target.value);
  };

  const onChangeOrderHandler = event => {
    setOrder(event.target.value);
  };

  const onSearchClickHandler = () => {
    setIsSearchActive(prevState => !prevState);
  };

  const filterList = useCallback(
    (list, searchParams) =>
      list.filter(
        item =>
          item.phoneNumber.includes(searchParams) ||
          item.name.toLowerCase().includes(searchParams.toLowerCase()) ||
          item.lastName.toLowerCase().includes(searchParams.toLowerCase()),
      ),
    [],
  );

  useEffect(() => {
    if (order === 'recent') {
      const orderedList = [...list].sort((a, b) =>
        compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
      );
      setList(orderedList);
    }
    if (order === 'alphabetic') {
      const orderedList = [...list].sort((a, b) => {
        if (a.lastName > b.lastName) return 1;
        if (a.lastName < b.lastName) return -1;
        return 0;
      });
      setList(orderedList);
    }
  }, [order]);

  useEffect(() => {
    if (order === 'recent') {
      const orderedList = [...patients].sort((a, b) =>
        compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
      );
      setList(orderedList);
    }
    if (order === 'alphabetic') {
      const orderedList = [...patients].sort((a, b) => {
        if (a.lastName > b.lastName) return 1;
        if (a.lastName < b.lastName) return -1;
        return 0;
      });
      setList(orderedList);
    }
  }, [patients]);

  useEffect(() => {
    if (activeSearch) {
      const searchList = filterList(patients, activeSearch);
      setList(searchList);
    } else {
      if (order === 'recent') {
        const orderedList = [...patients].sort((a, b) =>
          compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
        );
        setList(orderedList);
      }
      if (order === 'alphabetic') {
        const orderedList = [...patients].sort((a, b) => {
          if (a.lastName > b.lastName) return 1;
          if (a.lastName < b.lastName) return -1;
          return 0;
        });
        setList(orderedList);
      }
    }
  }, [activeSearch]);

  return (
    <>
      {isEmpty(patients) && (
        <Box className={classes.helperBox}>
          <Typography variant="body2">Aún no hay pacientes activos</Typography>
        </Box>
      )}
      {!isEmpty(patients) && (
        <>
          <Grid container style={{ marginBottom: 16 }}>
            <Grid
              item
              xs={6}
              sm={6}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                style={{
                  width: 'max-content',
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    paddingBottom: 8,
                    width: 'max-content',
                  }}
                >
                  ({current} de {total} pacientes en tu plan)
                </Typography>
              </Box>
              <Box
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  <IconButton onClick={onSearchClickHandler}>
                    <SearchIcon />
                  </IconButton>
                  <Typography variant="caption" className={classes.searchText}>
                    Buscar
                  </Typography>
                </Box>
                <TextField
                  className={classes.searchInput}
                  fullWidth
                  variant="outlined"
                  label="Buscar paciente"
                  onChange={searchChangeHandler}
                />
              </Box>
            </Grid>
            <Grid item xs sm>
              &nbsp;
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="order-select">Ordenar</InputLabel>
                <Select
                  label="Ordenar"
                  labelId="order-select"
                  value={order}
                  onChange={onChangeOrderHandler}
                >
                  <MenuItem value="recent">Mas recientes</MenuItem>
                  <MenuItem value="alphabetic">Orden A-Z</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <PatientList patients={list} />
        </>
      )}
    </>
  );
};
