export const palette = {
  type: 'light',
  primary: {
    dark: '#176363',
    main: '#2c7979',
    light: '#559898',
  },
  secondary: {
    dark: '#eceeec',
    main: '#f2faf4',
    light: '#ffffff',
  },
  action: {
    main: '#377990',
    light: '#82b5c3',
  },
  text: {
    primary: '#464b51',
    dark: '#0b1015',
  },
};
