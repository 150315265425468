import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '../../../../redux/slices/modal.slice';

const VirtualAgendaRestrictionWarningPanel = () => {
  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: 'VIRTUAL_AGENDA_RESTRICTION_WARNING',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="h6" align="center">
        Tu agenda virtual se encuentra desactivada
      </Typography>
      <Typography variant="body2" align="center">
        Contacta al administrador para mas detalles.
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <Button variant="contained" color="primary" onClick={onAcceptHandler}>
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default VirtualAgendaRestrictionWarningPanel;
