import { boolean, date, object } from 'yup';

const REQUIRED_MESSAGE = 'Este campo es obligatorio';

export const FormSchema = object({
  startTime: date().required(REQUIRED_MESSAGE),
  endTime: date()
    .default(null)
    .when(
      'startTime',
      (start, yup) =>
        start &&
        yup.min(start, 'La hora de fin no puede ser menor a la de inicio'),
    )
    .required(REQUIRED_MESSAGE),
  date: date().required(REQUIRED_MESSAGE),
  isAllDay: boolean().oneOf([true, false]),
});
