import { useForm, Controller } from 'react-hook-form';

import NumberFormat from 'react-number-format';

import {
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

import Button from '../../../Button/Button.component';

import { useUpdate } from 'hooks/patient.hook';

const PatientDetailsForm = ({ editable, patient }) => {
  const { control, handleSubmit } = useForm();

  const { mutate: update, isLoading: isLoadingUpdate } = useUpdate();

  const onSubmitHandler = data => {
    const { email, ...rest } = data;
    update({
      id: patient._id,
      data: rest,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            defaultValue={patient?.name ?? ''}
            rules={{
              required: 'Este campo es obligatorio',
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Nombre"
                color="primary"
                variant="outlined"
                value={value}
                error={!!error}
                disabled={!editable}
                onChange={onChange}
                helperText={error ? error.message : 'Nombre de tu paciente'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            defaultValue={patient?.lastName ?? ''}
            rules={{
              required: 'Este campo es obligatorio',
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Apellido"
                color="primary"
                variant="outlined"
                value={value}
                error={!!error}
                disabled={!editable}
                onChange={onChange}
                helperText={error ? error.message : 'Apellido de tu paciente'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue={patient?.phoneNumber ?? ''}
            rules={{
              pattern: {
                value: /[0-9]{10}/g,
                message: 'Escribe un número válido',
              },
              required: 'Este campo es obligatorio',
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <NumberFormat
                fullWidth
                label="Celular"
                variant="outlined"
                format="## #### ####"
                value={value}
                error={!!error}
                disabled={!editable}
                customInput={TextField}
                onValueChange={e => onChange(e.value)}
                helperText={
                  error ? error.message : 'Teléfono de contacto a 10 dígitos'
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            control={control}
            defaultValue={patient?.email ?? ''}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                disabled
                fullWidth
                label="Email"
                color="primary"
                variant="outlined"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Correo electrónico'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="sexo">Sexo</InputLabel>
            <Controller
              name="gender"
              control={control}
              defaultValue={patient?.sex ?? ''}
              render={({ field: { onChange, value } }) => (
                <Select
                  native
                  label="Sexo"
                  value={value}
                  onChange={onChange}
                  disabled={!editable}
                >
                  <option aria-label="none" value="" />
                  <option value="male">Hombre</option>
                  <option value="female">Mujer</option>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="birthday"
            defaultValue={patient?.birthday || new Date()}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disabled={!editable}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  label="Fecha de nacimiento"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={
                    error ? error.message : 'Fecha de nacimiento de tu paciente'
                  }
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="height"
            control={control}
            defaultValue={patient?.height ?? 0}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="number"
                label="Altura"
                color="primary"
                variant="outlined"
                value={value}
                error={!!error}
                onChange={onChange}
                disabled={!editable}
                inputProps={{
                  min: 0,
                  max: 250,
                }}
                helperText={
                  error ? error.message : 'Altura de tu paciente en centímetros'
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Box pt={4}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          loading={isLoadingUpdate}
          disabled={!editable}
        >
          Actualizar datos
        </Button>
      </Box>
    </form>
  );
};

export default PatientDetailsForm;
