import { Box, Button, Grid } from '@material-ui/core';
import { WellnubDateInput, WellnubTimeInput } from '@wellnub/web-common';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { set } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_SCHEMA } from './EditOfficeScheduleDaysOffForm.schema';
import { useEditNutritionistOfficeDayOff } from 'hooks/nutritionist.hook';

const EditOfficeScheduleDaysOffForm = () => {
  const dayOff = useSelector(state => state.forms.profile.target);

  const { mutate: updateDayOff, isLoading } = useEditNutritionistOfficeDayOff();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      startTime: dayOff ? new Date(dayOff.startDate) : new Date(),
      endTime: dayOff ? new Date(dayOff.endDate) : new Date(),
      day: dayOff ? new Date(dayOff.endDate) : new Date(),
    },
  });

  const onSubmitHandler = handleSubmit(data => {
    const payload = {
      id: dayOff._id,
      data: {
        startDate: set(data.day, {
          minutes: data.startTime.getMinutes(),
          hours: data.startTime.getHours(),
        }),
        endDate: set(data.day, {
          minutes: data.endTime.getMinutes(),
          hours: data.endTime.getHours(),
        }),
      },
    };
    updateDayOff(payload);
  });

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item xs={12} sm={4}>
          <WellnubTimeInput
            fullWidth
            control={control}
            name="startTime"
            label="Hora inicio"
            inputVariant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WellnubTimeInput
            fullWidth
            control={control}
            name="endTime"
            label="Hora fin"
            inputVariant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WellnubDateInput
            fullWidth
            control={control}
            name="day"
            format="dd/MM/yyyy"
            label="Día del año"
            inputVariant="outlined"
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="center">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Actualizar
        </Button>
      </Box>
    </form>
  );
};

export default EditOfficeScheduleDaysOffForm;
