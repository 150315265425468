import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Grid, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { useSignIn } from 'hooks/authentication.hook';

import Button from '../../Button/Button.component';

import { useStyles } from './SignInForm.styles';

const SignInForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { mutate, isLoading } = useSignIn();

  const { control, handleSubmit } = useForm();

  const classes = useStyles();

  const onSubmitHandler = credentials => {
    mutate(credentials);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={4} className={classes.grid}>
        <Grid item xs={12}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{
              required: 'Ingresa el correo electrónico para poder continuar',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Escribe un correo válido',
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                error={!!error}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            defaultValue=""
            control={control}
            rules={{
              required: 'Ingresa la contraseña para poder continuar',
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Contraseña"
                type={isPasswordVisible ? 'text' : 'password'}
                variant="outlined"
                error={!!error}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : ''}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setIsPasswordVisible(prevState => !prevState)
                      }
                    >
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        loading={isLoading}
      >
        Iniciar sesión
      </Button>
    </form>
  );
};

export default SignInForm;
