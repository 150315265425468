import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Box, Grid } from '@material-ui/core';

import { WellnubTextInput, WellnubSelectInput } from '@wellnub/web-common';

import { addMedicalProfileCondition } from 'redux/slices/forms.slice';

import Button from '../../Button/Button.component';

const SELECT_OPTIONS = [
  {
    id: 'medical-condition-0',
    value: 'Presente en paciente',
    label: 'Presente en paciente',
  },
  {
    id: 'medical-condition-1',
    value: 'Antecedente familiar',
    label: 'Antecedente familiar',
  },
];

const AddMedicalConditionForm = () => {
  const { control, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    const condition = getValues('condition');
    dispatch(addMedicalProfileCondition(condition));
    setTimeout(() => {
      setValue('condition.name', '');
      setValue('condition.affected', '');
      setValue('condition.note', '');
    }, 100);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            name="condition.name"
            variant="outlined"
            label="Condición"
            control={control}
            helperText="Nombre de la condición"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubSelectInput
            fullWidth
            variant="outlined"
            name="condition.affected"
            label="Paciente"
            options={SELECT_OPTIONS}
            control={control}
            helperText="Selecciona una opción"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            fullWidth
            multiline
            rows={2}
            name="condition.note"
            variant="outlined"
            label="Nota"
            control={control}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button variant="outlined" color="primary" onClick={onSubmitHandler}>
          Agregar
        </Button>
      </Box>
    </>
  );
};

export default AddMedicalConditionForm;
