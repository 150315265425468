import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, batch } from 'react-redux';

import { useSnackbar } from 'notistack';

import {
  getPatient,
  getPatients,
  requestCreation,
  requestVerification,
  requestAddPass,
  requestUpdate,
  requestActivation,
  requestInactivation,
  requestLinkPatient,
  requestAddNutritionalPlan,
  requestGetNutritionalPlans,
  requestDeleteNutritionalPlan,
  requestGetBiochemicalPlans,
  requestDeleteBiochemicalPlan,
  requestAddBiochemicalPlan,
  getGeneralInstructions,
  patchUpdatePatientBasicInfo,
} from 'network/services/patient.service';

import {
  setPatientData,
  setPatientRegisterStatus,
  setPatientGeneralProfile,
  setPatientLifeStyleProfile,
  setPatientDieteticProfile,
  setPatientMedicalProfile,
  setNutritionalPlans,
  setBiochemicalPlans,
} from 'redux/slices/patient.slice';

import { setModalStatus } from 'redux/slices/modal.slice';

import {
  setLifeStyleSports,
  setMedicalProfileConditions,
  setMedicalProfileMedications,
  setMedicalProfileSurgeries,
  setMedicalProfileOralContraceptives,
  setMedicalProfileHormonalContraceptives,
  setDietProfileFavFood,
  setDietProfileUnfavFood,
  setDietProfileDefaultDiets,
  setDietProfileDefaultAlergies,
  setDietProfileDefaultIntolerances,
  setDietProfileSupplements,
  setMedicalProfileDefaultMeals,
} from 'redux/slices/forms.slice';
import { setPatients } from '../redux/slices/patients/patients.slice';

import {
  setWnPatientBiochemicalPlans,
  setWnPatientData,
  setWnPatientNutritionalPlans,
} from 'redux/slices/patient/patient.slice';
// ---- TRAE LA INFORMACIÓN ESPECÍFICA DEL PACIENTE ----
export const usePatient = id => {
  const dispatch = useDispatch();
  return useQuery(['GET_PATIENT', id], () => getPatient(id), {
    enabled: !!id,
    onSuccess: ({ data }) => {
      dispatch(setWnPatientData(data));

      const {
        dietProfile = {},
        lifeStyle = {},
        medicalProfile = {},
        name,
        lastName,
        email,
        phoneNumber,
        gender,
        birthdate,
        address,
        visitReason,
        contactMethod,
      } = data;
      const sports =
        lifeStyle?.phisicalActivity?.sports?.map(
          ({ _id, activity, daysPerWeek, duration, note }, index) => ({
            id: `lifestyle-sport-${index}`,
            data: { activity, daysPerWeek, duration, note },
          }),
        ) ?? [];
      const conditions =
        medicalProfile?.conditions?.conditions?.map(
          ({ _id, name, affected, note }, index) => ({
            id: `medicalprofile-condition-${index}`,
            data: {
              name,
              affected: {
                family: 'Antecedente familiar',
                patient: 'Presente en paciente',
              }[affected],
              note,
            },
          }),
        ) ?? [];
      const medications =
        medicalProfile?.medications?.medicationList?.map(
          ({ name, dose, timeUsing, note }, index) => ({
            id: `medicalprofile-medication-${index}`,
            data: {
              name,
              dose,
              timeUsing,
              note,
            },
          }),
        ) ?? [];
      const surgeries =
        medicalProfile?.surgeries?.surgeriesList?.map(
          ({ event, timeSince, note }, index) => ({
            id: `medicalprofile-surgery-${index}`,
            data: {
              event,
              timeSince,
              note,
            },
          }),
        ) ?? [];
      const contraceptives =
        medicalProfile?.gynecology?.oralContraceptives?.map(
          ({ type, dose, note }, index) => ({
            id: `medicalprofile-contraceptive-${index}`,
            data: {
              type,
              dose,
              note,
            },
          }),
        ) ?? [];
      const hormonalContraceptives =
        medicalProfile?.gynecology?.hormonalContraceptives?.map(
          ({ type, dose, note }, index) => ({
            id: `medicalprofile-hormonalcontraceptive-${index}`,
            data: {
              type,
              dose,
              note,
            },
          }),
        ) ?? [];
      const meals =
        dietProfile?.mealSchedule?.map(({ hour }) => ({
          value: hour,
        })) ?? [];
      const diets =
        dietProfile?.diets?.map((diet, index) => ({
          id: `dietprofile-diet-${index}`,
          data: {
            type: diet,
          },
        })) ?? [];
      const alergies =
        dietProfile?.allergies?.map((alergy, index) => ({
          id: `dietprofile-alergy-${index}`,
          data: {
            type: alergy,
          },
        })) ?? [];
      const intolerances =
        dietProfile?.intolerances?.map((intolerance, index) => ({
          id: `dietprofile-intolerance-${index}`,
          data: {
            type: intolerance,
          },
        })) ?? [];
      const favFood =
        dietProfile?.likedFood?.map((food, index) => ({
          id: `dietprofile-favfood-${index}`,
          data: food,
        })) ?? [];
      const unfavFood =
        dietProfile?.dislikedFood?.map((food, index) => ({
          id: `dietprofile-unfavfood-${index}`,
          data: food,
        })) ?? [];
      const supplements =
        dietProfile?.supplements?.suplements?.map(
          ({ name, dose, reason, note }, index) => ({
            id: `dietprofile-suplement-${index}`,
            data: {
              name,
              dosage: dose,
              reason,
              note,
            },
          }),
        ) ?? [];
      const general = {
        name,
        lastName,
        email,
        phoneNumber,
        gender,
        birthdate,
        visitReason,
        contactMethod,
        address,
      };
      batch(() => {
        dispatch(setMedicalProfileDefaultMeals(meals));
        dispatch(setDietProfileSupplements(supplements));
        dispatch(setDietProfileFavFood(favFood));
        dispatch(setDietProfileUnfavFood(unfavFood));
        dispatch(setDietProfileDefaultDiets(diets));
        dispatch(setDietProfileDefaultAlergies(alergies));
        dispatch(setDietProfileDefaultIntolerances(intolerances));
        dispatch(setMedicalProfileConditions(conditions));
        dispatch(setMedicalProfileMedications(medications));
        dispatch(setMedicalProfileSurgeries(surgeries));
        dispatch(setMedicalProfileOralContraceptives(contraceptives));
        dispatch(
          setMedicalProfileHormonalContraceptives(hormonalContraceptives),
        );
        dispatch(setLifeStyleSports(sports));
        dispatch(setPatientGeneralProfile(general));
        dispatch(setPatientLifeStyleProfile(lifeStyle));
        dispatch(setPatientDieteticProfile(dietProfile));
        dispatch(setPatientMedicalProfile(medicalProfile));
      });
    },
  });
};

// ---- ACTUALIZA LA INFORMACIÓN DEL PACIENTE ----
export const useUpdate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestUpdate, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PATIENT');
      enqueueSnackbar('Se actualizó la información con éxito', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        });
      } else {
        enqueueSnackbar(
          'No se pudo actualizar la información. Intenta más tarde',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          },
        );
      }
    },
  });
};

// ---- TRAER LISTA DE PACIENTES ----
export const usePatients = () => {
  const dispatch = useDispatch();
  return useQuery('GET_PATIENTS', getPatients, {
    onSuccess: ({ data }) => {
      dispatch(setPatients(data));
    },
  });
};

// ---- CREA UN NUEVO PACIENTE ----
export const usePatientCreation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestCreation, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      queryClient.invalidateQueries('GET_PLAN_USERS');
      enqueueSnackbar('Se creó correctamente el paciente', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('No se pudo agregar el paciente. Intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'ADD_PATIENT_CONFIRMATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- VERIFICA SI EL PACIENTE YA ESTÁ REGISTRADO ----
export const useVerification = () => {
  const dispatch = useDispatch();

  return useMutation(requestVerification, {
    onSuccess: ({ data }) => {
      dispatch(setPatientData(data));
      dispatch(setPatientRegisterStatus(true));
      setTimeout(() => {
        dispatch(
          setModalStatus({
            name: 'PATIENT_CREATION',
            isActive: true,
          }),
        );
      }, 500);
    },
    onError: () => {
      dispatch(setPatientData({}));
      dispatch(setPatientRegisterStatus(false));
      setTimeout(() => {
        dispatch(
          setModalStatus({
            name: 'PATIENT_CREATION',
            isActive: true,
          }),
        );
      }, 500);
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'PATIENT_VERIFICATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- SOLICITA AGREGAR UN PASE DE ESCANEO CORPORAL A UN CLIENTE ----
export const useInbodyPasses = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestAddPass, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PATIENTS');
      queryClient.invalidateQueries('GET_PASSES');
      enqueueSnackbar('Se agregó correctamente el pase a tu cliente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo agregar el pase. Intente más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'ADD_INBODY_PASSES',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ACTIVA A UN CLIENTE ----
export const useActivation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestActivation, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PATIENTS');
      queryClient.invalidateQueries('GET_PLAN_USERS');
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intente más tarde', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'ACTIVATE_PATIENT_CONFIRMATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- DESACTIVA A UN CLIENTE ----
export const useInactivation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestInactivation, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PATIENTS');
      queryClient.invalidateQueries('GET_PLAN_USERS');
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intente más tarde', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'INACTIVATE_PATIENT_CONFIRMATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- VINCULA UN PACIENTE EXISTENTE ----
export const useLinkPatient = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestLinkPatient, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PATIENTS');
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      queryClient.invalidateQueries('GET_PLAN_USERS');
      enqueueSnackbar(
        'Se envió exitosamente solicitud de vinculación al paciente',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('No se pudo realizar la operación. Intente más tarde', {
          variant: 'warning',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'PATIENT_CREATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- AGREGA UN PLAN NUTRICIONAL ----
export const useAddNutritionalPlan = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestAddNutritionalPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONAL_PLANS');
      enqueueSnackbar('Se creó el plan con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo crear el plan, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- ELIMINA UN PLAN NUTRICIONAL ----
export const useDeleteNutritionalPlan = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestDeleteNutritionalPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONAL_PLANS');
      enqueueSnackbar('Se eliminó correctamente el plan nutricional', {
        variant: 'success',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_NUTRITIONAL_PLAN',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE EL LISTADO DE PLANES NUTRICIONALES ----
export const useGetNutritionalPlans = id => {
  const dispatch = useDispatch();

  return useQuery(
    ['GET_NUTRITIONAL_PLANS', id],
    () => requestGetNutritionalPlans(id),
    {
      enabled: !!id,
      onSuccess: response => {
        batch(() => {
          dispatch(setNutritionalPlans(response));
          dispatch(setWnPatientNutritionalPlans(response));
        });
      },
    },
  );
};

// ---- AGREGA UN PLAN BIOQUIMICO ----
export const useAddBiochemicalPlan = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestAddBiochemicalPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_BIOCHEMICAL_PLANS');
      enqueueSnackbar('Se creó el plan con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo crear el plan, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- ELIMINA UN PLAN BIOQUIMICO ----
export const useDeleteBiochemicalPlan = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestDeleteBiochemicalPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_BIOCHEMICAL_PLANS');
      enqueueSnackbar('Se eliminó correctamente el plan bioquímico', {
        variant: 'success',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_BIOCHEMICAL_PLAN',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE EL LISTADO DE PLANES BIOQUIMICOS ----
export const useGetBiochemicalPlans = id => {
  const dispatch = useDispatch();

  return useQuery(
    ['GET_BIOCHEMICAL_PLANS', id],
    () => requestGetBiochemicalPlans(id),
    {
      enabled: !!id,
      onSuccess: response => {
        batch(() => {
          dispatch(setBiochemicalPlans(response));
          dispatch(setWnPatientBiochemicalPlans(response));
        });
      },
    },
  );
};

// ---- TRAE LA IMÁGEN CON INSTRUCCIONES ----
export const useGetGeneralInstructions = id => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    'PATIENT_GENERAL_INSTRUCTIONS',
    () => getGeneralInstructions(id),
    {
      enabled: false,
      onSuccess: () => {
        enqueueSnackbar(
          'En un momento comenzará la descarga de las instrucciones',
          { variant: 'success' },
        );
      },
    },
  );
};

// ---- ACTUALIZA LA INFORMACIÓN DE UNA SOLICITUD DE UN PACIENTE AUN NO ACEPTADO ----
export const useUpdatePatientBasicInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(patchUpdatePatientBasicInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      enqueueSnackbar(
        'Se actualizó correctamente la información del paciente',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intenta de nuevo más tarde.',
          {
            variant: 'error',
          },
        );
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'LINK_REQUEST_BY_NUTRITIONIST',
          isActive: false,
        }),
      );
    },
  });
};