import { createSelector } from '@reduxjs/toolkit';

export const defaultServicesSelector = createSelector(
  [state => state.serviceList.data],
  services =>
    services
      .filter(service => service.isDefault)
      .map(item => ({
        id: item._id,
        service: item._id,
        name: item.name,
        description: '',
        mode: ['online'],
        price: '0',
        isPricePublic: false,
      })),
);
