import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';

import { useDeleteNutritionistOfficeDayOff } from 'hooks/nutritionist.hook';

const DeleteNutritionistOfficeDayOffPanel = () => {
  const { mutate: deleteDayOff, isLoading } =
    useDeleteNutritionistOfficeDayOff();

  const { target } = useSelector(state => state.forms.profile);

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    deleteDayOff(target._id);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'DELETE_NUTRITIONIST_OFFICE_DAY_OFF',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Estás seguro que deseas eliminar este horario?</Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Confirmar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteNutritionistOfficeDayOffPanel;
