import { object, string, date } from 'yup';

const PHONE_NUMBER_REGEX = /[0-9]{10}/;

const REQUIRED_MESSAGE = 'Este campo es obligatorio';

export const FormSchema = object({
  name: string('sdsdsd').required(REQUIRED_MESSAGE),
  lastName: string().required(REQUIRED_MESSAGE),
  email: string().email('Escribe un email válido'),
  birthdate: date().required(REQUIRED_MESSAGE).nullable(),
  countryCode: string().required(REQUIRED_MESSAGE),
  phoneNumber: string()
    .matches(PHONE_NUMBER_REGEX, 'Escribe un número válido')
    .required(REQUIRED_MESSAGE),
  sex: string().required(REQUIRED_MESSAGE),
  about: string(),
});
