import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { WellnubTextInput } from '@wellnub/web-common';

import { Box, Grid } from '@material-ui/core';

import Button from '../../Button/Button.component';

import { addLifeStyleSport } from 'redux/slices/forms.slice';

const AddPhysicalActivityForm = () => {
  const { control, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    const sport = getValues('sport');
    dispatch(addLifeStyleSport(sport));
    setTimeout(() => {
      setValue('sport.activity', '');
      setValue('sport.daysPerWeek', '');
      setValue('sport.duration', '');
      setValue('sport.note', '');
    }, 100);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="sport.activity"
            label="Ejercicio"
            control={control}
            helperText="Tipo de ejercicio"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="sport.daysPerWeek"
            label="Frecuencia"
            control={control}
            helperText="Días a la semana"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="sport.duration"
            label="Duración"
            control={control}
            helperText="Horas a la semana"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            name="sport.note"
            variant="outlined"
            multiline
            rows={2}
            label="Nota"
            control={control}
            helperText="Tipo de ejercicio"
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button variant="outlined" color="primary" onClick={onSubmitHandler}>
          Agregar
        </Button>
      </Box>
    </>
  );
};

export default AddPhysicalActivityForm;
