import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Box, Grid } from '@material-ui/core';

import { WellnubTextInput } from '@wellnub/web-common';

import { addMedicalProfileSurgery } from 'redux/slices/forms.slice';

import Button from '../../Button/Button.component';

const SURGERIES_OPTIONS = [
  { id: 'surgery-option-0', value: 'Fractura', label: 'Fractura' },
  {
    id: 'surgery-option-0',
    value: 'Hospitalización',
    label: 'Hospitalización',
  },
  { id: 'surgery-option-0', value: 'Cirugía', label: 'Cirugía' },
  { id: 'surgery-option-0', value: 'Dolor', label: 'Dolor' },
];

const AddSurgeryForm = () => {
  const { control, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    const surgery = getValues('surgery');
    dispatch(addMedicalProfileSurgery(surgery));
    setTimeout(() => {
      setValue('surgery.event', '');
      setValue('surgery.timeSince', '');
      setValue('surgery.note', '');
    }, 100);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            name="surgery.event"
            variant="outlined"
            label="Tipo"
            control={control}
            helperText="Evento"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="surgery.timeSince"
            label="Fecha"
            control={control}
            helperText="Fecha o tiempo que ha pasado desde el evento"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            name="surgery.note"
            label="Nota"
            control={control}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button variant="outlined" color="primary" onClick={onSubmitHandler}>
          Agregar
        </Button>
      </Box>
    </>
  );
};

export default AddSurgeryForm;
