import { useDispatch, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import { Paper, Typography, Link, Box, Grid, Chip } from '@material-ui/core';

import Button from '../../Button/Button.component';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setPatientData } from 'redux/slices/patient.slice';

import { useStyles } from './PatientCard.styles';

const PatientCard = ({ patient }) => {
  const { events } = useSelector(state => state.virtualAgenda);

  const dispatch = useDispatch();

  const { url } = useRouteMatch();
  const { push } = useHistory();

  const classes = useStyles();

  const onAddPassHandler = activePatient => event => {
    event.preventDefault();
    dispatch(setPatientData(activePatient));
    dispatch(
      setModalStatus({
        name: 'ADD_INBODY_PASSES',
        isActive: true,
      }),
    );
  };

  const onChangeStatusHandler = patient => event => {
    event.preventDefault();
    dispatch(setPatientData(patient));
    const hasPendingEvents = events.some(
      item => item.patient._id === patient._id && item.status === 'accepted',
    );
    if (hasPendingEvents) {
      dispatch(
        setModalStatus({
          name: 'INACTIVE_PATIENT_WITH_APPOINTMENT_WARNING',
          isActive: true,
        }),
      );
      return;
    }
    if (patient.isActive) {
      dispatch(
        setModalStatus({
          name: 'INACTIVATE_PATIENT_CONFIRMATION',
          isActive: true,
        }),
      );
    } else {
      dispatch(
        setModalStatus({
          name: 'ACTIVATE_PATIENT_CONFIRMATION',
          isActive: true,
        }),
      );
    }
  };

  const onInbodyBadgeClickHandler = () => {
    push(`${url}/${patient._id}/progress`);
  };

  const onPatientBadgeClickHandler = () => {
    push(`${url}/${patient._id}`);
  };

  return (
    <Paper elevation={3} className={classes.paper} component="article">
      <Box textAlign="center">
        {/* -- USAR PROPIEDAD patient.inbodyPasses SI NO ES LA ACTUAL */}
        {patient.inbodyPasses !== 0 && (
          <Chip
            size="small"
            label={`Pases asignados: ${patient.inbodyPasses}`}
            classes={{
              root: classes.badgePasses,
            }}
          />
        )}
        {patient.scheduledPasses !== 0 && (
          <Chip
            size="small"
            label="Inbody agendado"
            classes={{
              root: classes.badge,
            }}
          />
        )}
        {patient.hasReturnedPassBadge && (
          <Chip
            size="small"
            label="No asistió InBody"
            classes={{
              root: classes.badgeNoAssist,
            }}
          />
        )}
        {patient.badge && (
          <>
            {patient.badge === 'newPatient' && (
              <Chip
                size="small"
                onClick={onPatientBadgeClickHandler}
                label="Nuevo Paciente"
                classes={{
                  root: classes.chipAlter,
                }}
              />
            )}
            {patient.badge === 'newInBody' && (
              <Chip
                size="small"
                onClick={onInbodyBadgeClickHandler}
                label="Nuevo InBody"
                classes={{
                  root: classes.chipNewInbody,
                }}
              />
            )}
          </>
        )}
      </Box>
      <Link
        to={`${url}/${patient._id}`}
        underline="none"
        component={RouterLink}
      >
        <Typography className={classes.name}>
          {patient.name} {patient.lastName}
        </Typography>
      </Link>
      <Grid container component="footer" className={classes.center}>
        <Grid item sm={12} xs={6}>
          <Button
            variant="text"
            disabled={!patient.isActive}
            className={`${classes.button} ${classes.primary}`}
            onClick={onAddPassHandler(patient)}
          >
            Asignar pase
          </Button>
        </Grid>
        <Grid item sm={12} xs={6}>
          <Button
            variant="text"
            className={`${classes.button} ${classes.secondary}`}
            onClick={onChangeStatusHandler(patient)}
          >
            {patient.isActive ? 'Desactivar' : 'Activar'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PatientCard;
