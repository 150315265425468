import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { getNutritionistAppointments } from '../network/services/virtualAgenda.service';
import { setVirtualAgendaEvents } from '../redux/slices/virtualAgenda/virtualAgenda.slice';

export const useGetNutritionistAppointments = () => {
  const dispatch = useDispatch();

  return useQuery(
    '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
    getNutritionistAppointments,
    {
      onSuccess: data => {
        const activeEvents = data.filter(item =>
          ['pending', 'accepted', 'rescheduled'].includes(item.status),
        );
        dispatch(setVirtualAgendaEvents(activeEvents));
      },
    },
  );
};
