import { useDispatch } from 'react-redux';

import {
  Box,
  CardMedia,
  Radio,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setTarget } from 'redux/slices/payments.slice';

import amex from 'assets/icons/card-amex.svg';
import visa from 'assets/icons/card-visa.svg';
import master from 'assets/icons/card-master.svg';

import { useStyles } from './CreditCardRow.styles';

const CreditCardRow = ({ card }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const onClickHandler = card => event => {
    const { value } = event.target;
    const parsedValue = Number(value);
    if (!parsedValue) {
      dispatch(
        setModalStatus({
          name: 'SET_DEFAULT_CARD',
          isActive: true,
        }),
      );
      dispatch(setTarget(card));
    }
  };

  const onDeleteClickHandler = card => event => {
    event.preventDefault();
    dispatch(
      setModalStatus({
        name: 'DELETE_CARD',
        isActive: true,
      }),
    );
    dispatch(setTarget(card));
  };

  return (
    <TableRow className={classes.row}>
      <TableCell align="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          <Radio
            value={card.default ? 1 : 0}
            color="primary"
            checked={card.default}
            onClick={onClickHandler(card)}
          />
          <CardMedia
            component="img"
            image={
              {
                visa: visa,
                mastercard: master,
                american_express: amex,
              }[card.brand]
            }
            className={classes.cardLogo}
          />
          <Typography variant="body2" className={classes.cardNumber}>
            &#8226;&#8226;&#8226;&#8226; {card.last4}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">{card.name}</TableCell>
      <TableCell align="center">
        {card.exp_month}/{card.exp_year}
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Eliminar">
          <IconButton onClick={onDeleteClickHandler(card)}>
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default CreditCardRow;
