import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import { WellnubDateInput, WellnubTextInput } from '@wellnub/web-common';

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useFileUploader } from 'hooks/fileUpload.hook';

import { patientSelector } from 'redux/slices/patient/patient.selector';
import { useAddBiochemicalPlan } from 'hooks/patient.hook';

import Button from '../../Button/Button.component';

const AddBiochemicalPlanForm = () => {
  const [fileUrl, setFileUrl] = useState('');
  const fileRef = useRef(null);

  const patient = useSelector(patientSelector);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      analysisDate: new Date(),
      type: '',
    },
  });

  const { mutateAsync, isLoading: isLoadingFile } = useFileUploader();

  const { mutate, isLoading, isSuccess } = useAddBiochemicalPlan();

  const { enqueueSnackbar } = useSnackbar();

  let formData = new FormData();

  const onSubmitHandler = async data => {
    const { analysisDate, type } = data;
    const payload = {
      patient: patient._id,
      analysisDate,
      type,
      fileUrl,
    };
    mutate(payload);
  };

  const onChangeFileHandler = e => {
    if (e.target && e.target.files[0]) {
      formData.append('file', e.target.files[0]);
      mutateAsync(formData)
        .then(response => {
          setFileUrl(response.fileUrl);
        })
        .catch(() => {
          enqueueSnackbar('Algo salió mal, intenta más tarde', {
            variant: 'error',
          });
        });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      fileRef.current.value = '';
    }
  }, [isSuccess, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <WellnubDateInput
            fullWidth
            name="analysisDate"
            inputVariant="outlined"
            rules={{
              required: 'Este campo es requerido',
            }}
            format="dd/MM/yyyy"
            label="Fecha inicio*"
            control={control}
            helperText="Fecha del análisis"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WellnubTextInput
            fullWidth
            name="type"
            variant="outlined"
            rules={{
              required: 'Este campo es requerido',
            }}
            label="Tipo de análisis*"
            control={control}
            helperText="Tipo de análisis (química sanguinea, orina, etc.)"
          />
        </Grid>
        <Grid item sm={4}>
          <Typography variant="caption" style={{ paddingBottom: 8 }}>
            Agregar archivo*
          </Typography>
          <input
            required
            type="file"
            ref={fileRef}
            id="fileInput"
            accept="image/*,.pdf"
            onChange={onChangeFileHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">* Campos obligatorios</Typography>
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading || isLoadingFile}
        >
          {isLoadingFile ? (
            <>
              <span>Cargando</span>
              <CircularProgress size={22} />
            </>
          ) : (
            'Agregar'
          )}
        </Button>
      </Box>
    </form>
  );
};

export default AddBiochemicalPlanForm;
