import { Box, Typography } from '@material-ui/core';

import EditOfficeScheduleDaysOffForm from '../../../Forms/EditOfficeScheduleDaysOffForm/EditOfficeScheduleDaysOffForm.component';

const EditCalendarDaysOffPanel = () => {
  return (
    <Box>
      <EditOfficeScheduleDaysOffForm />
    </Box>
  );
};

export default EditCalendarDaysOffPanel;
