import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  photo: {
    path: '',
    base64: null,
  },
};

export const cloudSlice = createSlice({
  name: 'cloud',
  initialState,
  reducers: {
    setPhotoPath: (state, action) => {
      state.photo.path = action.payload;
    },
    setPhotoBase64: (state, action) => {
      state.photo.base64 = action.payload;
    },
  },
});

export const { setPhotoPath, setPhotoBase64 } = cloudSlice.actions;

export const cloudReducer = cloudSlice.reducer;
