import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Box, Grid } from '@material-ui/core';

import { WellnubTextInput } from '@wellnub/web-common';

import { addDietProfileSuplement } from 'redux/slices/forms.slice';

import Button from '../../Button/Button.component';

const AddSuplementForm = () => {
  const { control, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    const suplement = getValues('suplement');
    dispatch(addDietProfileSuplement(suplement));
    setTimeout(() => {
      setValue('suplement.name', '');
      setValue('suplement.dosage', '');
      setValue('suplement.reason', '');
      setValue('suplement.note', '');
    }, 100);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            name="suplement.name"
            variant="outlined"
            label="Nombre"
            control={control}
            helperText="Nombre del suplemento"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="suplement.dosage"
            label="Dosis"
            control={control}
            helperText="Dosis"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="suplement.reason"
            label="Razón"
            control={control}
            helperText="Razón"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            multiline
            rows={2}
            name="suplement.note"
            variant="outlined"
            label="Nota"
            control={control}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button variant="outlined" color="primary" onClick={onSubmitHandler}>
          Agregar
        </Button>
      </Box>
    </>
  );
};

export default AddSuplementForm;
