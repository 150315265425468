import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Grid, Button } from '@material-ui/core';

import { WellnubPasswordInput } from '@wellnub/web-common';

import { usePatchPassword } from 'hooks/nutritionist.hook';

import { FormSchema } from './UpdatePasswordForm.schema';

const UpdatePasswordForm = () => {
  const { mutate: updatePassword, isLoading } = usePatchPassword();

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmation: '',
    },
  });

  const onSubmitHandler = handleSubmit(data => {
    const { confirmation, ...rest } = data;
    updatePassword(rest);
  });

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <WellnubPasswordInput
            fullWidth
            name="currentPassword"
            control={control}
            label="Contraseña actual"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <WellnubPasswordInput
            fullWidth
            name="password"
            control={control}
            label="Nueva contraseña"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <WellnubPasswordInput
            fullWidth
            name="confirmation"
            control={control}
            label="Confirmar nueva contraseña"
          />
        </Grid>
      </Grid>
      <Box pt={4}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Actualizar contraseña
        </Button>
      </Box>
    </form>
  );
};

export default UpdatePasswordForm;
