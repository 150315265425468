import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Menu,
  Typography,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useSelector } from 'react-redux';
import {
  newNutritionistNotificationsSelector,
  notificationsSelector,
} from 'redux/slices/notifications/notifications.selector';

import Notification from '../Notification/Notification.component';

import { useStyles } from './NotificationIndicator.styles';
import { useGetNotifications } from '../../hooks/nutritionist.hook';
import { nanoid } from '@reduxjs/toolkit';
import { isThisWeek, isToday, isYesterday } from 'date-fns';

const NotificationIndicator = () => {
  const [sortedNotifications, setSortedNotifications] = useState({
    today: [],
    thisWeek: [],
    rest: [],
  });
  const [anchor, setAnchor] = useState(null);

  const badges = useSelector(newNutritionistNotificationsSelector);

  const { refetch } = useGetNotifications();

  const notifications = useSelector(notificationsSelector);

  const classes = useStyles();

  const onOpenHandler = event => {
    setAnchor(event.currentTarget);
    refetch();
  };

  const onCloseHandler = () => {
    setAnchor(null);
  };

  useEffect(() => {
    const sortedNotifications = notifications.reduce(
      (accumulator, current) => {
        if (isToday(new Date(current.date))) {
          accumulator.today.push(current);
        } else if (isThisWeek(new Date(current.date))) {
          accumulator.thisWeek.push(current);
        } else {
          accumulator.rest.push(current);
        }
        return accumulator;
      },
      { today: [], thisWeek: [], rest: [] },
    );
    setSortedNotifications(sortedNotifications);
  }, [notifications]);

  return (
    <Box>
      <Badge
        color="error"
        max={9}
        badgeContent={badges}
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <IconButton onClick={onOpenHandler}>
          <NotificationsIcon color="secondary" />
        </IconButton>
      </Badge>
      <Menu
        elevation={0}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={onCloseHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {isEmpty(notifications) && (
          <Box p={2}>
            <Typography variant="body2" align="center">
              No tienes notificaciones pendientes
            </Typography>
          </Box>
        )}
        {!isEmpty(notifications) && (
          <List>
            <ListItem>
              <List
                subheader={
                  <ListSubheader className={classes.subheader}>
                    <Typography variant="h6">Hoy</Typography>
                  </ListSubheader>
                }
              >
                {isEmpty(sortedNotifications.today) && (
                  <Box p={2}>
                    <Typography variant="body2" align="center">
                      No hay notificaciones de hoy
                    </Typography>
                  </Box>
                )}
                {!isEmpty(sortedNotifications.today) &&
                  sortedNotifications.today.map(notification => (
                    <ListItem>
                      <Box onClick={onCloseHandler} key={nanoid()}>
                        <Notification notification={notification} />
                      </Box>
                    </ListItem>
                  ))}
              </List>
            </ListItem>
            <ListItem>
              <List
                subheader={
                  <ListSubheader className={classes.subheader}>
                    <Typography variant="h6">Esta semana</Typography>
                  </ListSubheader>
                }
              >
                {isEmpty(sortedNotifications.thisWeek) && (
                  <Box p={2}>
                    <Typography variant="body2" align="center">
                      No hay notificaciones de esta semana
                    </Typography>
                  </Box>
                )}
                {!isEmpty(sortedNotifications.thisWeek) &&
                  sortedNotifications.thisWeek.map(notification => (
                    <ListItem>
                      <Box onClick={onCloseHandler} key={nanoid()}>
                        <Notification notification={notification} />
                      </Box>
                    </ListItem>
                  ))}
              </List>
            </ListItem>
            <ListItem>
              <List
                subheader={
                  <ListSubheader className={classes.subheader}>
                    <Typography variant="h6">Anteriores</Typography>
                  </ListSubheader>
                }
              >
                {isEmpty(sortedNotifications.rest) && (
                  <Box p={2}>
                    <Typography variant="body2" align="center">
                      No hay notificaciones restantes
                    </Typography>
                  </Box>
                )}
                {!isEmpty(sortedNotifications.rest) &&
                  sortedNotifications.rest.map(notification => (
                    <ListItem>
                      <Box onClick={onCloseHandler} key={nanoid()}>
                        <Notification notification={notification} />
                      </Box>
                    </ListItem>
                  ))}
              </List>
            </ListItem>
          </List>
        )}
      </Menu>
    </Box>
  );
};

export default NotificationIndicator;