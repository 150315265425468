import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  withNotification: {
    position: 'relative',
    display: 'inline-block',
    '&::after': {
      content: "' '",
      width: '10px',
      height: '10px',
      position: 'absolute',
      top: '30%',
      right: '-20px',
      borderRadius: '50%',
      backgroundColor: 'red',
      display: ({ withNotification }) => (withNotification ? 'block' : 'none'),
    },
  },
}));
