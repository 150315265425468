import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  appBar: {
    boxShadow: 'unset',
  },
  tabs: {
    backgroundColor: '#FAFAFA',
  },
  tab: {
    color: theme.palette.primary.light,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 12,
  },
  selected: {
    fontWeight: 'bold',
  },
  withNotification: {
    '&::after': {
      content: "' '",
      width: '10px',
      height: '10px',
      position: 'absolute',
      top: '40%',
      right: 20,
      borderRadius: '50%',
      backgroundColor: 'red',
      display: ({withNotification}) => withNotification ? 'block' : 'none'
    }
  }
}));
