import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  events: [],
  editableEvent: {},
  pendingAppointmentAfterCreatePatient: {
    // ** MODES: 'normal' | 'persist' **
    mode: 'normal',
    data: {},
  },
  unseenAppointments: []
};

const virtualAgendaSlice = createSlice({
  name: 'virtualAgenda',
  initialState,
  reducers: {
    setVirtualAgendaEvents: (state, action) => {
      state.events = action.payload;
    },
    setEditableEvent: (state, action) => {
      state.editableEvent = action.payload;
    },
    setPendingAppointmentAfterCreatePatient: (state, action) => {
      state.pendingAppointmentAfterCreatePatient = action.payload;
    },
    setUnseenAppointments: (state, action) => {
      state.unseenAppointments = action.payload
    }
  },
});

export const {
  setVirtualAgendaEvents,
  setEditableEvent,
  setPendingAppointmentAfterCreatePatient,
  setUnseenAppointments
} = virtualAgendaSlice.actions;
export const VirtualAgendaReducer = virtualAgendaSlice.reducer;