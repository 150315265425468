import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CalendarEventForm from '../../../Forms/CalendarEvent/CalendarEventForm.component';

const CalendarEventPanel = () => {
  const { event } = useSelector(state => state.calendarEvents);
  return (
    <Box>
      <CalendarEventForm event={event} />
    </Box>
  );
};

export default CalendarEventPanel;
