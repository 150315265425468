import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { LinkRequestCard } from 'components';

import {
  getReceivedInvitations,
  getSentInvitations,
} from 'redux/slices/invitations/invitations.selector';

const useStyles = makeStyles(theme => ({
  helperBox: {
    padding: theme.spacing('5px', 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.primary.light}`,
  },
}));

export const InvitationsPage = () => {
  const invitationsSent = useSelector(getSentInvitations);
  const invitationsRecieved = useSelector(getReceivedInvitations);

  const classes = useStyles();

  return (
    <>
      <Box mb={4}>
        <Typography
          variant="h6"
          style={{ textTransform: 'uppercase', paddingBottom: 16 }}
        >
          Enviadas por mí
        </Typography>
        {isEmpty(invitationsSent) && (
          <Box className={classes.helperBox}>
            <Typography variant="body2">
              No hay solicitudes pendientes
            </Typography>
          </Box>
        )}
        {!isEmpty(invitationsSent) && (
          <Grid container component="ul" spacing={3}>
            {invitationsSent.map(request => (
              <Grid item md={2} sm={4} xs={12} component="li" key={request._id}>
                <LinkRequestCard
                  editable
                  withInstructions
                  variant={request.starter}
                  publicField={request.publicField}
                  patient={request.patient}
                  nutritionPartner={request._id}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Box mb={4}>
        <Typography
          variant="h6"
          style={{ textTransform: 'uppercase', paddingBottom: 16 }}
        >
          Enviadas por pacientes
        </Typography>
        {isEmpty(invitationsRecieved) && (
          <Box className={classes.helperBox}>
            <Typography variant="body2">
              No hay solicitudes pendientes
            </Typography>
          </Box>
        )}
        {!isEmpty(invitationsRecieved) && (
          <Grid container component="ul" spacing={3}>
            {invitationsRecieved.map(request => (
              <Grid item md={2} sm={4} xs={12} component="li" key={request._id}>
                <LinkRequestCard
                  variant={request.starter}
                  publicField={request.publicField}
                  requestData={request}
                  patient={{
                    ...request.nutritionistContact,
                    ...request.patient,
                  }}
                  nutritionPartner={request._id}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
};
