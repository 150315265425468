import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  dataHeader: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  favTitle: {
    padding: theme.spacing(1, 0),
    textAlign: 'center',
    color: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
  },
  favTitlePrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  favTitleSecondary: {
    backgroundColor: '#1a1a1a',
  },
  list: {
    padding: 0,
  },
}));
