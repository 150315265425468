import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { Box, Grid } from '@material-ui/core';

import { WellnubTextInput, WellnubNumberInput } from '@wellnub/web-common';

import { setPatientData } from 'redux/slices/patient.slice';
import { Button } from 'components';
import {
  useAcceptLinkRequest,
  useRejectLinkRequest,
} from 'hooks/nutritionist.hook';
import { format } from 'date-fns';

const LinkRequestByPatient = () => {
  const { data } = useSelector(state => state.patient);

  const { mutate: acceptRequest, isLoading: isLoadingAccept } =
    useAcceptLinkRequest();
  const { mutate: rejectRequest, isLoading: isLoadingReject } =
    useRejectLinkRequest();

  const { control } = useForm({
    defaultValues: {
      name: data?.name ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
      phoneNumber: data?.phoneNumber ?? '',
      gender: { male: 'Hombre', female: 'Mujer' }[data?.sex],
      birthday: format(new Date(data?.birthdate ?? null), 'MM/dd/yyyy'),
      contactMethod: new Intl.ListFormat('es').format(
        data?.contactMethod ?? [],
      ),
    },
  });

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    acceptRequest(data.requestId);
  };

  const onRejectHandler = () => {
    rejectRequest(data.requestId);
  };

  useEffect(() => {
    return () => {
      dispatch(setPatientData({}));
    };
  }, []);

  return (
    <Box>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              name="name"
              label="Nombre"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              name="lastName"
              label="Apellido"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              type="email"
              name="email"
              label="Email"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubNumberInput
              fullWidth
              disabled
              defaultValue={data?.phoneNumber ?? ''}
              name="phoneNumber"
              label="Teléfono"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              name="gender"
              label="Sexo"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              name="birthday"
              label="Fecha de nacimiento"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              name="service"
              label="Servicio buscado"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              name="mode"
              label="Modalidad del servicio"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              name="contactMethod"
              label="Forma de contacto preferida"
              variant="outlined"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <WellnubTextInput
              fullWidth
              disabled
              name="note"
              label="Nota"
              variant="outlined"
              control={control}
            />
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onRejectHandler}
            loading={isLoadingReject}
          >
            Rechazar solicitud
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onAcceptHandler}
            loading={isLoadingAccept}
          >
            Aceptar solicitud
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default LinkRequestByPatient;
