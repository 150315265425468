import { useEffect } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { WellnubTextInput, WellnubNumberInput } from '@wellnub/web-common';

import {
  useCreateNutritionistOffice,
  useUpdateNutritionistOffice,
} from 'hooks/nutritionist.hook';

import { useDispatch } from 'react-redux';
import { setIsCreateOfficeFormOpen, setMode } from 'redux/slices/forms.slice';

import { FormSchema } from './AddNutritionistOfficeForm.schema';

import { useStyles } from './AddNutritionistOfficeForm.styles';

const AddNutritionistOfficeForm = ({ editableData, mode }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      name: editableData?.name ?? '',
      mode: editableData?.mode ?? 'online',
      link: editableData?.address?.mapUrl ?? '',
      // phoneNumber: editableData?.phoneNumber ?? '',
      street: editableData?.address?.street ?? '',
      neighborhood: editableData?.address?.neighborhood ?? '',
      hall: editableData?.address?.hall ?? '',
      city: editableData?.address?.country ?? '',
      state: editableData?.address?.state ?? '',
      zipCode: editableData?.address?.zipCode ?? '',
    },
  });

  const dispatch = useDispatch();

  const modeWatcher = watch('mode');

  const { mutate: createOffice, isLoading } = useCreateNutritionistOffice();

  const { mutate: updateOffice, isLoading: isLoadingEdit } =
    useUpdateNutritionistOffice();

  const onSubmitHandler = handleSubmit(data => {
    const payload = {
      name: data.name,
      mode: data.mode,
      address: {
        street: data.street,
        neighborhood: data.neighborhood,
        hall: data.hall,
        state: data.state,
        country: data.city,
        zipCode: data.zipCode,
        mapUrl: data.link,
      },
    };
    if (mode === 'normal') {
      createOffice(payload);
    }
    if (mode === 'editable') {
      updateOffice({
        id: editableData._id,
        data: payload,
      });
    }
  });

  const classes = useStyles();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      dispatch(setIsCreateOfficeFormOpen(false));
      dispatch(setMode('normal'));
    }
  }, [isSubmitSuccessful, reset, dispatch]);

  useEffect(() => {
    if (editableData.mode !== modeWatcher) {
      setValue('link', '');
      setValue('street', '');
      setValue('neighborhood', '');
      setValue('hall', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zipCode', '');
      // setValue('phoneNumber', '');
    }
  }, [modeWatcher]);

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Nombre del consultorio
          </Typography>
          <Box mt={2}>
            <WellnubTextInput
              fullWidth
              name="name"
              control={control}
              variant="outlined"
              label="Nombre del consultorio"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Modalidad
          </Typography>
          <Box mt={2}>
            <Controller
              name="mode"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <RadioGroup row value={value} onChange={onChange}>
                    <FormControlLabel
                      value="online"
                      control={<Radio color="primary" />}
                      label="En Línea"
                    />
                    <FormControlLabel
                      value="onsite"
                      control={<Radio color="primary" />}
                      label="Presencial"
                    />
                    <FormControlLabel
                      value="home"
                      control={<Radio color="primary" />}
                      label="A domicilio"
                    />
                  </RadioGroup>
                  <FormHelperText style={{ color: 'red' }}>
                    {error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </Box>
        </Grid>
        {modeWatcher === 'online' && (
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className={classes.title}>
              Link o instrucciones
            </Typography>
            <Box mt={2}>
              <WellnubTextInput
                fullWidth
                control={control}
                name="link"
                variant="outlined"
                label="Link o instrucciones"
              />
            </Box>
          </Grid>
        )}
        {/*<Grid item xs={12} sm={6}>*/}
        {/*  <Typography variant="h6" className={classes.title}>*/}
        {/*    Teléfono de contacto*/}
        {/*  </Typography>*/}
        {/*  <Box mt={2}>*/}
        {/*    <WellnubNumberInput*/}
        {/*      fullWidth*/}
        {/*      defaultValue={editableData?.phoneNumber ?? ''}*/}
        {/*      format="## #### ####"*/}
        {/*      control={control}*/}
        {/*      name="phoneNumber"*/}
        {/*      variant="outlined"*/}
        {/*      label="Teléfono de contacto"*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
        {modeWatcher !== 'online' && (
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className={classes.title}>
              Calle y número
            </Typography>
            <Box mt={2}>
              <WellnubTextInput
                fullWidth
                control={control}
                name="street"
                variant="outlined"
                label="Calle y número"
              />
            </Box>
          </Grid>
        )}
        {modeWatcher !== 'online' && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.title}>
                  Colonia
                </Typography>
                <Box mt={2}>
                  <WellnubTextInput
                    fullWidth
                    control={control}
                    name="neighborhood"
                    variant="outlined"
                    label="Colonia"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" className={classes.title}>
                  Alcaldía o municipio
                </Typography>
                <Box mt={2}>
                  <WellnubTextInput
                    fullWidth
                    control={control}
                    name="hall"
                    variant="outlined"
                    label="Alaldía o municipio"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        {modeWatcher !== 'online' && (
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" className={classes.title}>
              Ciudad
            </Typography>
            <Box mt={2}>
              <WellnubTextInput
                fullWidth
                control={control}
                name="city"
                variant="outlined"
                label="Ciudad"
              />
            </Box>
          </Grid>
        )}
        {modeWatcher !== 'online' && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.title}>
                  Estado
                </Typography>
                <Box mt={2}>
                  <WellnubTextInput
                    fullWidth
                    control={control}
                    name="state"
                    variant="outlined"
                    label="Estado"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" className={classes.title}>
                  Código postal
                </Typography>
                <Box mt={2}>
                  <WellnubNumberInput
                    fullWidth
                    defaultValue={editableData?.address?.zipCode ?? ''}
                    format="#####"
                    control={control}
                    name="zipCode"
                    variant="outlined"
                    label="Código postal"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading || isLoadingEdit}
        >
          Guardar
        </Button>
      </Box>
    </form>
  );
};

export default AddNutritionistOfficeForm;
