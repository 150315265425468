import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

export const getReceivedInvitations = createSelector(
  [state => state.invitations.data],
  invitations => invitations.filter(item => item.starter === 'patient'),
);

export const getSentInvitations = createSelector(
  [state => state.invitations.data],
  invitations => invitations.filter(item => item.starter === 'nutritionist'),
);

export const getInvitationsBadge = createSelector(
  [state => state.invitations.data],
  invitations => {
    const list = invitations.filter(item => item.starter === 'patient');
    return !isEmpty(list);
  },
);
