import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';

const MissingNutritionistProfilePanel = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const onAcceptHandler = () => {
    push('/settings/profile/general');
    setTimeout(() => {
      dispatch(
        setModalStatus({
          name: 'MISSING_NUTRITIONIST_PROFILE',
          isActive: false,
        }),
      );
    });
  };

  return (
    <Box textAlign="center">
      <Typography
        variant="h6"
        // align="center"
        style={{ textTransform: 'uppercase' }}
      >
        Por favor actualiza tu perfil en Wellnub
      </Typography>
      <Typography style={{ paddingTop: 8, paddingBottom: 8 }}>
        ¡En Wellnub siempre estamos incorporando nuevas herramientas para ti!
      </Typography>
      <Typography>
        Para que más pacientes prospecto que usan Wellnub puedan encontrarte más
        fácilmente, incorporamos una nueva sección dentro de tu perfil Wellnub:
        especialidades y tipos de dietas.
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        mt={2}
      >
        <Button variant="contained" color="primary" onClick={onAcceptHandler}>
          Actualizar ahora
        </Button>
      </Box>
    </Box>
  );
};

export default MissingNutritionistProfilePanel;
