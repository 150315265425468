import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';

const EmptyOfficesWarningPanel = () => {
  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    dispatch(
      setModalStatus({
        name: 'EMPTY_OFFICES_WARNING',
        isActive: false,
      }),
    );
  };
  return (
    <Box textAlign="center">
      <Typography style={{ paddingBottom: 16 }}>
        Debes agregar al menos un consultorio para tener un perfil completo en
        Wellnub
      </Typography>
      <Button variant="contained" color="primary" onClick={onConfirmHandler}>
        Confirmar
      </Button>
    </Box>
  );
};

export default EmptyOfficesWarningPanel;
