import { createSelector } from '@reduxjs/toolkit';

export const getActivePatientsSelector = createSelector(
  [state => state.patients.data],
  patients => patients.filter(patient => patient.isActive),
);

export const getInactivePatientsSelector = createSelector(
  [state => state.patients.data],
  patients => patients.filter(patient => !patient.isActive),
);

export const getActivePatientsBadgeSelector = createSelector(
  [state => state.patients.data],
  patients =>
    patients.filter(patient => patient.isActive).some(item => item.badge),
);
