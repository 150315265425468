import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));
