import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  WellnubTextInput,
  WellnubNumberInput,
  WellnubSelectInput,
  WellnubDateInput,
  WellnubCountryCodeInput,
} from '@wellnub/web-common';

import { Box, Grid, Typography } from '@material-ui/core';

import MUIPhoneInput from 'material-ui-phone-number';

import { useUpdate } from 'hooks/patient.hook';

import { validatePhoneNumber } from 'utils/helpers';

import { patientSelector } from 'redux/slices/patient/patient.selector';

import Button from '../../../Button/Button.component';
import { useStyles } from './MedicalHistoryGeneralForm.styles';

const GENDER_OPTIONS = [
  { id: 1, value: 'male', label: 'Hombre' },
  { id: 2, value: 'female', label: 'Mujer' },
];

const CONTACT_OPTIONS = [
  { id: 1, value: 'whatsapp', label: 'Whatsapp' },
  { id: 2, value: 'phone', label: 'Teléfono' },
  { id: 3, value: 'email', label: 'Correo' },
];

const MedicalHistoryGeneralForm = ({ editable }) => {
  const { general } = useSelector(state => state.patient.profile);
  const patient = useSelector(patientSelector);

  const { id } = useParams();

  const { mutate, isLoading } = useUpdate();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: general?.name ?? '',
      lastName: general?.lastName ?? '',
      email: general?.email ?? '',
      phoneNumber: general?.phoneNumber ?? '',
      countryCode: general?.countryCode ?? '52',
      gender: general?.gender ?? '',
      birthdate: new Date(general?.birthdate ?? null),
      contactMethod: general?.contactMethod ?? '',
      visitReason: general?.visitReason ?? '',
      address: {
        street: general?.address.street ?? '',
        neighborhood: general?.address.neighborhood ?? '',
        city: general?.address.city ?? '',
        hall: general?.address.hall ?? '',
        state: general?.address.state ?? '',
        zipCode: general?.address.zipCode ?? '',
      },
    },
  });

  const classes = useStyles();

  const onSubmitHandler = data => {
    mutate({
      id: patient._id,
      data,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Box component="section">
        <Typography variant="h5" className={classes.sectionTitle}>
          Información general
        </Typography>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled={!editable}
              name="name"
              label="Nombre*"
              rules={{
                required: 'Este campo es obligatorio',
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: 'Escribe un nombre válido',
                },
              }}
              variant="outlined"
              control={control}
              helperText="Escribe tu nombre"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled={!editable}
              name="lastName"
              label="Apellidos*"
              rules={{
                required: 'Este campo es obligatorio',
              }}
              variant="outlined"
              control={control}
              helperText="Escribe tu nombre"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <WellnubCountryCodeInput
                  label="Región"
                  disabled={!editable}
                  control={control}
                  name="countryCode"
                  rules={{ required: 'Este campo es requerido' }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <WellnubNumberInput
                  fullWidth
                  disabled={!editable}
                  variant="outlined"
                  label="Celular *"
                  name="phoneNumber"
                  defaultValue={general?.phoneNumber ?? ''}
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio',
                    pattern: {
                      value: /[0-9]{10}/,
                      message: 'Escribe un número válido',
                    },
                  }}
                  format="## #### ####"
                  helperText="Celular de contacto a 10 dígitos"
                />
              </Grid>
            </Grid>
            {/*<Controller*/}
            {/*  control={control}*/}
            {/*  name="phoneNumber"*/}
            {/*  rules={{*/}
            {/*    validate: value => validatePhoneNumber(value),*/}
            {/*  }}*/}
            {/*  render={({*/}
            {/*    field: { value, onChange },*/}
            {/*    fieldState: { error },*/}
            {/*  }) => (*/}
            {/*    <MUIPhoneInput*/}
            {/*      value={value}*/}
            {/*      fullWidth*/}
            {/*      variant="outlined"*/}
            {/*      error={!!error}*/}
            {/*      disabled={!editable}*/}
            {/*      label="Celular *"*/}
            {/*      defaultCountry="mx"*/}
            {/*      onChange={onChange}*/}
            {/*      helperText={*/}
            {/*        error?.message ?? 'Celular de contacto a 10 dígitos'*/}
            {/*      }*/}
            {/*      onlyCountries={['mx', 'us']}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
            {/*<WellnubNumberInput*/}
            {/*  fullWidth*/}
            {/*  defaultValue={general?.phoneNumber ?? ''}*/}
            {/*  disabled={!editable}*/}
            {/*  name="phoneNumber"*/}
            {/*  label="Celular*"*/}
            {/*  format="## #### ####"*/}
            {/*  rules={{*/}
            {/*    required: 'Este campo es obligatorio',*/}
            {/*  }}*/}
            {/*  variant="outlined"*/}
            {/*  control={control}*/}
            {/*  helperText="Escribe tu celular"*/}
            {/*/>*/}
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled
              type="email"
              name="email"
              label="Email*"
              rules={{
                required: 'Este campo es obligatorio',
              }}
              variant="outlined"
              control={control}
              helperText="Escribe tu email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubSelectInput
              fullWidth
              disabled={!editable}
              name="gender"
              label="Sexo"
              options={GENDER_OPTIONS}
              variant="outlined"
              control={control}
              helperText="Selecciona tu sexo"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubDateInput
              fullWidth
              disabled={!editable}
              name="birthdate"
              label="Fecha de nacimiento"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              maxDate={new Date()}
              control={control}
              helperText="Selecciona tu sexo"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubSelectInput
              fullWidth
              disabled={!editable}
              name="contactMethod"
              label="Método de contacto"
              options={CONTACT_OPTIONS}
              variant="outlined"
              control={control}
              helperText="Selecciona tu método de contacto"
            />
          </Grid>
        </Grid>
      </Box>
      <Box component="section" mt={4}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Dirección
        </Typography>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled={!editable}
              name="address.street"
              label="Calle"
              variant="outlined"
              control={control}
              helperText="Calle, número"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled={!editable}
              name="address.neighborhood"
              label="Colonia"
              variant="outlined"
              control={control}
              helperText="Colonia"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled={!editable}
              name="address.hall"
              label="Alcaldía o Municipio"
              variant="outlined"
              control={control}
              helperText="Delegación o municipio"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled={!editable}
              name="address.city"
              label="Ciudad"
              variant="outlined"
              control={control}
              helperText="Ciudad"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubTextInput
              fullWidth
              disabled={!editable}
              name="address.state"
              label="Estado"
              variant="outlined"
              control={control}
              helperText="Estado"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubNumberInput
              fullWidth
              defaultValue={general?.address.zipCode ?? ''}
              disabled={!editable}
              name="address.zipCode"
              format="#####"
              label="Código postal"
              variant="outlined"
              control={control}
              helperText="Código postal"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4} component="section">
        <Typography variant="h5" className={classes.sectionTitle}>
          Motivo de la consulta / objetivo del paciente
        </Typography>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} sm={12}>
            <WellnubTextInput
              fullWidth
              multiline
              rows={2}
              disabled={!editable}
              name="visitReason"
              label="Motivos"
              variant="outlined"
              control={control}
              helperText="Motivos de la consulta"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4} component="section">
        {editable && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Guardar
          </Button>
        )}
      </Box>
    </form>
  );
};

export default MedicalHistoryGeneralForm;
