import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

export const userHasToCompleteProfileSelector = createSelector(
  [state => state.user.profile?.nutritionistProfile?.services ?? []],
  services => isEmpty(services),
);

export const userHasToFillDietsProfileSelector = createSelector(
  [
    state => state.user.profile?.nutritionistProfile?.diets ?? [],
    state => state.user.profile?.nutritionistProfile?.types ?? [],
  ],
  (diets, types) => isEmpty(diets) || isEmpty(types),
);

export const userIsFirstSignInSelector = createSelector(
  [state => state.user.profile?.hasLoggedIn ?? false],
  hasLoggedIn => !hasLoggedIn,
);

export const nutritionistGeneralDataSelector = createSelector(
  [state => state.user.profile],
  profile => ({
    name: profile?.name ?? '',
    lastName: profile?.lastName ?? '',
    email: profile?.email ?? '',
    phoneNumber: profile?.phoneNumber ?? '',
    countryCode: profile?.countryCode ?? '',
    birthdate: profile?.birthdate ?? new Date(),
    sex: profile?.sex ?? '',
    about: profile?.nutritionistProfile?.about ?? '',
  }),
);

export const nutritionistSpecialtiesDataSelector = createSelector(
  [
    state => state.user.profile?.nutritionistProfile ?? {},
    state => state.user.nutritionistTypes,
    state => state.user.nutritionistDiets,
    state => state.user.nutritionistSpecialties,
    state => state.user.nutritionistServices,
  ],
  (profile, types, diets, specialties) => ({
    mainDegree: profile?.mainDegree ?? '',
    professionalLicense: profile?.professionalLicense ?? '',
    expertise: profile?.expertise ?? [],
    certifications: profile?.certifications ?? [],
    activeTypes: profile?.types ?? [],
    activeDiets: profile?.diets ?? [],
    activeSpecialties: profile?.specialities?.map(spec => spec._id) ?? [],
    types,
    diets,
    specialties,
  }),
);

export const nutritionistServicesSelector = createSelector(
  [
    state => state.user.profile?.nutritionistProfile ?? {},
    state => state.user.nutritionistServices,
  ],
  (profile, services) => ({
    services:
      profile?.services?.map(service => ({
        ...service,
        name:
          services.find(item => item._id === service.service)?.name ??
          'Servicio',
      })) ?? [],
  }),
);

export const nutritionistOfficesSelector = createSelector(
  [state => state.user.nutritionistOffices],
  offices => offices,
);

export const nutritionistOfficesScheduleSelector = createSelector(
  [state => state.user.nutritionistOfficesSchedule],
  schedule => schedule,
);

export const nutritionistOfficeDaysOffSelector = createSelector(
  [state => state.user.nutritionistOfficeDaysOff],
  daysOff => daysOff,
);

export const nutritionistScheduleSettingsSelector = createSelector(
  [state => state.user?.profile?.nutritionistProfile?.settings ?? {}],
  settings => settings,
);
