import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useRejectCalendarEvent } from 'hooks/nutritionist.hook';

const RejectCalendarEventWarningPanel = () => {
  const { editableEvent } = useSelector(state => state.virtualAgenda);

  const { mutate: rejectEvent, isLoading: isRejectingEvent } =
    useRejectCalendarEvent();

  const dispatch = useDispatch();

  const onEditAppointmentDateHandler = () => {
    dispatch(
      setModalStatus({
        name: 'REJECT_CALENDAR_EVENT_WARNING',
        isActive: false,
      }),
    );
    setTimeout(() => {
      dispatch(
        setModalStatus({
          name: 'PROPOSE_NEW_DATE_FOR_CALENDAR_EVENT',
          isActive: true,
        }),
      );
    }, 500);
  };

  const onRejectAppointmentHandler = () => {
    rejectEvent(editableEvent?._id ?? '');
  };

  return (
    <Box>
      <Typography variant="h6" align="center">
        ¿Rechazar cita?
      </Typography>
      <Typography align="center" style={{ marginTop: '16px' }}>
        Puedes proponer otra fecha si así lo deseas
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={onEditAppointmentDateHandler}
          disabled={isRejectingEvent}
        >
          Proponer nueva fecha
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onRejectAppointmentHandler}
          disabled={isRejectingEvent}
        >
          Rechazar
        </Button>
      </Box>
    </Box>
  );
};

export default RejectCalendarEventWarningPanel;
