import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const notificationsAdapter = createEntityAdapter({
  selectId: notification => notification._id,
  sortComparer: (a, b) =>
    new Date(a.date).getDate() - new Date(b.date).getDate(),
});

const initialState = {
  new: 0,
  notifications: notificationsAdapter.getInitialState(),
};

const notificationsSlice = createSlice({
  name: 'wnNotifications',
  initialState,
  reducers: {
    setWnNotifications: (state, action) => {
      notificationsAdapter.setAll(state.notifications, action.payload);
    },
    setWnNotificationCounter: (state, action) => {
      state.new = action.payload;
    },
  },
});

export const { setWnNotificationCounter, setWnNotifications } =
  notificationsSlice.actions;
export const WNNotificationsReducer = notificationsSlice.reducer;