import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: 12,
  },
  label: {
    fontSize: 13,
  },
}));
