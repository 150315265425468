import { useRouteMatch, Link, useLocation } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import { useStyles } from './ProfileNavigation.styles';
import { useSelector } from 'react-redux';

const ProfileNavigation = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const isProfileComplete = useSelector(state => state.user.isProfileComplete);

  const classes = useStyles();

  const getTabValue = () => {
    if (pathname.includes('general')) return 'general';
    if (pathname.includes('password')) return 'password';
    if (pathname.includes('specialties')) return 'specialties';
    if (pathname.includes('services')) return 'services';
    if (pathname.includes('offices')) return 'offices';
    if (pathname.includes('market')) return 'market';
    if (pathname.includes('history')) return 'history';
    return 'general';
  };

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={getTabValue()}
      className={classes.tabs}
      indicatorColor="primary"
    >
      <Tab
        to={`${url}/general`}
        component={Link}
        value="general"
        label="Información General"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        to={`${url}/specialties`}
        component={Link}
        value="specialties"
        label="Formación / Especialidad"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        to={`${url}/services`}
        component={Link}
        value="services"
        label="Mis servicios"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        to={`${url}/offices`}
        component={Link}
        value="offices"
        label="Mis consultorios"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        to={`${url}/market`}
        component={Link}
        value="market"
        label="Compras"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        disabled={!isProfileComplete}
        to={`${url}/history`}
        component={Link}
        value="history"
        label="Historial"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        disabled={!isProfileComplete}
        to={`${url}/password`}
        component={Link}
        value="password"
        label="Contraseña"
        classes={{
          wrapper: classes.tab,
        }}
      />
    </Tabs>
  );
};

export default ProfileNavigation;
