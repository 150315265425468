import { batch, useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { setEditableTarget } from 'redux/slices/forms.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import { nutritionistOfficesSelector } from 'redux/slices/user.selector';
import { useGetOfficeHoursAffectedPatients } from 'hooks/nutritionist.hook';
import { setWnUserEditableOfficeHours } from 'redux/slices/user/user.slice';

const OfficesScheduleRow = ({ schedule }) => {
  const offices = useSelector(nutritionistOfficesSelector);

  const { refetch } = useGetOfficeHoursAffectedPatients(schedule._id);

  const dispatch = useDispatch();

  const onDeleteHandler = () => {
    refetch();
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'DELETE_NUTRITIONIST_OFFICE_SCHEDULE',
          isActive: true,
        }),
      );
      dispatch(setEditableTarget(schedule));
    });
  };

  const onEditHandler = () => {
    batch(() => {
      dispatch(
        setWnUserEditableOfficeHours({
          mode: 'UPDATE',
          data: schedule,
        }),
      );
      dispatch(
        setModalStatus({
          name: 'EDIT_NUTRITIONIST_OFFICE_HOURS',
          isActive: true,
        }),
      );
    });
  };

  return (
    <TableRow>
      {/* ---- ACCIONES ---- */}
      <TableCell align="center">
        <Tooltip title="Eliminar">
          <IconButton size="small" onClick={onDeleteHandler}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar">
          <IconButton size="small" onClick={onEditHandler}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>

      {/* ---- HORARIO ---- */}
      <TableCell align="center">
        <Typography variant="caption">
          {schedule.schedule.startTime} - {schedule.schedule.endTime}
        </Typography>
      </TableCell>

      {/* ---- DÍAS DE LA SEMANA ---- */}
      <TableCell align="center">
        <Typography variant="caption">
          {[...schedule.schedule.days]
            .map(dayIndex => (dayIndex === 0 ? 7 : dayIndex))
            .sort()
            .map(
              dayIndex =>
                ({
                  1: 'Lu',
                  2: 'Ma',
                  3: 'Mi',
                  4: 'Ju',
                  5: 'Vi',
                  6: 'Sa',
                  7: 'Do',
                }[dayIndex]),
            )
            .join(' - ')}
        </Typography>
      </TableCell>

      {/* ---- CONSULTORIO ---- */}
      <TableCell align="center">
        <Typography variant="caption">
          {schedule.offices
            .map(
              item =>
                offices.find(office => office._id === item)?.name ??
                'Consultorio',
            )
            .join(' - ')}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default OfficesScheduleRow;
