import { configureStore } from '@reduxjs/toolkit';

import { authenticationReducer } from './slices/authentication.slice';
import { modalReducer } from './slices/modal.slice';
import { userReducer } from './slices/user.slice';
import { cloudReducer } from './slices/cloud.slice';
import { patientReducer } from './slices/patient.slice';
import { drawersReducer } from './slices/drawers.slice';
import { paymentsReducer } from './slices/payments.slice';
import { formsReducer } from './slices/forms.slice';
import { serviceListReducer } from './slices/serviceList.slice';
import { PatientsReducer } from './slices/patients/patients.slice';
import { InvitationsReducer } from './slices/invitations/invitations.slice';
import { TestimonialsReducer } from './slices/testimonials/testimonials.slice';
import { CalendarEventReducer } from './slices/calendarEvents/calendarEvents.slice';
import { MarketReducer } from './slices/market/market.slice';
import { HistoryReducer } from './slices/history/history.slice';
import { VirtualAgendaReducer } from './slices/virtualAgenda/virtualAgenda.slice';
import { AffectedPatientsReducer } from './slices/affectedPatients/affectedPatients.slice';
import { WNUserReducer } from './slices/user/user.slice';
import { WNPatientReducer } from './slices/patient/patient.slice';
import { WNNotificationsReducer } from './slices/notifications/notifications.slice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    modal: modalReducer,
    user: userReducer,
    cloud: cloudReducer,
    patient: patientReducer,
    drawers: drawersReducer,
    payments: paymentsReducer,
    forms: formsReducer,
    serviceList: serviceListReducer,
    patients: PatientsReducer,
    invitations: InvitationsReducer,
    testimonials: TestimonialsReducer,
    calendarEvents: CalendarEventReducer,
    market: MarketReducer,
    history: HistoryReducer,
    virtualAgenda: VirtualAgendaReducer,
    affectedPatients: AffectedPatientsReducer,
    wnUser: WNUserReducer,
    wnPatient: WNPatientReducer,
    wnNotifications: WNNotificationsReducer,
  },
});