import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  editableOfficeHours: {
    mode: '',
    data: {},
  },
};

const userSlice = createSlice({
  name: 'wnUser',
  initialState,
  reducers: {
    setWnUserData: (state, action) => {
      state.data = action.payload;
    },
    setWnUserEditableOfficeHours: (state, action) => {
      state.editableOfficeHours = action.payload;
    },
  },
});

export const { setWnUserData, setWnUserEditableOfficeHours } =
  userSlice.actions;
export const WNUserReducer = userSlice.reducer;
