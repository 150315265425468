import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: 8,
    textAlign: 'center',
  },
  name: {
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  helperText: {
    fontSize: 13,
  },
  button: {
    fontSize: 12,
  },
}));
