export const typography = {
  fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
  body1: {
    '@media (max-width:600px)': {
      fontSize: 15,
    },
  },
  body2: {
    '@media (max-width:600px)': {
      fontSize: 13,
    },
  },
  h1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 34,
    fontWeight: 700,
    '@media (max-width:600px)': {
      fontSize: 22,
    },
  },
  h2: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 28,
    fontWeight: 700,
    '@media (max-width:600px)': {
      fontSize: 20,
    },
  },
  h3: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 20,
    fontWeight: 700,
  },
  h4: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 18,
    fontWeight: 700,
  },
  h5: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 700,
  },
  h6: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeight: 700,
  },
};
