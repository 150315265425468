import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { nutritionistServicesSelector } from 'redux/slices/user.selector';

import { usePatchProfile } from 'hooks/nutritionist.hook';

const DeleteNutritionistServicePanel = () => {
  const { services: activeServices } = useSelector(
    nutritionistServicesSelector,
  );

  const edtiableData = useSelector(state => state.forms.profile.target);

  const { mutateAsync: updateProfile, isLoading } = usePatchProfile();

  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    const services = activeServices.filter(
      service => service._id !== edtiableData._id,
    );
    const payload = {
      nutritionistProfile: {
        services,
      },
    };
    updateProfile(payload).then(() => {
      dispatch(
        setModalStatus({
          name: 'DELETE_NUTRITIONIST_SERVICE',
          isActive: false,
        }),
      );
    });
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'DELETE_NUTRITIONIST_SERVICE',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Estás seguro que deseas borrar este servicio?</Typography>
      <Box
        mt={2}
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmHandler}
          disabled={isLoading}
        >
          Confirmar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteNutritionistServicePanel;
