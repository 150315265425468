import { Box, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import { useStyles } from './WellcomeInfo.styles';

const WellcomeInfo = () => {
  const classes = useStyles();
  return (
    <Box component="main">
      <Typography
        variant="h5"
        style={{
          textAlign: 'center',
          textTransform: 'uppercase',
          paddingBottom: 16,
        }}
      >
        Bienvenido(a)
      </Typography>
      <Typography variant="h6" style={{ textAlign: 'center' }}>
        Regístrate y empieza a disfrutar de los beneficios que te ofrece Wellnub
      </Typography>
      <Box component="section" py={2}>
        <ul>
          <li className={classes.item}>
            <Typography className={classes.flex}>
              <CheckIcon fontSize="small" className={classes.check} />
              Acceso sin costo a nuestra plataforma para dar de alta hasta 30
              pacientes
            </Typography>
          </li>
          <li className={classes.item}>
            <Typography className={classes.flex}>
              <CheckIcon fontSize="small" className={classes.check} />
              Acceso a análisis de composición corporal InBody&reg; (precios
              inigualables)
            </Typography>
          </li>
          <li className={classes.item}>
            <Typography className={classes.flex}>
              <CheckIcon fontSize="small" className={classes.check} />
              Acceso a nuestra red de InBody&reg; 270 para medir a tus pacientes
              (cómodo, rápido y accesible)
            </Typography>
          </li>
          <li className={classes.item}>
            <Typography className={classes.flex}>
              <CheckIcon fontSize="small" className={classes.check} />
              Crea tu perfil profesional detallado para que más pacientes
              potenciales te encuentren
            </Typography>
          </li>
          <li className={classes.item}>
            <Typography className={classes.flex}>
              <CheckIcon fontSize="small" className={classes.check} />
              Las herramientas y servicios que necesitas para elevar tu consulta
              online
            </Typography>
          </li>
        </ul>
      </Box>
      <Box component="footer">
        <Typography variant="h6" className={classes.title}>
          La única plataforma que te da acceso a una red privada de InBody&reg;
        </Typography>
      </Box>
    </Box>
  );
};

export default WellcomeInfo;
