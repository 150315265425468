import { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import { setEditableTarget, setMode } from 'redux/slices/forms.slice';

import { setModalStatus } from 'redux/slices/modal.slice';

import { useStyles } from './ServicesRow.styles';
import { nutritionistServicesSelector } from '../../../redux/slices/user.selector';

const ServicesRow = ({ service }) => {
  const [duration] = useState(() => {
    if (!service.minutesLenght) return new Date(2000, 0, 0, 0, 0);
    const hours = Math.floor(service.minutesLenght / 60);
    const minutes = service.minutesLenght % 60;
    const duration = new Date();
    duration.setHours(hours);
    duration.setMinutes(minutes);
    return duration;
  });

  const nutritionist = useSelector(nutritionistServicesSelector);

  const dispatch = useDispatch();

  const classes = useStyles();

  const onEditHandler = () => {
    batch(() => {
      dispatch(setMode('editable'));
      dispatch(setEditableTarget(service));
    });
  };

  const onDeleteHandler = () => {
    if (nutritionist.services.length === 1) {
      dispatch(
        setModalStatus({
          name: 'AVOID_TO_DELETE_SERVICES_WARNING',
          isActive: true,
        }),
      );
      return;
    }
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'DELETE_NUTRITIONIST_SERVICE',
          isActive: true,
        }),
      );
      dispatch(setEditableTarget(service));
      dispatch(setMode('delete'));
    });
  };

  return (
    <TableRow className={classes.row}>
      {/* ---- ACCIONES ---- */}
      <TableCell align="center">
        <Tooltip title="Editar">
          <IconButton onClick={onEditHandler} size="small">
            <CreateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton onClick={onDeleteHandler} size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>

      {/* ---- NOMBRE ---- */}
      <TableCell align="center">
        <Typography variant="caption">{service.name}</Typography>
      </TableCell>

      {/* ---- DURACIÓN ---- */}
      <TableCell align="center">
        <Typography variant="caption">{format(duration, 'H:mm')}</Typography>
      </TableCell>

      {/* ---- PRECIO ---- */}
      <TableCell align="center">
        <Typography variant="caption">
          {new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
          }).format(service.price)}
        </Typography>
      </TableCell>

      {/* ---- MODALIDAD ---- */}
      <TableCell align="center">
        <Typography variant="caption">
          {service.mode
            .map(
              item =>
                ({
                  online: 'En línea',
                  home: 'A domicilio',
                  site: 'Presencial',
                }[item]),
            )
            .join('/')}
        </Typography>
      </TableCell>

      {/* ---- DESCRIPCIÓN ---- */}
      <TableCell align="center">
        <Typography variant="caption">{service.description}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default ServicesRow;
