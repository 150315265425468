import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Link,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

import fb from 'assets/icons/facebook.png';
import ig from 'assets/icons/instagram.png';

import { useStyles } from './Footer.styles';

const Footer = (_, ref) => {
  const classes = useStyles();

  const onOpenFacebookHandler = () => {
    window.open('https://www.facebook.com/Wellnub-109247271500626', '_blank');
  };

  const onOpenInstagramHandler = () => {
    window.open('https://www.instagram.com/wellnub.expert/', '_blank');
  };

  return (
    <footer className={classes.footer} ref={ref}>
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          <Grid item sm={10}>
            <Grid container>
              <Grid item md={4} sm={4} xs={12}>
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.info}
                >
                  Copyright &reg; Wellnub {new Date().getFullYear()}
                </Typography>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <Link
                  to="/conditions"
                  target="_blank"
                  underline="none"
                  component={RouterLink}
                  className={classes.info}
                >
                  Términos y Condiciones
                </Link>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <Link
                  to="/privacy"
                  target="_blank"
                  underline="none"
                  component={RouterLink}
                  className={classes.info}
                >
                  Aviso de privacidad de datos
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item sm={6}>
                <IconButton onClick={onOpenFacebookHandler}>
                  <CardMedia
                    component="img"
                    image={fb}
                    style={{ width: 30, height: 30 }}
                  />
                </IconButton>
              </Grid>
              <Grid item sm={6}>
                <IconButton onClick={onOpenInstagramHandler}>
                  <CardMedia
                    component="img"
                    image={ig}
                    style={{ width: 30, height: 30 }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default forwardRef(Footer);
