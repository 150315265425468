import { nanoid } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  WellnubTextInput,
  WellnubDateInput,
  WellnubSelectInput,
  WellnubNumberInput,
  WellnubCountryCodeInput,
} from '@wellnub/web-common';

import { usePatchProfile } from 'hooks/nutritionist.hook';
import { nutritionistGeneralDataSelector } from 'redux/slices/user.selector';

import ImageUploader from '../../../ImageUploader/ImageUploader.component';

import { FormSchema } from './ProfileGeneralDataForm.schema';

import { useStyles } from './ProfileGeneralDataForm.styles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

const GENDER_OPTIONS = [
  { id: nanoid(), value: 'female', label: 'Mujer' },
  { id: nanoid(), value: 'male', label: 'Hombre' },
];

const ProfileGeneralDataForm = () => {
  const { path: pictureUrl } = useSelector(state => state.cloud.photo);
  const nutritionist = useSelector(nutritionistGeneralDataSelector);
  const { isProfileComplete } = useSelector(state => state.user);

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      name: nutritionist.name,
      lastName: nutritionist.lastName,
      email: nutritionist.email,
      sex: nutritionist.sex,
      about: nutritionist.about,
      phoneNumber: nutritionist.phoneNumber,
      countryCode: nutritionist.countryCode,
      birthdate: nutritionist.birthdate,
    },
  });

  const { mutateAsync: updateProfile, isLoading } = usePatchProfile();

  const { push } = useHistory();

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const onSubmitHandler = handleSubmit(data => {
    const { email, about, ...rest } = data;
    updateProfile({ ...rest, pictureUrl, nutritionistProfile: { about } }).then(
      () => {
        if (!isProfileComplete) {
          setTimeout(() => {
            push('/settings/profile/specialties');
          }, 1000);
        }
        enqueueSnackbar('Tu información ha sido actualizada con éxito', {
          variant: 'success',
        });
        queryClient.invalidateQueries('GET_DETAILS');
      },
    );
  });
  const onOmitStepHandler = () => {
    push('/settings/profile/specialties');
  };

  return (
    <form noValidate onSubmit={onSubmitHandler}>
      <Box component="section" mb={4}>
        <Box mb={2}>
          <Typography variant="h6" className={classes.title}>
            Datos generales
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Tus datos e información básicos para que los pacientes sepan quien
            eres y puedan contactarte.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <WellnubTextInput
              fullWidth
              control={control}
              name="name"
              label="Nombre *"
              helperText="Escribe tu nombre"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <WellnubTextInput
              fullWidth
              control={control}
              name="lastName"
              label="Apellido *"
              helperText="Escribe tu apellido"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <WellnubTextInput
              fullWidth
              disabled
              type="email"
              control={control}
              name="email"
              label="Email"
              helperText="Escribe tu correo electrónico"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <WellnubDateInput
              fullWidth
              control={control}
              format="dd/MM/yyyy"
              name="birthdate"
              helperText="Escribe tu fecha de nacimiento"
              label="Fecha de nacimiento *"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <WellnubCountryCodeInput
                  fullWidth
                  label="Región"
                  control={control}
                  variant="standard"
                  name="countryCode"
                />
              </Grid>
              <Grid item xs={8}>
                <WellnubNumberInput
                  fullWidth
                  label="Celular *"
                  control={control}
                  name="phoneNumber"
                  format="## #### ####"
                  defaultValue={nutritionist.phoneNumber}
                  helperText="Celular de contacto a 10 dígitos"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <WellnubSelectInput
              fullWidth
              options={GENDER_OPTIONS}
              control={control}
              name="sex"
              label="Sexo"
            />
          </Grid>
          <Grid item xs={12}>
            <WellnubTextInput
              fullWidth
              multiline
              minRows={2}
              variant="outlined"
              control={control}
              label="Acerca de mi"
              type="text"
              name="about"
              helperText="Información adicional sobre mi, mi experiencia, mi práctica, mis consultas..."
            />
          </Grid>
        </Grid>
      </Box>
      <Box component="section" mb={4}>
        <Box mb={2}>
          <Typography variant="h6" className={classes.title}>
            Foto
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Si bien no es un campo obligatorio, te sugerimos subir una foto
            profesional (de buena calidad y fondo limpio o blanco) para crear
            una excelente primera impresión con tus pacientes.
          </Typography>
        </Box>
        <Grid>
          <Grid item xs={12} sm={4}>
            <ImageUploader name="photo" />
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isLoading}
      >
        {!isProfileComplete ? 'Guardar y continuar' : 'Actualizar'}
      </Button>
      {!isProfileComplete && (
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 16 }}
          onClick={onOmitStepHandler}
        >
          Omitir
        </Button>
      )}
    </form>
  );
};

export default ProfileGeneralDataForm;
