import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    padding: theme.spacing(4),
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttons: {
    marginTop: 'auto',
  },
}));
