import { batch, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { Box, Button, Paper, Typography } from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setPatientData, setPatientMode } from 'redux/slices/patient.slice';

import { useGetGeneralInstructions } from 'hooks/patient.hook';

import { useStyles } from './LinkRequestCard.styles';
import { useState } from 'react';

const LinkRequestCard = ({
  editable = false,
  variant,
  publicField,
  patient,
  nutritionPartner,
  withInstructions = false,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const { refetch } = useGetGeneralInstructions(patient._id);

  const onOpenModalHandler = () => {
    if (variant === 'nutritionist') {
      batch(() => {
        dispatch(
          setPatientData({
            ...patient,
            requestId: nutritionPartner,
          }),
        );
        dispatch(
          setModalStatus({
            isActive: true,
            name: 'LINK_REQUEST_BY_NUTRITIONIST',
          }),
        );
        dispatch(setPatientMode(editable ? 'editable' : 'normal'));
      });
    }
    if (variant === 'patient') {
      batch(() => {
        dispatch(setPatientData({ ...patient, requestId: nutritionPartner }));
        dispatch(
          setModalStatus({
            isActive: true,
            name: 'LINK_REQUEST_BY_PATIENT',
          }),
        );
      });
    }
  };

  const onCancelHandler = () => {
    if (variant === 'nutritionist') {
      batch(() => {
        dispatch(setPatientData({ ...patient, requestId: nutritionPartner }));
        dispatch(
          setModalStatus({
            name: 'CANCEL_LINK_REQUEST',
            isActive: true,
          }),
        );
      });
    }
    if (variant === 'patient') {
      batch(() => {
        dispatch(setPatientData({ ...patient, requestId: nutritionPartner }));
        dispatch(
          setModalStatus({
            name: 'REJECT_LINK_REQUEST',
            isActive: true,
          }),
        );
      });
    }
  };

  const onAcceptHandler = () => {
    batch(() => {
      dispatch(setPatientData({ ...patient, requestId: nutritionPartner }));
      dispatch(
        setModalStatus({
          name: 'ACCEPT_LINK_REQUEST',
          isActive: true,
        }),
      );
    });
  };

  const onCopyInstructionsHandler = () => {
    setIsLoading(true);
    refetch()
      .then(({ data }) => fetch(`data:image/jpeg;base64,${data}`))
      .then(response => response.blob())
      .then(file => {
        saveAs(file, 'wellnub-instructions.jpg');
        setIsLoading(false);
      });
  };

  return (
    <Paper elevation={3} component="article" className={classes.paper}>
      {variant === 'nutritionist' && (
        <Typography className={classes.name} onClick={onOpenModalHandler}>
          {patient[publicField]}
        </Typography>
      )}
      {variant === 'patient' && (
        <Typography className={classes.name} onClick={onOpenModalHandler}>
          {patient.name} {patient.lastName}
        </Typography>
      )}
      <Box py={2} component="main">
        <Typography variant="body2" className={classes.helperText}>
          Confirmación pendiente de: <br />
          {variant === 'patient' && 'Nutriólogo'}
          {variant === 'nutritionist' && 'Paciente'}
        </Typography>
      </Box>
      <Box component="footer">
        {variant === 'patient' && (
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={onAcceptHandler}
          >
            Aceptar
          </Button>
        )}
        <Button
          variant="text"
          color="primary"
          className={classes.button}
          onClick={onCancelHandler}
        >
          {variant === 'patient' && 'Rechazar'}
          {variant === 'nutritionist' && 'Cancelar'}
        </Button>
        {withInstructions && (
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={onCopyInstructionsHandler}
            disabled={isLoading}
          >
            Descargar Instrucciones
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default LinkRequestCard;
