import { useHistory } from 'react-router-dom';

import { Box, Drawer } from '@material-ui/core';

import Button from '../../Button/Button.component';

import { DRAWERS } from 'utils/constants';

import { useDrawer } from 'hooks/drawers.hook';

import { useStyles } from './SignInDrawer.styles';

const SignInDrawer = () => {
  const { visibility, handleClose } = useDrawer(DRAWERS.signIn);

  const { push } = useHistory();

  const classes = useStyles();

  const onSignInClickHandler = () => {
    push('/signIn');
    handleClose();
  };

  const onSignUpClickHandler = () => {
    push('/signUp');
    handleClose();
  };

  return (
    <Drawer
      open={visibility}
      anchor="right"
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box className={classes.buttons}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSignInClickHandler}
        >
          Iniciar sesión
        </Button>
        <Box pt={2}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onSignUpClickHandler}
          >
            Registrarse
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SignInDrawer;
