import CreditCardDrawer from '../CreditCard/CreditCardDrawer.component';
import CheckoutDrawer from '../Checkout/CheckoutDrawer.component';
import SignInDrawer from '../SingIn/SignInDrawer.component';

const DrawerManager = () => (
  <>
    <CreditCardDrawer />
    <CheckoutDrawer />
    <SignInDrawer />
  </>
);

export default DrawerManager;
