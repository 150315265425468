import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useHistory } from 'react-router-dom';

const CompletedProfileWarningPanel = () => {
  const dispatch = useDispatch();

  const { replace } = useHistory();

  const onCloseHandler = () => {
    dispatch(
      setModalStatus({
        name: 'COMPLETED_PROFILE_WARNING',
        isActive: false,
      }),
    );
  };

  const onNavigateHandler = () => {
    onCloseHandler();
    setTimeout(() => {
      replace('/dashboard/patients');
    }, 1000);
  };

  return (
    <Box>
      <Typography variant="h6" align="center">
        ¡Listo! Gracias por tomarte el tiempo para compartirnos tu información.
      </Typography>
      <Typography align="center" style={{ paddingTop: 8 }}>
        ¿Qué deseas hacer ahora?
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        pt={1}
      >
        <Button variant="outlined" color="primary" onClick={onCloseHandler}>
          Quedarme en configuración
        </Button>
        <Button variant="contained" color="primary" onClick={onNavigateHandler}>
          Comenzar a utilizar Wellnub
        </Button>
      </Box>
    </Box>
  );
};

export default CompletedProfileWarningPanel;
