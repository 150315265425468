import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { set } from 'date-fns';
import { Box, Button, Grid } from '@material-ui/core';
import {
  WellnubDateInput,
  WellnubTimeInput,
  WellnubCheckboxInput,
} from '@wellnub/web-common';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateNutritionistOfficeDayOff } from 'hooks/nutritionist.hook';

import { FormSchema } from './AddOfficeScheduleDaysOffForm.schema';

const AddOfficeScheduleDaysOffForm = () => {
  const { mutate: createDayOff, isLoading } =
    useCreateNutritionistOfficeDayOff();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      isAllDay: false,
      date: new Date(),
      startTime: new Date(2022, 1, 1, 8, 0),
      endTime: new Date(2022, 1, 1, 8, 0),
    },
  });

  const onSubmitHandler = handleSubmit(data => {
    const startHours = data.startTime.getHours();
    const startMinutes = data.startTime.getMinutes();
    const endHours = data.endTime.getHours();
    const endMinutes = data.endTime.getMinutes();
    const payload = {
      startDate: isAllDay
        ? set(data.date, { hours: 0, minutes: 0 }).toISOString()
        : set(data.date, {
            hours: startHours,
            minutes: startMinutes,
          }).toISOString(),
      endDate: isAllDay
        ? set(data.date, { hours: 23, minutes: 59 }).toISOString()
        : set(data.date, {
            hours: endHours,
            minutes: endMinutes,
          }).toISOString(),
    };
    createDayOff(payload);
  });

  const isAllDay = watch('isAllDay');

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <WellnubTimeInput
            fullWidth
            disabled={isAllDay}
            inputVariant="outlined"
            control={control}
            name="startTime"
            label="Hora inicio"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WellnubTimeInput
            fullWidth
            disabled={isAllDay}
            inputVariant="outlined"
            control={control}
            name="endTime"
            label="Hora fin"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <WellnubDateInput
            fullWidth
            inputVariant="outlined"
            control={control}
            name="date"
            format="dd/MM/yyyy"
            label="Día de año"
          />
        </Grid>
      </Grid>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <WellnubCheckboxInput
          name="isAllDay"
          control={control}
          label="Ingresar todo el día"
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          Guardar
        </Button>
      </Box>
    </form>
  );
};

export default AddOfficeScheduleDaysOffForm;
