import { useStyles } from './OfficeScheduleDaysOffTable.styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import OfficeScheduleDaysOffRow from '../../Rows/OfficeScheduleDaysOffRow/OfficeScheduleDaysOffRow.component';

const OfficeScheduleDaysOffTable = ({ daysOff }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell align="center" className={classes.header}>
              Acciones
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Horario
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Día
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {daysOff.map(dayOff => (
            <OfficeScheduleDaysOffRow key={dayOff._id} dayOff={dayOff} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfficeScheduleDaysOffTable;
