import { useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import { useDeleteNutritionalPlan } from 'hooks/patient.hook';

import Button from '../../Button/Button.component';

const DeleteNutritionalPlanForm = () => {
  const { id } = useSelector(
    state => state.patient.profile.nutritionalPlanSelected,
  );

  const { mutate } = useDeleteNutritionalPlan();

  const onConfirmHandler = () => {
    mutate(id);
  };

  return (
    <Box>
      <Typography>
        ¿Confirmas que deseas eliminar este plan de nutrición?
      </Typography>
      <Typography>No podrás deshacer esta acción</Typography>
      <Box mt={2} textAlign="center">
        <Button variant="contained" color="primary" onClick={onConfirmHandler}>
          Confirmar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteNutritionalPlanForm;
