import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Button } from 'components';

import { setModalStatus } from 'redux/slices/modal.slice';

import { useAcceptLinkRequest } from 'hooks/nutritionist.hook';
import { useInbodyPasses } from 'hooks/patient.hook';

const AcceptLinkRequest = () => {
  const [isAcceptedToAddPass, setIsAcceptedToAddPass] = useState('decline');

  const { data } = useSelector(state => state.patient);
  const { availablePasses } = useSelector(state => state.user);

  const { mutate: acceptLinkRequest, isLoading: isLoadingAccept } =
    useAcceptLinkRequest();
  const { mutate: addPass, isLoading: isLoadingAddPass } = useInbodyPasses();

  const { push } = useHistory();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    if (isAcceptedToAddPass === 'accept') {
      acceptLinkRequest(data.requestId);
      addPass(data._id);
    } else {
      acceptLinkRequest(data.requestId);
    }
  };

  const onChangeAccepted = event => {
    setIsAcceptedToAddPass(event.target.value);
  };

  const onRedirectHandler = () => {
    dispatch(
      setModalStatus({
        name: 'ACCEPT_LINK_REQUEST',
        isActive: false,
      }),
    );
    push('/settings/payments/passes');
  };

  useEffect(() => {
    if (availablePasses !== 0) {
      setIsAcceptedToAddPass('accept');
    }
  }, [availablePasses]);

  return (
    <Box>
      <Typography style={{ marginBottom: 8 }}>
        ¿Confirmas aceptar la solicitud de este paciente?
      </Typography>
      <Typography style={{ marginBottom: 8 }}>
        Al confirmar esta solicitud, se vinculará este paciente contigo y te
        tomará un slot de tus pacientes activos
      </Typography>
      <Typography style={{ marginBottom: 8 }}>
        ¿Deseas agregar automáticamente un pase a este paciente cuando se
        vincule contigo?
      </Typography>
      <FormControl
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <RadioGroup row value={isAcceptedToAddPass} onChange={onChangeAccepted}>
          <FormControlLabel
            value="decline"
            control={<Radio color="primary" />}
            label="No, sin pase"
          />
          <FormControlLabel
            value="accept"
            control={<Radio color="primary" />}
            label="Si, agregar pase"
          />
        </RadioGroup>
      </FormControl>
      <Box textAlign="center" mt={2}>
        <Button
          loading={isLoadingAddPass || isLoadingAccept}
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isAcceptedToAddPass === 'accept' && availablePasses === 0}
        >
          Confirmar
        </Button>
      </Box>
      <Box mt={2}>
        {isAcceptedToAddPass === 'accept' && availablePasses === 0 && (
          <Alert severity="warning">
            Ya no cuentas con pases disponibles, para adquirir más
            presiona&nbsp;
            <span
              onClick={onRedirectHandler}
              style={{
                color: 'currentcolor',
                fontWeight: 700,
                cursor: 'pointer',
              }}
            >
              aquí
            </span>
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default AcceptLinkRequest;
