import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { Box, Typography } from '@material-ui/core';
import { Button } from 'components';
import {
  useGetGeneralInstructions,
  usePatientCreation,
} from 'hooks/patient.hook';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { setModalStatus } from '../../../redux/slices/modal.slice';
import { useState } from 'react';

const AddPatientConfirmationForm = () => {
  const [wellnubTicketID, setWellnubTicketID] = useState('');
  const { dataForRegister } = useSelector(state => state.patient.placeholder);
  const { isAddedFromVirtualAgenda } = useSelector(state => state.patient);
  const { data, mode } = useSelector(
    state => state.virtualAgenda.pendingAppointmentAfterCreatePatient,
  );

  const { mutateAsync, isLoading } = usePatientCreation();

  const { refetch } = useGetGeneralInstructions(wellnubTicketID);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const onConfirmationClickHandler = () => {
    const { withPass, isDownloadable, ...rest } = dataForRegister;
    mutateAsync({
      shouldAddPass: withPass === 'accept',
      addedFromCalendar: isAddedFromVirtualAgenda,
      data: rest,
    })
      .then(response => {
        setWellnubTicketID(response._id);
        if (!isEmpty(data) && mode === 'persist') {
          dispatch(
            setModalStatus({
              name: 'CALENDAR_EVENT',
              isActive: true,
            }),
          );
        }
        if (dataForRegister.isDownloadable) {
          enqueueSnackbar(
            'En un momento comenzará la descarga de las instrucciones para tu paciente',
            {
              variant: 'info',
            },
          );
          setTimeout(() => {
            refetch()
              .then(({ data }) => fetch(`data:image/jpeg;base64,${data}`))
              .then(response => response.blob())
              .then(file => {
                enqueueSnackbar('Descarga exitosa', { variant: 'success' });
                saveAs(file, 'wellnub-instructions.jpg');
              })
              .catch(() => {
                enqueueSnackbar(
                  'No se pudo descargar las insctucciones. Intenta más tarde',
                  {
                    variant: 'warning',
                  },
                );
              });
          }, 1000);
        }
      })
      .catch(() => {});
  };

  const onEditDataHandler = () => {
    dispatch(
      setModalStatus({
        name: 'ADD_PATIENT_CONFIRMATION',
        isActive: false,
      }),
    );
    setTimeout(() => {
      dispatch(
        setModalStatus({
          name: 'PATIENT_CREATION',
          isActive: true,
        }),
      );
    }, 500);
  };

  return (
    <Box>
      <Typography>
        Estas a punto de crear un nuevo paciente en Wellnub.
      </Typography>
      <Typography style={{ marginTop: 16, maxWidth: '60ch' }}>
        Su usuario y contraseña serán su email y número celular a 10 dígitos
        (respectivamente) con los que lo estás dando de alta.
      </Typography>
      <Typography style={{ marginTop: 16 }}>
        Por favor confirma los datos de tu nuevo paciente:
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">
          <Typography variant="h6" component="span">
            Correo: {dataForRegister?.email ?? ''}
          </Typography>
        </Typography>
        <Typography variant="body2">
          <Typography variant="h6" component="span">
            Contraseña (celular): {dataForRegister?.phoneNumber ?? ''}
          </Typography>
        </Typography>
        <Typography variant="body2">
          <Typography variant="h6" component="span">
            ¿Agregar pase al vincular?{' '}
            {dataForRegister.withPass === 'accept' ? 'Si' : 'No'}
          </Typography>
        </Typography>
        <Typography variant="body2">
          <Typography variant="h6" component="span">
            ¿Descargar instrucciones después de vincular?&nbsp;
            {dataForRegister.isDownloadable ? 'Si' : 'No'}
          </Typography>
        </Typography>
      </Box>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmationClickHandler}
          loading={isLoading}
        >
          Confirmar
        </Button>
        <Button variant="outlined" color="primary" onClick={onEditDataHandler}>
          Corregir datos
        </Button>
      </Box>
    </Box>
  );
};

export default AddPatientConfirmationForm;