import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import Button from '../../../Button/Button.component';

import { useVerification } from 'hooks/patient.hook';

import {
  setEmailPlaceholder,
  setPatientPublicField,
  setPhoneNumberPlaceholder,
  setCountryCodePlaceholder,
} from 'redux/slices/patient.slice';
import { batch } from 'react-redux';

import { useStyles } from './PatientVerificationForm.styles';
import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';

const PatientVerificationForm = () => {
  const [type, setType] = useState('email');

  const { mutate: verify, isLoading } = useVerification();

  const { control, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const classes = useStyles();

  const label = {
    email: 'e-mail',
    phoneNumber: 'celular',
  }[type];

  const onChangeTypeHandler = event => {
    setType(event.target.value);
  };

  const onSubmitHandler = data => {
    const { email, phoneNumber, countryCode } = data;
    if (type === 'email') {
      batch(() => {
        dispatch(setEmailPlaceholder(email));
        dispatch(setPatientPublicField('email'));
      });
      verify({ email });
    }
    if (type === 'phoneNumber') {
      batch(() => {
        dispatch(setPhoneNumberPlaceholder(phoneNumber));
        dispatch(setPatientPublicField('phoneNumber'));
        dispatch(setCountryCodePlaceholder(countryCode));
      });
      verify({ phoneNumber, countryCode });
    }
  };

  useEffect(() => {
    batch(() => {
      dispatch(setEmailPlaceholder(''));
      dispatch(setPhoneNumberPlaceholder(''));
    });
  }, [dispatch]);

  return (
    <>
      <Typography>
        Por favor ingresa el {label} del paciente que desea agregar
      </Typography>
      <Box pt={2}>
        <FormControl fullWidth component="fieldset">
          <RadioGroup
            name="verificationType"
            value={type}
            onChange={onChangeTypeHandler}
          >
            <Grid container>
              <Grid item xs={6} className={classes.radioContainer}>
                <FormControlLabel
                  label="Email"
                  value="email"
                  control={<Radio color="primary" />}
                />
              </Grid>
              <Grid item xs={6} className={classes.radioContainer}>
                <FormControlLabel
                  label="Celular"
                  value="phoneNumber"
                  control={<Radio color="primary" />}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit(onSubmitHandler)}>
        {type === 'email' && (
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Este campo es requerido',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Escribe un correo válido',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                label="Email"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Escribe el email'}
              />
            )}
          />
        )}
        {type === 'phoneNumber' && (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <WellnubCountryCodeInput
                label="Región"
                control={control}
                name="countryCode"
                defaultValue="52"
                rules={{ required: 'Este campo es requerido' }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <WellnubNumberInput
                fullWidth
                variant="outlined"
                label="Celular *"
                name="phoneNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Este campo es obligatorio',
                  pattern: {
                    value: /[0-9]{10}/,
                    message: 'Escribe un número válido',
                  },
                }}
                format="## #### ####"
                helperText="Celular de contacto a 10 dígitos"
              />
            </Grid>
          </Grid>
          // <Controller
          //   control={control}
          //   defaultValue=""
          //   name="phoneNumber"
          //   rules={{
          //     validate: value => validatePhoneNumber(value),
          //   }}
          //   render={({ field: { value, onChange }, fieldState: { error } }) => (
          //     <MUIPhoneInput
          //       value={value}
          //       fullWidth
          //       variant="outlined"
          //       error={!!error}
          //       label="Celular"
          //       defaultCountry="mx"
          //       onChange={onChange}
          //       helperText={
          //         error?.message ?? 'Celular de contacto a 10 dígitos'
          //       }
          //       onlyCountries={['mx', 'us']}
          //     />
          //   )}
          // />
        )}
        <Box pt={2} textAlign="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Confirmar
          </Button>
        </Box>
      </form>
    </>
  );
};

export default PatientVerificationForm;
