import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useHistory } from 'react-router-dom';

const NoServicesWarningPanel = () => {
  const dispatch = useDispatch();

  const { replace } = useHistory();

  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: 'NO_SERVICES_WARNING',
        isActive: false,
      }),
    );
  };

  const onRedirectHandler = () => {
    onAcceptHandler();
    setTimeout(() => {
      replace('/settings/profile/services');
    }, 1000);
  };

  return (
    <Box textAlign="center">
      <Typography variant="h6">
        No tienes servicios disponibles para agendar.
      </Typography>
      <Typography variant="body2" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Para poder agendar una cita, es necesario tener algun servicio dado de
        alta.
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-evenly">
        <Button variant="outlined" onClick={onAcceptHandler} color="primary">
          Aceptar
        </Button>
        <Button variant="contained" onClick={onRedirectHandler} color="primary">
          Ir a mis servicios
        </Button>
      </Box>
    </Box>
  );
};

export default NoServicesWarningPanel;
