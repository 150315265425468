import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactGA4 from 'react-ga4';
import { Switch, Route } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';

import {
  SignUpPage,
  SignInPage,
  HomePage,
  PatientPage,
  // PatientsPage,
  SettingsPage,
  FirstSignInPage,
  PrivacyPage,
  ConditionsPage,
  TutorialsPage,
  DashboardPage,
  VerificationCompletePage,
} from 'pages';

import { ModalManager, Header, Footer, DrawerManager } from 'components';

import { useGetNotificationCounter } from 'hooks/nutritionist.hook';

import { setAuthentication } from 'redux/slices/authentication.slice';

import { withProtected, withRedirect } from 'utils/authentication';

const useStyles = makeStyles(() => ({
  app: {
    minHeight: props =>
      `calc(100vh - ${props.footerHeight}px - ${props.headerHeight}px)`,
  },
}));

const App = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const { isLoading, isError } = useGetNotificationCounter();

  const dispatch = useDispatch();

  const classes = useStyles({ headerHeight, footerHeight });

  const authStorage = JSON.parse(localStorage.getItem('authentication'));

  const measuredHeaderRef = useCallback(node => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const measuredFooterRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA4.initialize('G-KVNQXKD6BY');
    }
  }, []);

  if (authStorage) {
    dispatch(setAuthentication(true));
  }

  return (
    <>
      <DrawerManager />
      <ModalManager />
      <Header ref={measuredHeaderRef} />
      <Box component="main" className={classes.app}>
        <Switch>
          <Route exact path="/" component={withRedirect(HomePage)} />
          <Route exact path="/signIn" component={withRedirect(SignInPage)} />
          <Route exact path="/signUp" component={withRedirect(SignUpPage)} />

          <Route
            path="/dashboard/patients/:id"
            component={withProtected(PatientPage)}
          />
          <Route path="/dashboard" component={withProtected(DashboardPage)} />
          {/*<Route*/}
          {/*  exact*/}
          {/*  path="/patients"*/}
          {/*  component={withProtected(PatientsPage)}*/}
          {/*/>*/}
          <Route path="/settings" component={withProtected(SettingsPage)} />
          <Route path="/tutorials" component={withProtected(TutorialsPage)} />
          <Route path="/thankyou" component={withRedirect(FirstSignInPage)} />
          <Route exact path="/verification-completed">
            <VerificationCompletePage />
          </Route>
          <Route exact path="/privacy">
            <PrivacyPage />
          </Route>
          <Route exact path="/conditions">
            <ConditionsPage />
          </Route>
        </Switch>
      </Box>
      <Footer ref={measuredFooterRef} />
    </>
  );
};

export default App;
