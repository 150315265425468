import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import OfficesScheduleRow from '../../Rows/OfficesScheduleRow/OfficesScheduleRow.component';

import { useStyles } from './OfficeScheduleTable.styles';

const OfficeScheduleTable = ({ schedules }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell align="center" className={classes.header}>
              Acciones
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Horario
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Días de la semana
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Consultorio
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map(item => (
            <OfficesScheduleRow key={item._id} schedule={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfficeScheduleTable;
