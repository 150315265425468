import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: ({ isMobileSize }) =>
      isMobileSize ? '#f0f2ef' : 'transparent',
  },
  tab: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.primary.main,
  },
}));
