import { useSelector } from 'react-redux';

import { Typography, Box } from '@material-ui/core';

import { useInbodyPasses } from 'hooks/patient.hook';

import Button from '../../../Button/Button.component';

import { useStyles } from './PatientPassesForm.styles';
import { useSnackbar } from 'notistack';

const PatientPassesForm = () => {
  const {
    data: { _id },
  } = useSelector(state => state.patient);

  const { availablePasses } = useSelector(state => state.user);

  const { mutate: addPass, isLoading } = useInbodyPasses();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const onConfirmClickHandler = event => {
    event.preventDefault();
    if (availablePasses) {
      addPass(_id);
    } else {
      enqueueSnackbar('No tienes pases disponibles para agregar', {
        variant: 'warning',
      });
    }
  };

  return (
    <>
      <Typography className={classes.paragraph}>
        ¿Confirmas que deseas agregar 1 pase de escaneo corporal a este
        paciente? Al confirmar:
      </Typography>
      <ol style={{ marginLeft: 16 }}>
        <li>
          <Typography>
            Tomaremos un pase de tu cuenta de pases de escaneo corporal
            disponibles y se lo asignaremos a este paciente.
          </Typography>
        </li>
        <li>
          <Typography>
            Notificaremos a tu paciente a través de un correo.
          </Typography>
        </li>
        <li>
          <Typography>
            A través de su perfil de Wellnub, tu paciente podrá utilizar su pase
            para agendar un análisis Wellnub-InBody&reg; en cualquiera de
            nuestras locaciones disponibles.
          </Typography>
        </li>
      </ol>
      <Typography className={classes.paragraph} style={{ marginTop: 16 }}>
        Importante: una vez asignado el pase, esta acción no podrá ser revertida
      </Typography>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={onConfirmClickHandler}
        >
          Confirmar
        </Button>
      </Box>
    </>
  );
};

export default PatientPassesForm;
