import { object, string, ref } from 'yup';

export const FormSchema = object({
  currentPassword: string().required('Este campo es obligatorio'),
  password: string()
    .min(6, 'La contraseña debe de tener más de 6 caracteres')
    .required('Este campo es obligatorio'),
  confirmation: string()
    .oneOf([ref('password')], 'Las contraseñas deben coincidir')
    .required('Este campo es obligatorio'),
});
