import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  event: {
    action: '',
    type: '',
    start: '',
    end: '',
  },
  events: [],
};

const calendarEventsSlice = createSlice({
  name: 'calendarEvents',
  initialState,
  reducers: {
    setCalendarEvent: (state, action) => {
      state.event = action.payload;
    },
    setCalendarEvents: (state, action) => {
      state.events = action.payload;
    },
  },
});

export const { setCalendarEvent, setCalendarEvents } =
  calendarEventsSlice.actions;

export const CalendarEventReducer = calendarEventsSlice.reducer;
