import { createSelector } from '@reduxjs/toolkit';
import { notificationsAdapter } from './notifications.slice';

export const newNutritionistNotificationsSelector = createSelector(
  [state => state.wnNotifications.new],
  unreadNotifications => unreadNotifications,
);

export const { selectAll: notificationsSelector } =
  notificationsAdapter.getSelectors(
    state => state.wnNotifications.notifications,
  );