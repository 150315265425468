import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';

export const AvoidToDeleteOfficeWarning = () => {
  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    dispatch(
      setModalStatus({
        name: 'AVOID_TO_DELETE_OFFICE_WARNING',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="h6" align="center">
        ¡Recuerda! <br />
        Debes contar con al menos un consultorio
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ paddingTop: 16, paddingBottom: 16 }}
      >
        Para la experiencia de tus pacientes es importante que cuentes con al
        menos un consultorio.
      </Typography>
      <Box textAlign="center">
        <Button color="primary" variant="contained" onClick={onConfirmHandler}>
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default AvoidToDeleteOfficeWarning;
