import { object, string } from 'yup';

const REQUIRED_MESSAGE = 'Este campo es obligatorio';

const PHONE_NUMBER_REGEX = /[0-9]{10}/;

export const FormSchema = object({
  name: string().required(REQUIRED_MESSAGE),
  lastName: string().required(REQUIRED_MESSAGE),
  email: string().email('Escribe un email válido').required(REQUIRED_MESSAGE),
  countryCode: string().required(REQUIRED_MESSAGE),
  phoneNumber: string()
    .matches(PHONE_NUMBER_REGEX, 'Escribe un número válido')
    .required(REQUIRED_MESSAGE),
});
