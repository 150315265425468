import { wellnub } from '../wellnub.api';

export const requestDetails = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/user');
  return data;
};

export const getPlanUsers = () =>
  wellnub.get('/v1/nutritionist/user/usersInPlan');

export const requestSuscriptions = () =>
  wellnub.get('/v1/nutritionist/storeProduct/subscription');

export const getPasses = () =>
  wellnub.get('/v1/nutritionist/user/activePasses');

export const getServices = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/user/service');
  return data;
};

export const getSpecialities = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/user/speciality');
  return data;
};

export const patchProfile = payload =>
  wellnub.patch('/v1/nutritionist/user', payload);

export const patchPassword = payload =>
  wellnub.patch('/v1/users/password', payload);

export const getLinkRequests = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/linkRequest');
  return data;
};

export const postLinkRequest = payload => {
  return wellnub.post('/v1/nutritionist/linkRequest', payload);
};

export const acceptLinkRequest = id => {
  return wellnub.patch(`/v1/nutritionist/linkRequest/accept/${id}`);
};

export const rejectLinkRequest = id => {
  return wellnub.patch(`/v1/nutritionist/linkRequest/reject/${id}`);
};

export const cancelLinkRequest = id => {
  return wellnub.patch(`/v1/nutritionist/linkRequest/cancel/${id}`);
};

export const getPaymentsHistory = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/payment/history');
  return data;
};

export const getInbodyPassesHistory = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/inbodyPass/history');
  return data;
};

export const deletePatientBadges = async id => {
  const { data } = await wellnub.delete(
    `/v1/nutritionist/notification/clearInBodyBadges/${id}`,
  );
  return data;
};

export const getCalendarEvents = async () => {
  const { data } = await wellnub.get(`/v1/nutritionist/appointment`);
  return data;
};

export const createCalendarEvent = async payload => {
  // ---- VERSIÓN ANTERIOR A LA RELEASE 1.0.8 ----
  // const { data } = await wellnub.post(`/v1/nutritionist/appointment`, payload);
  const { data } = await wellnub.post(
    `/v1/nutritionist/wellnubService/nutritionistAppointment`,
    payload,
  );
  return data;
};

export const updateCalendarEvent = async payload => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/nutritionistAppointment/${payload.id}`,
    payload.data,
  );
  return data;
};

export const proposeNewDateForAppointment = async payload => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/nutritionistAppointment/${payload.id}`,
    payload.data,
  );
  return data;
};

export const acceptCalendarEvent = async id => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/nutritionistAppointment/accept/${id}`,
  );
  return data;
};

export const rejectCalendarEvent = async id => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/nutritionistAppointment/reject/${id}`,
  );
  return data;
};

export const deleteCalendarEvent = async id => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/nutritionistAppointment/cancel/${id}`,
  );
  return data;
};

export const getNutritionistTypesList = async () => {
  const { data } = await wellnub.get(`/v1/nutritionist/user/type`);
  return data;
};

export const getNutritionistDietsList = async () => {
  const { data } = await wellnub.get(`/v1/nutritionist/user/diet`);
  return data;
};

export const getNutritionistOffices = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/wellnubService/office');
  return data;
};

export const postCreateNutritionistOffice = async payload => {
  const { data } = await wellnub.post(
    `/v1/nutritionist/wellnubService/office`,
    payload,
  );
  return data;
};

export const patchUpdateNutritionistOffice = async payload => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/office/${payload.id}`,
    payload.data,
  );
  return data;
};

export const deleteDeleteNutritionistOffice = async id => {
  const { data } = await wellnub.delete(
    `/v1/nutritionist/wellnubService/office/${id}`,
  );
  return data;
};

export const patchUpdateNutritionistOfficeSettings = async payload => {
  const { data } = await wellnub.put(`/v1/nutritionist/user/settings`, payload);
  return data;
};

export const getNutritionistOfficeSchedule = async () => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/wellnubService/officeSchedule`,
  );
  return data;
};

export const postCreateNutritionistOfficeSchedule = async payload => {
  const { data } = await wellnub.post(
    `/v1/nutritionist/wellnubService/officeSchedule`,
    payload,
  );
  return data;
};

export const patchUpdateNutritionistOfficeSchedule = async payload => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/officeSchedule/${payload.id}`,
    payload.data,
  );
  return data;
};

export const deleteDeleteNutritionistOfficeSchedule = async id => {
  const { data } = await wellnub.delete(
    `/v1/nutritionist/wellnubService/officeSchedule/${id}`,
  );
  return data;
};

export const getNutritionistOfficeDaysOff = async () => {
  const { data } = await wellnub.get(`/v1/nutritionist/user/closingDate`);
  return data;
};

export const postCreateNutritionistOfficeDayOff = async payload => {
  const { data } = await wellnub.post(
    `/v1/nutritionist/user/closingDate`,
    payload,
  );
  return data;
};

export const patchEditNutritionistOfficeDayOff = async payload => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/user/closingDate/${payload.id}`,
    payload.data,
    payload,
  );
  return data;
};

export const deleteDelteNutritionistOfficeDayOff = async id => {
  const { data } = await wellnub.delete(
    `/v1/nutritionist/user/closingDate/${id}`,
  );
  return data;
};

export const getDeleteOffieAffectedPatients = async id => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/wellnubService/office/warnings/${id}`,
  );
  return data;
};

export const getOfficeHoursAffectedPatients = async id => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/wellnubService/officeSchedule/warnings/${id}`,
  );
  return data;
};

export const getAppointmentCounters = async () => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/wellnubService/nutritionistAppointment/counter`,
  );
  return data;
};

export const getPendingAppointmentsUserList = async () => {
  const { data } = await wellnub.get(
    '/v1/nutritionist/wellnubService/nutritionistAppointment/updates',
  );
  return data;
};

export const getNewNutritionistNotifications = async () => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/wellnubService/persistentNotification/new/count`,
  );
  return data;
};

export const getNotifications = async () => {
  const { data } = await wellnub.get(
    '/v1/nutritionist/wellnubService/persistentNotification',
  );
  return data;
};

export const markAsSeenNotification = async id => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/wellnubService/persistentNotification/markAsSeen/${id}`,
  );
  return data;
};
