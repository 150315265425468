import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Box, Backdrop } from '@material-ui/core';

import { useImageUpload } from 'hooks/imageUpload.hook';

import Button from '../Button/Button.component';

import { getCroppedImage } from 'utils/helpers';

import { useStyles } from './Cropper.styles';

const Cropper = ({ name, isOpen, closeHandler }) => {
  const [crop, setCrop] = useState({ aspect: 1, unit: '%', width: 30 });
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState('');

  const { base64 } = useSelector(state => state.cloud[name]);

  const { mutate: upload, isLoading, isSuccess } = useImageUpload(name);

  const classes = useStyles();

  let timeout;

  const onCropChangeHandler = crop => {
    setCrop(crop);
  };

  const onImageLoadedHandler = image => {
    setImage(image);
  };

  const onCropCompleteHandler = async crop => {
    if (crop.width && crop.height && image) {
      const croppedImage = await getCroppedImage(image, crop, 'cropped.jpeg');
      setCroppedImage(croppedImage);
    }
  };

  const onSaveCropHandler = event => {
    event.preventDefault();
    const data = new FormData();
    data.append('file', croppedImage);
    data.append('upload_preset', 'sluw6zqk');
    upload(data);
  };

  if (isSuccess) {
    timeout = setTimeout(() => {
      closeHandler();
    }, 250);
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  return (
    <Backdrop open={isOpen} classes={{ root: classes.backdrop }}>
      <Box className={classes.content}>
        <ReactCrop
          ruleOfThirds
          crop={crop}
          src={base64}
          className={classes.cropper}
          onChange={onCropChangeHandler}
          onComplete={onCropCompleteHandler}
          onImageLoaded={onImageLoadedHandler}
        />
        <Box className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            loading={isLoading}
            onClick={onSaveCropHandler}
          >
            Guardar
          </Button>
          <Button variant="contained" onClick={closeHandler}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
};

export default Cropper;
