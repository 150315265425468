import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.secondary.main,
  },
}));
