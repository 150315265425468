import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Box, Grid } from '@material-ui/core';

import { WellnubTextInput } from '@wellnub/web-common';

import { addMedicalProfileHormonalContraceptive } from 'redux/slices/forms.slice';

import Button from '../../Button/Button.component';

const AddHormonalContraceptiveForm = () => {
  const { control, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    const contaceptive = getValues('hormonalContraceptive');
    dispatch(addMedicalProfileHormonalContraceptive(contaceptive));
    setTimeout(() => {
      setValue('hormonalContraceptive.type', '');
      setValue('hormonalContraceptive.dose', '');
      setValue('hormonalContraceptive.note', '');
    }, 100);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            name="hormonalContraceptive.type"
            variant="outlined"
            label="Tipo"
            control={control}
            helperText="Nombre"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WellnubTextInput
            fullWidth
            variant="outlined"
            name="hormonalContraceptive.dose"
            label="Dosis"
            control={control}
            helperText="Dosis"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            name="hormonalContraceptive.note"
            label="Detalles"
            control={control}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button variant="outlined" color="primary" onClick={onSubmitHandler}>
          Agregar
        </Button>
      </Box>
    </>
  );
};

export default AddHormonalContraceptiveForm;
