import { Avatar, Box, Chip, Grid, Link, Typography } from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';

import { useStyles } from './TestimonialCard.styles';

const TestimonialCard = ({ testimonial }) => {
  const classes = useStyles();

  return (
    <Box component="article" className={classes.card}>
      <Avatar
        alt={testimonial.name}
        src={testimonial.photo}
        className={classes.photo}
      />
      <Typography variant="h6" className={classes.name}>
        {testimonial.name}
      </Typography>
      <Typography variant="body2">{testimonial.title}</Typography>
      <Grid container spacing={2} className={classes.socialMediaWrapper}>
        {testimonial.socialMedia.map(item => (
          <Grid item key={`${testimonial.name}-${item.name}`}>
            <Link href={item.url} target="_blank" rel="noopener">
              {
                {
                  instagram: <InstagramIcon />,
                  facebook: <FacebookIcon />,
                  website: <LanguageIcon />,
                }[item.name]
              }
            </Link>
          </Grid>
        ))}
      </Grid>
      <Typography variant="body2" className={classes.description}>
        "{testimonial.description}"
      </Typography>
      <Chip label={testimonial.location} color="primary.light" />
    </Box>
  );
};

export default TestimonialCard;
