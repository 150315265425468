import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inbodyBundles: [],
};

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setInbodyBundles: (state, action) => {
      state.inbodyBundles = action.payload;
    },
  },
});

export const { setInbodyBundles } = marketSlice.actions;
export const MarketReducer = marketSlice.reducer;
