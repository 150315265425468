import { Grid } from '@material-ui/core';

import PatientCard from '../../Cards/Patient/PatientCard.component';

const PatientList = ({ patients }) => (
  <Grid container component="ul" spacing={3}>
    {patients.map(patient => (
      <Grid item component="li" md={2} sm={4} xs={12} key={patient._id}>
        <PatientCard patient={patient} />
      </Grid>
    ))}
  </Grid>
);

export default PatientList;
