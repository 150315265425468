import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'active',
  id: '',
  isFirstSignIn: false,
  isSubscribed: false,
  availablePasses: 0,
  name: '',
  profile: {},
  isProfileComplete: false,
  nutritionistTypes: [],
  nutritionistDiets: [],
  nutritionistSpecialties: [],
  nutritionistServices: [],
  nutritionistOffices: [],
  nutritionistOfficesSchedule: [],
  nutritionistOfficeDaysOff: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserStatus: (state, action) => {
      state.status = action.payload;
    },
    setUserId: (state, action) => {
      state.id = action.payload;
    },
    setFistSignIn: (state, action) => {
      state.isFirstSignIn = action.payload;
    },
    setIsSubscribed: (state, action) => {
      state.isSubscribed = action.payload;
    },
    setAvailablePasses: (state, action) => {
      state.availablePasses = action.payload;
    },
    setUserName: (state, action) => {
      state.name = action.payload;
    },
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    setUserIsProfileComplete: (state, action) => {
      state.isProfileComplete = action.payload;
    },
    setNutritionistTypes: (state, action) => {
      state.nutritionistTypes = action.payload;
    },
    setNutritionistDiets: (state, action) => {
      state.nutritionistDiets = action.payload;
    },
    setNutritionistSpecialties: (state, action) => {
      state.nutritionistSpecialties = action.payload;
    },
    setNutritionistServices: (state, action) => {
      state.nutritionistServices = action.payload;
    },
    setNutritionistOffices: (state, action) => {
      state.nutritionistOffices = action.payload;
    },
    setNutritionistOfficesSchedule: (state, action) => {
      state.nutritionistOfficesSchedule = action.payload;
    },
    setNutritionistOfficeDaysOff: (state, action) => {
      state.nutritionistOfficeDaysOff = action.payload;
    },
  },
});

export const {
  setUserStatus,
  setUserId,
  setFistSignIn,
  setIsSubscribed,
  setUserName,
  setAvailablePasses,
  setUserProfile,
  setUserIsProfileComplete,
  setNutritionistTypes,
  setNutritionistDiets,
  setNutritionistSpecialties,
  setNutritionistServices,
  setNutritionistOffices,
  setNutritionistOfficesSchedule,
  setNutritionistOfficeDaysOff,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
