import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useAnalyticsEventTracker } from 'hooks/analytics.hook';

import { Box, Grid, Typography } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import {
  WellnubTable,
  WellnubCheckboxInput,
  WellnubTextInput,
} from '@wellnub/web-common';

import { useUpdate } from 'hooks/patient.hook';

import {
  deleteMedicalProfileCondition,
  deleteMedicalProfileMedication,
  deleteMedicalProfileSurgery,
  deleteMedicalProfileContraceptive,
  deleteMedicalProfileHormonalContraceptive,
} from 'redux/slices/forms.slice';

import Button from '../../../Button/Button.component';
import AddMedicalConditionForm from '../../AddMedicalCondition/AddMedicalConditionForm.component';
import AddMedicationForm from '../../AddMedication/AddMedicationForm.component';
import AddSurgeryForm from '../../AddSurgery/AddSurgeryForm.component';
import AddContraceptiveForm from '../../AddContraceptive/AddContraceptiveForm.component';
import AddHormonalContraceptiveForm from '../../AddHormonalContraceptive/AddHormonalContraceptiveForm.component';

import { useStyles } from './MedicalHistoryMedicalProfileForm.styles';

const CONDITIONS_TABLE_HEADERS = [
  'Acciones',
  'Condición',
  'Paciente/Antecedente familiar',
  'Nota',
];

const MEDICATIONS_TABLE_HEADERS = [
  'Acciones',
  'Nombre',
  'Dosis',
  'Tiempo con el medicamento',
  'Nota',
];

const SURGERIES_TABLE_HEADERS = [
  'Acciones',
  'Tipo',
  'Fecha o tiempo',
  'Detalles',
];

const CONTRACEPTIVES_TABLE_HEADERS = [
  'Acciones',
  'Nombre',
  'Dosis',
  'Detalles',
];

const MedicalHistoryMedicalProfileForm = ({ editable }) => {
  const [isToggleConditionActive, setIsToggleConditionActive] = useState(false);
  const [isToggleMedicationActive, setIsToggleMedicationActive] =
    useState(false);
  const [isToggleSurgeryActive, setIsToggleSurgeryActive] = useState(false);
  const [isToggleContraceptiveActive, setIsToggleContraceptiveActive] =
    useState(false);
  const [
    isToggleHormonalContraceptiveActive,
    setIsToggleHormonalContraceptiveActive,
  ] = useState(false);

  const {
    conditions,
    medications,
    surgeries,
    contraceptives,
    hormonalContraceptives,
  } = useSelector(state => state.forms.medicalHistory.medicalProfile);

  const { medical, general } = useSelector(state => state.patient.profile);

  const { id } = useParams();

  const { handleSubmit, control, watch, ...restMethods } = useForm({
    mode: 'onChange',
    defaultValues: {
      surgery: {
        event: '',
        timeSince: '',
        note: '',
      },
      condition: {
        name: '',
        affected: '',
        note: '',
      },
      medication: {
        name: '',
        dose: '',
        timeUsing: '',
        note: '',
      },
      contaceptive: {
        type: '',
        dose: '',
        note: '',
      },
      hormonalContraceptive: {
        type: '',
        dose: '',
        note: '',
      },
      conditions: {
        hasCondition: medical?.conditions?.hasCondition ?? false,
      },
      medications: {
        isTakingMedication: medical?.medications?.isTakingMedication ?? false,
      },
      surgeries: {
        didHaveSurgeries: medical?.surgeries?.didHaveSurgeries ?? false,
      },
      gynecology: {
        lastMenstruation: medical?.gynecology?.lastMenstruation ?? '',
        durationOfMenstruation:
          medical?.gynecology?.durationOfMenstruation ?? '',
        pregnancies: medical?.gynecology?.pregnancies ?? '',
        childbirths: medical?.gynecology?.childbirths ?? '',
        isPatientPregnant: medical?.gynecology?.isPatientPregnant ?? false,
        pregnantTime: medical?.gynecology?.pregnantTime ?? '',
        isUsingContraceptive:
          medical?.gynecology?.isUsingContraceptive ?? false,
        hasClimacteric: medical?.gynecology?.hasClimacteric ?? false,
        startDateOfClimacteric:
          medical?.gynecology?.startDateOfClimacteric ?? '',
        hasHormonalTherapy: medical?.gynecology?.hasHormonalTherapy ?? false,
      },
      aditionalNotes: medical?.aditionalNotes ?? '',
    },
  });

  const { mutate, isLoading } = useUpdate();

  const { eventTracker } = useAnalyticsEventTracker('nutritionist-action');

  const {
    conditions: { hasCondition },
    medications: { isTakingMedication },
    surgeries: { didHaveSurgeries },
    gynecology: {
      isPatientPregnant,
      isUsingContraceptive,
      hasClimacteric,
      hasHormonalTherapy,
    },
  } = watch();

  const onSubmitHandler = data => {
    eventTracker('edit-user-medical-profile', 'medical-profile-edited');
    const payload = {
      medicalProfile: {
        conditions: {
          ...data.conditions,
          conditions: conditions.map(({ data }) => ({
            name: data.name,
            affected: {
              'Presente en paciente': 'patient',
              'Antecedente familiar': 'family',
            }[data.affected],
            note: data.note,
          })),
        },
        medications: {
          ...data.medications,
          medicationList: medications.map(({ data }) => ({
            ...data,
          })),
        },
        surgeries: {
          ...data.surgeries,
          surgeriesList: surgeries.map(({ data }) => ({
            ...data,
          })),
        },
        gynecology: {
          ...data.gynecology,
          oralContraceptives: contraceptives.map(({ data }) => ({ ...data })),
          hormonalContraceptives: hormonalContraceptives.map(({ data }) => ({
            ...data,
          })),
        },
        aditionalNotes: data.aditionalNotes,
      },
    };
    mutate({
      id,
      data: payload,
    });
  };

  const classes = useStyles();

  const dispatch = useDispatch();

  const TABLE_CONDITIONS_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteMedicalProfileCondition(id));
      },
    },
  ];

  const TABLE_MEDICATIONS_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteMedicalProfileMedication(id));
      },
    },
  ];

  const TABLE_SURGERIES_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteMedicalProfileSurgery(id));
      },
    },
  ];

  const TABLE_CONTRACEPTIVES_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteMedicalProfileContraceptive(id));
      },
    },
  ];

  const TABLE_HORMONAL_CONTRACEPTIVES_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteMedicalProfileHormonalContraceptive(id));
      },
    },
  ];

  const onToggleConditionHandler = () => {
    setIsToggleConditionActive(prevState => !prevState);
  };

  const onToggleMedicationHandler = () => {
    setIsToggleMedicationActive(prevState => !prevState);
  };

  const onToggleSurgeryHandler = () => {
    setIsToggleSurgeryActive(prevState => !prevState);
  };

  const onToggleContraceptiveHandler = () => {
    setIsToggleContraceptiveActive(prevState => !prevState);
  };

  const onToggleHormonalContraceptiveHandler = () => {
    setIsToggleHormonalContraceptiveActive(prevState => !prevState);
  };

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      control={control}
      watch={watch}
      {...restMethods}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Box component="section">
          <Typography variant="h5" className={classes.sectionTitle}>
            Condiciones médicas del paciente y antecedentes familiares
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12}>
              <WellnubCheckboxInput
                fullWidth
                disabled={!editable}
                name="conditions.hasCondition"
                label="¿El paciente presenta o tiene antecedentes familiares de algún tipo  de condición o enfermedad?"
                control={control}
                defaultChecked={medical?.conditions?.hasCondition ?? false}
              />
            </Grid>
          </Grid>
          {hasCondition && (
            <Box mt={4}>
              <WellnubTable
                headers={CONDITIONS_TABLE_HEADERS}
                rows={conditions}
                actions={TABLE_CONDITIONS_ACTIONS}
                disabled={!editable}
              />
              <Box mt={2}>
                <Button
                  disabled={!editable}
                  variant="outlined"
                  color="primary"
                  startIcon={
                    isToggleConditionActive ? <RemoveIcon /> : <AddIcon />
                  }
                  onClick={onToggleConditionHandler}
                >
                  Agregar nueva condición
                </Button>
              </Box>
              {isToggleConditionActive && (
                <Box mt={2}>
                  <AddMedicalConditionForm />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Medicamentos
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12}>
              <WellnubCheckboxInput
                fullWidth
                disabled={!editable}
                name="medications.isTakingMedication"
                label="¿El paciente lleva algún tipo de medicamento?"
                control={control}
                defaultChecked={
                  medical?.medications?.isTakingMedication ?? false
                }
              />
            </Grid>
          </Grid>
          {isTakingMedication && (
            <Box mt={4}>
              <WellnubTable
                headers={MEDICATIONS_TABLE_HEADERS}
                rows={medications}
                actions={TABLE_MEDICATIONS_ACTIONS}
                disabled={!editable}
              />
              <Box mt={2}>
                <Button
                  disabled={!editable}
                  variant="outlined"
                  color="primary"
                  startIcon={
                    isToggleMedicationActive ? <RemoveIcon /> : <AddIcon />
                  }
                  onClick={onToggleMedicationHandler}
                >
                  Agregar nuevo medicamento
                </Button>
              </Box>
              {isToggleMedicationActive && (
                <Box mt={2}>
                  <AddMedicationForm />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Fracturas, dolores, hospitalizaciones y cirugías
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12}>
              <WellnubCheckboxInput
                fullWidth
                disabled={!editable}
                name="surgeries.didHaveSurgeries"
                label="¿El paciente ha sufrido algún tipo de fractura, hospitalización, cirugía o dolores recientes?"
                control={control}
                defaultChecked={medical?.surgeries?.didHaveSurgeries ?? false}
              />
            </Grid>
          </Grid>
          {didHaveSurgeries && (
            <Box mt={4}>
              <WellnubTable
                headers={SURGERIES_TABLE_HEADERS}
                rows={surgeries}
                actions={TABLE_SURGERIES_ACTIONS}
                disabled={!editable}
              />
              <Box mt={2}>
                <Button
                  disabled={!editable}
                  variant="outlined"
                  color="primary"
                  startIcon={
                    isToggleSurgeryActive ? <RemoveIcon /> : <AddIcon />
                  }
                  onClick={onToggleSurgeryHandler}
                >
                  Agregar nueva intervención
                </Button>
              </Box>
              {isToggleSurgeryActive && (
                <Box mt={2}>
                  <AddSurgeryForm />
                </Box>
              )}
            </Box>
          )}
        </Box>
        {general.gender === 'female' ? (
          <Box component="section" mt={4}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Aspectos ginecológicos
            </Typography>
            <Grid container spacing={4} className={classes.grid}>
              <Grid item xs={12} sm={6}>
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.lastMenstruation"
                  label="FUM"
                  variant="outlined"
                  control={control}
                  helperText="Fecha última menstruación (FUM)"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.durationOfMenstruation"
                  label="Duración promedio de menstruación"
                  variant="outlined"
                  control={control}
                  helperText="Duración en días de la menstruación"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.pregnancies"
                  label="# de gestas"
                  variant="outlined"
                  control={control}
                  helperText="# de embarazos"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.childbirths"
                  label="# de partos"
                  variant="outlined"
                  control={control}
                  helperText="# de partos"
                />
              </Grid>
              <Grid item xs={12}>
                <WellnubCheckboxInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.isPatientPregnant"
                  label="¿La paciente está embarazada actualmente?"
                  control={control}
                  defaultChecked={
                    medical?.gynecology?.isPatientPregnant ?? false
                  }
                />
              </Grid>
              {isPatientPregnant && (
                <Grid item xs={12} sm={6}>
                  <WellnubTextInput
                    fullWidth
                    disabled={!editable}
                    name="gynecology.pregnantTime"
                    label="# de semanas"
                    variant="outlined"
                    control={control}
                    helperText="# de semanas de gestación"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <WellnubCheckboxInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.isUsingContraceptive"
                  label="¿La paciente toma anticonceptivos orales?"
                  control={control}
                  defaultChecked={
                    medical?.gynecology?.isUsingContraceptive ?? false
                  }
                />
              </Grid>
            </Grid>
            {isUsingContraceptive && (
              <Box mt={4}>
                <WellnubTable
                  headers={CONTRACEPTIVES_TABLE_HEADERS}
                  rows={contraceptives}
                  actions={TABLE_CONTRACEPTIVES_ACTIONS}
                  disabled={!editable}
                />
                <Box mt={2}>
                  <Button
                    disabled={!editable}
                    variant="outlined"
                    color="primary"
                    startIcon={
                      isToggleContraceptiveActive ? <RemoveIcon /> : <AddIcon />
                    }
                    onClick={onToggleContraceptiveHandler}
                  >
                    Agregar nuevo anticonceptivo
                  </Button>
                </Box>
                {isToggleContraceptiveActive && (
                  <Box mt={2}>
                    <AddContraceptiveForm />
                  </Box>
                )}
              </Box>
            )}
            <Grid container spacing={4} className={classes.grid}>
              <Grid item xs={12}>
                <WellnubCheckboxInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.hasClimacteric"
                  label="¿La paciente presenta climaterio?"
                  control={control}
                  defaultChecked={medical?.gynecology?.hasClimacteric ?? false}
                />
              </Grid>
              {hasClimacteric && (
                <Grid item xs={12} sm={6}>
                  <WellnubTextInput
                    fullWidth
                    disabled={!editable}
                    name="gynecology.startDateOfClimacteric"
                    label="Fecha"
                    variant="outlined"
                    control={control}
                    helperText="Fecha de inicio de climaterio"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <WellnubCheckboxInput
                  fullWidth
                  disabled={!editable}
                  name="gynecology.hasHormonalTherapy"
                  label="¿La paciente lleva terapia de reemplazo hormonal?"
                  control={control}
                  defaultChecked={
                    medical?.gynecology?.hasHormonalTherapy ?? false
                  }
                />
              </Grid>
            </Grid>
            {hasHormonalTherapy && (
              <Box mt={4}>
                <WellnubTable
                  headers={CONTRACEPTIVES_TABLE_HEADERS}
                  rows={hormonalContraceptives}
                  actions={TABLE_HORMONAL_CONTRACEPTIVES_ACTIONS}
                  disabled={!editable}
                />
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    disabled={!editable}
                    color="primary"
                    startIcon={
                      isToggleHormonalContraceptiveActive ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    onClick={onToggleHormonalContraceptiveHandler}
                  >
                    Agregar nuevo anticonceptivo
                  </Button>
                </Box>
                {isToggleHormonalContraceptiveActive && (
                  <Box mt={2}>
                    <AddHormonalContraceptiveForm />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box component="section" mt={4}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Aspectos ginecológicos
            </Typography>
            <Box mt={4}>
              <Typography variant="h6">
                No aplica, paciente masculino
              </Typography>
            </Box>
          </Box>
        )}
        <Box component="section" mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <WellnubTextInput
                fullWidth
                multiline
                rows={2}
                disabled={!editable}
                name="aditionalNotes"
                variant="outlined"
                label="Notas adicionales al perfil médico del paciente"
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
        {editable && (
          <Box component="section" textAlign="right" mt={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              loading={isLoading}
            >
              Guardar
            </Button>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default MedicalHistoryMedicalProfileForm;
