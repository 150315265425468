import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {
  WellnubTextInput,
  WellnubSelectInput,
  WellnubCheckboxGroupInput,
} from '@wellnub/web-common';

import { nutritionistSpecialtiesDataSelector } from 'redux/slices/user.selector';
import { usePatchProfile } from 'hooks/nutritionist.hook';

import { FormSchema } from './SpecialtiesSettingsForm.schema';

import { useStyles } from './SpecialtiesSettingsForm.styles';
import { nanoid } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

const GRADUATION_YEAR_OPTIONS = [
  { id: 'grd-1', value: 1980, label: '1980' },
  { id: 'grd-2', value: 1981, label: '1981' },
  { id: 'grd-3', value: 1982, label: '1982' },
  { id: 'grd-4', value: 1983, label: '1983' },
  { id: 'grd-5', value: 1984, label: '1984' },
  { id: 'grd-6', value: 1985, label: '1985' },
  { id: 'grd-7', value: 1986, label: '1986' },
  { id: 'grd-8', value: 1987, label: '1987' },
  { id: 'grd-9', value: 1988, label: '1988' },
  { id: 'grd-10', value: 1989, label: '1989' },
  { id: 'grd-11', value: 1990, label: '1990' },
  { id: 'grd-12', value: 1991, label: '1991' },
  { id: 'grd-13', value: 1992, label: '1992' },
  { id: 'grd-14', value: 1993, label: '1993' },
  { id: 'grd-15', value: 1994, label: '1994' },
  { id: 'grd-16', value: 1995, label: '1995' },
  { id: 'grd-17', value: 1996, label: '1996' },
  { id: 'grd-18', value: 1997, label: '1997' },
  { id: 'grd-19', value: 1998, label: '1998' },
  { id: 'grd-20', value: 1999, label: '1999' },
  { id: 'grd-21', value: 2000, label: '2000' },
  { id: 'grd-22', value: 2001, label: '2001' },
  { id: 'grd-23', value: 2002, label: '2002' },
  { id: 'grd-24', value: 2003, label: '2003' },
  { id: 'grd-25', value: 2004, label: '2004' },
  { id: 'grd-26', value: 2005, label: '2005' },
  { id: 'grd-27', value: 2006, label: '2006' },
  { id: 'grd-28', value: 2007, label: '2007' },
  { id: 'grd-29', value: 2008, label: '2008' },
  { id: 'grd-30', value: 2009, label: '2009' },
  { id: 'grd-31', value: 2010, label: '2010' },
  { id: 'grd-32', value: 2011, label: '2011' },
  { id: 'grd-33', value: 2012, label: '2012' },
  { id: 'grd-34', value: 2013, label: '2013' },
  { id: 'grd-35', value: 2014, label: '2014' },
  { id: 'grd-36', value: 2015, label: '2015' },
  { id: 'grd-37', value: 2016, label: '2016' },
  { id: 'grd-38', value: 2017, label: '2017' },
  { id: 'grd-39', value: 2018, label: '2018' },
  { id: 'grd-40', value: 2019, label: '2019' },
  { id: 'grd-41', value: 2020, label: '2020' },
  { id: 'grd-42', value: 2021, label: '2021' },
  { id: 'grd-43', value: 2022, label: '2022' },
  { id: 'grd-44', value: 2023, label: '2023' },
  { id: 'grd-45', value: 2024, label: '2024' },
];

const SpecialtiesSettingsForm = () => {
  const [expertise, setExpertise] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [nutritionistTypes, setNutritionistTypes] = useState([]);
  const [nutritionistDiets, setNutritionistDiets] = useState([]);
  const [nutritionistSpecialties, setNutritionistSpecialties] = useState({
    lifeStyle: [],
    medical: [],
  });
  const [isExpertiseVisible, setIsExpertiseVisible] = useState(false);
  const [isCertificationsVisible, setIsCertificationsVisible] = useState(false);

  const { isProfileComplete } = useSelector(state => state.user);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { push, block } = useHistory();

  const { mutateAsync: updateProfile, isLoading } = usePatchProfile();

  const nutritionist = useSelector(nutritionistSpecialtiesDataSelector);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      mainDegree: nutritionist.mainDegree,
      professionalLicense: nutritionist.professionalLicense,
      expertise: {
        title: '',
        school: '',
        graduationYear: 1980,
      },
      certification: {
        title: '',
        school: '',
      },
      types: [...nutritionist.activeTypes],
      specialities: [...nutritionist.activeSpecialties],
      diets: [...nutritionist.activeDiets],
    },
  });

  const expertiseWatcher = watch('expertise');
  const certificationWatcher = watch('certification');

  const isAddExpertiseDisabled = Object.values(expertiseWatcher).some(value =>
    isEmpty(value.toString()),
  );

  const isAddCertificationDisabled =
    Object.values(certificationWatcher).some(isEmpty);

  const classes = useStyles();

  const onSubmitHandler = handleSubmit(data => {
    const { expertise: _, certification, ...restData } = data;
    const payload = {
      nutritionistProfile: {
        expertise: expertise.map(({ _id, ...rest }) => ({ ...rest })),
        certifications: certifications.map(({ _id, ...rest }) => ({ ...rest })),
        ...restData,
      },
    };
    updateProfile(payload).then(() => {
      if (!isProfileComplete) {
        setTimeout(() => {
          push('/settings/profile/services');
        }, 1000);
      }
      enqueueSnackbar('Tu información ha sido actualizada con éxito', {
        variant: 'success',
      });
      queryClient.invalidateQueries('GET_DETAILS');
    });
  });

  const onAddExpertiseHandler = () => {
    const expertise = getValues('expertise');
    setExpertise(prevState => [
      ...prevState,
      {
        _id: `${expertise.title}-${expertise.school}`,
        graduationYear: expertise.graduationYear.toString(),
        ...expertise,
      },
    ]);
    return setValue('expertise', {
      title: '',
      school: '',
      graduationYear: 1980,
    });
  };

  const onAddCertificationHandler = () => {
    const certification = getValues('certification');
    setCertifications(prevState => [
      ...prevState,
      {
        _id: `${certification.title}-${certification.school}`,
        ...certification,
      },
    ]);
    return setValue('certification', {
      title: '',
      school: '',
    });
  };

  const onDeleteExpertiseHandler = item => () => {
    const items = expertise.filter(element => element._id !== item._id);
    setExpertise(items);
  };

  const onDeleteCertificationHandler = item => () => {
    const items = certifications.filter(element => element._id !== item._id);
    setCertifications(items);
  };

  useEffect(() => {
    const types = nutritionist.types.map(type => ({
      id: type._id,
      label: type.type,
      value: type._id,
      checked: nutritionist.activeTypes.includes(type._id),
      disabled: false,
    }));
    const diets = nutritionist.diets.map(diet => ({
      id: diet._id,
      label: diet.name,
      value: diet._id,
      checked: nutritionist.activeDiets.includes(diet._id),
    }));
    const lifeStyle = nutritionist.specialties
      .filter(item => item.type === 'food')
      .map(spec => ({
        id: spec._id,
        label: spec.name,
        checked: nutritionist.activeSpecialties.includes(spec._id),
        value: spec._id,
      }));
    const medical = nutritionist.specialties
      .filter(item => item.type === 'specialConditions')
      .map(spec => ({
        id: spec._id,
        label: spec.name,
        checked: nutritionist.activeSpecialties.includes(spec._id),
        value: spec._id,
      }));
    setNutritionistDiets(diets);
    setNutritionistTypes(types);
    setExpertise(prevState => [...prevState, ...nutritionist.expertise]);
    setCertifications(prevState => [
      ...prevState,
      ...nutritionist.certifications,
    ]);
    setNutritionistSpecialties({
      lifeStyle,
      medical,
    });
  }, [nutritionist]);

  useEffect(() => {
    const unblockNavigation = block(() => {
      if (!isValid && !isProfileComplete) {
        window.alert(
          'Hay ciertos datos obligatorios que necesitamos en Wellnub para tu mejor experiencia y la de los pacientes, por favor revisa tu perfil.',
        );
        return false;
      }
      return true;
    });

    return () => {
      unblockNavigation();
    };
  }, [isValid, block, isProfileComplete]);

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container spacing={4} style={{ marginBottom: 16 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Cédula profesional *
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ paddingBottom: 16 }}
          >
            Te pedimos que nos compartas tu cédula profesional al ser una
            plataforma exclusiva para nutriólogos. Si por algún motivo aún no
            cuentas con ella, escribe “en trámite”.
          </Typography>
          <WellnubTextInput
            fullWidth
            name="professionalLicense"
            control={control}
            helperText="Escribe tu cédula profesional"
            label="Cédula profesional *"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Título profesional principal *
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ paddingBottom: 16 }}
          >
            Elige tu título profesional público en Wellnub (lic.en nutrición,
            nutriólogo(a), educador(a) en diabetes…)
          </Typography>
          <WellnubTextInput
            fullWidth
            name="mainDegree"
            control={control}
            label="Título (Lic. en nutrición, Nutriólogo, Educador) *"
            helperText="Escribe tu título profesional"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            Educación profesional (en orden de relevancia) *
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ paddingBottom: 16 }}
          >
            Llena los siguientes 3 campos y haz clic en el botón agregar para
            guardar cada uno de tus títulos profesionales. Debes ingresar al
            menos un título, puedes ingresar tantos como desees.
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.tableHeader}>
                    Titulo
                  </TableCell>
                  <TableCell align="center" className={classes.tableHeader}>
                    Institución/Escuela
                  </TableCell>
                  <TableCell align="center" className={classes.tableHeader}>
                    Año de graduación
                  </TableCell>
                  <TableCell align="center" className={classes.tableHeader}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expertise.map(item => (
                  <TableRow key={nanoid()} className={classes.tableRow}>
                    <TableCell align="center">{item?.title}</TableCell>
                    <TableCell align="center">{item?.school}</TableCell>
                    <TableCell align="center">{item?.graduationYear}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={onDeleteExpertiseHandler(item)}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<AddIcon fontSize="small" />}
              onClick={() => setIsExpertiseVisible(prevState => !prevState)}
            >
              Agregar título
            </Button>
          </Box>
          {isExpertiseVisible && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <WellnubTextInput
                    fullWidth
                    name="expertise.title"
                    control={control}
                    label="Título"
                  />
                </Grid>
                <Grid item xs={4}>
                  <WellnubTextInput
                    fullWidth
                    name="expertise.school"
                    control={control}
                    label="Institución / Escuela"
                  />
                </Grid>
                <Grid item xs={4}>
                  <WellnubSelectInput
                    fullWidth
                    name="expertise.graduationYear"
                    control={control}
                    label="Año"
                    options={GRADUATION_YEAR_OPTIONS}
                  />
                </Grid>
              </Grid>
              <Box mt={2} textAlign="right">
                <Button
                  disabled={isAddExpertiseDisabled}
                  variant="outlined"
                  color="primary"
                  onClick={onAddExpertiseHandler}
                >
                  Agregar
                </Button>
              </Box>
            </>
          )}
          {isEmpty(expertise) && (
            <Typography style={{ color: 'red', fontSize: 13, paddingTop: 8 }}>
              Debes de ingresar al menos un titulo universitario.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            Otras certificaciones y cursos (en orden de relevancia)
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ paddingBottom: 16 }}
          >
            Ingresa los cursos y certificaciones que complementan tu formación
            como nutriólogo(a). Llena los siguientes 2 campos y haz clic en el
            botón agregar para guardar cada certificación. Puedes ingresar todas
            las certificaciones que desees.
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.tableHeader}>
                    Certificación
                  </TableCell>
                  <TableCell align="center" className={classes.tableHeader}>
                    Institución/Escuela
                  </TableCell>
                  <TableCell align="center" className={classes.tableHeader}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {certifications.map(item => (
                  <TableRow key={nanoid()} className={classes.tableRow}>
                    <TableCell align="center">{item?.title}</TableCell>
                    <TableCell align="center">{item?.school}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={onDeleteCertificationHandler(item)}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<AddIcon fontSize="small" />}
              onClick={() =>
                setIsCertificationsVisible(prevState => !prevState)
              }
            >
              Agregar certificación
            </Button>
          </Box>
          {isCertificationsVisible && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <WellnubTextInput
                    fullWidth
                    name="certification.title"
                    control={control}
                    label="Titulo"
                  />
                </Grid>
                <Grid item xs={6}>
                  <WellnubTextInput
                    fullWidth
                    name="certification.school"
                    control={control}
                    label="Institución / Escuela"
                  />
                </Grid>
              </Grid>
              <Box mt={2} textAlign="right">
                <Button
                  disabled={isAddCertificationDisabled}
                  variant="outlined"
                  color="primary"
                  onClick={onAddCertificationHandler}
                >
                  Agregar
                </Button>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            Tipo de nutriólogo *
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ paddingBottom: 16 }}
          >
            ¿Eres nutriólogo general o cuentas con alguna especialidad? Por
            favor indícalo a tus pacientes en esta sección. Debes de seleccionar
            al menos una opción.
          </Typography>
          <WellnubCheckboxGroupInput
            row
            name="types"
            control={control}
            options={nutritionistTypes}
          />
        </Grid>

        {/* ---- ESPECIALIDADES ---- */}

        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            Especialidades (selecciona máximo 5 especialidades en total) *
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ paddingBottom: 16 }}
          >
            ¿Cuál es la especialidad o enfoque de tu consulta? ¿Por estilo de
            vida y/o especialidades clínicas (patologías)? Selecciona{' '}
            <b>al menos 1</b> de las siguientes opciones y <b>máximo 5</b>{' '}
            (tomando en cuanta ambas sub categorías).
          </Typography>
          <Box pt={1}>
            <Typography variant="body2">
              <b>Especialidades por estilo de vida</b>
            </Typography>
            <WellnubCheckboxGroupInput
              grid
              name="specialities"
              control={control}
              options={nutritionistSpecialties.lifeStyle}
            />
          </Box>
          <Box mt={4}>
            <Typography variant="body2">
              <b>Especialidades por patologías</b>
            </Typography>
            <WellnubCheckboxGroupInput
              grid
              name="specialities"
              control={control}
              options={nutritionistSpecialties.medical}
            />
          </Box>
        </Grid>

        {/* ---- DIETAS ---- */}

        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            Tipo de dieta *
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ paddingBottom: 16 }}
          >
            Señala si te especializas o prefieres trabajar con alguno(s) de los
            siguientes tipos de dieta. Debes de seleccionar al menos una opción.
          </Typography>
          <WellnubCheckboxGroupInput
            grid
            name="diets"
            control={control}
            options={nutritionistDiets}
          />
        </Grid>
      </Grid>
      <Box py={1} textAlign="right">
        <Typography variant="h6">* Estos campos son obligatorios</Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isLoading}
      >
        {!isProfileComplete ? 'Guardar y continuar' : 'Actualizar'}
      </Button>
    </form>
  );
};

export default SpecialtiesSettingsForm;
