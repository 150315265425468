import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  patients: [],
};

const affectedPatientsSlice = createSlice({
  name: 'affectedPatients',
  initialState,
  reducers: {
    setAffectedPatients: (state, action) => {
      state.patients = action.payload;
    },
  },
});

export const { setAffectedPatients } = affectedPatientsSlice.actions;
export const AffectedPatientsReducer = affectedPatientsSlice.reducer;
