import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import Button from '../../../Button/Button.component';

import { useGetGeneralInstructions, useLinkPatient } from 'hooks/patient.hook';

import { setPatientDataForRegister } from 'redux/slices/patient.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

import { useStyles } from './PatientCreationForm.styles';
import { Alert } from '@material-ui/lab';
import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { PatientCreationFormSchema } from './PatientCreationForm.schema';

const PatientCreationForm = () => {
  const [isAcceptedToAddPass, setIsAcceptedToAddPass] = useState('decline');
  const [wellnubTicketID, setWellnubTicketID] = useState('');

  const { data, isAlreadyRegistered, placeholder, isAddedFromVirtualAgenda } =
    useSelector(state => state.patient);

  const { availablePasses } = useSelector(state => state.user);

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(PatientCreationFormSchema),
    defaultValues: {
      name: data?.name ?? placeholder?.dataForRegister?.name,
      lastName: data?.lastName ?? placeholder?.dataForRegister?.lastName,
      countryCode: data?.countryCode ?? placeholder.countryCode,
      email:
        data.email || placeholder.email || placeholder.dataForRegister.email,
      phoneNumber:
        data.phoneNumber ||
        placeholder.phoneNumber ||
        placeholder.dataForRegister.phoneNumber,
      isDownloadable: placeholder?.dataForRegister?.isDownloadable,
    },
  });

  const { mutateAsync: link, isLoading: isLinking } = useLinkPatient();

  const { refetch } = useGetGeneralInstructions(wellnubTicketID);

  const { push } = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();

  const onSubmitHandler = fieldsData => {
    const { _id, isDownloadable, ...patient } = fieldsData;
    if (isAlreadyRegistered) {
      const payload = {
        patient: data._id,
        publicField: placeholder.publicField,
      };
      link({
        addedFromCalendar: isAddedFromVirtualAgenda,
        shouldAddPass: isAcceptedToAddPass === 'accept',
        data: payload,
      }).then(response => {
        setWellnubTicketID(response._id);
        if (isDownloadable) {
          setTimeout(() => {
            enqueueSnackbar(
              'En un momento comenzará la descarga de las instrucciones para tu paciente',
              {
                variant: 'info',
              },
            );
            refetch()
              .then(({ data }) => fetch(`data:image/jpeg;base64,${data}`))
              .then(response => response.blob())
              .then(file => {
                enqueueSnackbar('Descarga exitosa', { variant: 'success' });
                saveAs(file, 'wellnub-instructions.jpg');
              })
              .catch(() => {
                enqueueSnackbar(
                  'No se pudo descargar las instrucciones. Intenta más tarde',
                  {
                    variant: 'warning',
                  },
                );
              });
          }, 1000);
        }
      });
    } else {
      batch(() => {
        dispatch(
          setPatientDataForRegister({
            ...patient,
            withPass: isAcceptedToAddPass,
            isDownloadable: fieldsData.isDownloadable,
          }),
        );
        dispatch(
          setModalStatus({
            name: 'ADD_PATIENT_CONFIRMATION',
            isActive: true,
          }),
        );
      });
    }
  };

  const onChangeAccepted = event => {
    setIsAcceptedToAddPass(event.target.value);
  };

  const onRedirectHandler = () => {
    dispatch(
      setModalStatus({
        name: 'ACCEPT_LINK_REQUEST',
        isActive: false,
      }),
    );
    push('/settings/payments/passes');
  };

  useEffect(() => {
    if (availablePasses !== 0) {
      setIsAcceptedToAddPass('accept');
    }
  }, [availablePasses]);

  return (
    <>
      <Typography>
        {isAlreadyRegistered
          ? 'Estos son los datos asociados al email. ¿Deseas agregar este paciente?'
          : 'Escribe los datos para agregar un nuevo paciente'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
        <Grid container spacing={4}>
          {/* --- Nombre --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  disabled={isAlreadyRegistered}
                  variant="outlined"
                  type="text"
                  label="Nombre"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe el nombre'}
                />
              )}
            />
          </Grid>
          {/* --- Apellido --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              name="lastName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  disabled={isAlreadyRegistered}
                  variant="outlined"
                  type="text"
                  label="Apellido"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe el apellido'}
                />
              )}
            />
          </Grid>
          {/* --- Correo electronico --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              name="email"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  disabled={isAlreadyRegistered || placeholder.email}
                  variant="outlined"
                  type="email"
                  label="Email"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe el email'}
                />
              )}
            />
          </Grid>
          {/* --- Telefono --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <WellnubCountryCodeInput
                  label="Región"
                  disabled={isAlreadyRegistered || placeholder.phoneNumber}
                  control={control}
                  name="countryCode"
                  rules={{ required: 'Este campo es requerido' }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <WellnubNumberInput
                  fullWidth
                  defaultValue={
                    data.phoneNumber ||
                    placeholder.phoneNumber ||
                    placeholder.dataForRegister.phoneNumber
                  }
                  disabled={isAlreadyRegistered || placeholder.phoneNumber}
                  variant="outlined"
                  label="Teléfono"
                  name="phoneNumber"
                  control={control}
                  format="## #### ####"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={2} textAlign="center">
          <Typography>
            ¿Deseas asignar automáticamente un pase a este paciente cuando se
            vincule contigo?
          </Typography>
          <FormControl>
            <RadioGroup
              row
              value={isAcceptedToAddPass}
              onChange={onChangeAccepted}
            >
              <FormControlLabel
                value="decline"
                control={<Radio color="primary" />}
                label="No, sin pase"
              />
              <FormControlLabel
                value="accept"
                control={<Radio color="primary" />}
                label="Si, asignar pase"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mt={2}>
          {isAcceptedToAddPass === 'accept' && availablePasses === 0 && (
            <Alert severity="warning">
              Ya no cuentas con pases disponibles, para adquirir más
              presiona&nbsp;
              <span
                onClick={onRedirectHandler}
                style={{
                  color: 'currentcolor',
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                aquí
              </span>
            </Alert>
          )}
        </Box>
        <Box textAlign="center">
          <Controller
            name="isDownloadable"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                label="Descargar instrucciones de ingreso para tu paciente"
                control={
                  <Checkbox
                    checked={value}
                    color="primary"
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Box>
        <Box
          pt={2}
          width="100%"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isLinking}
            disabled={isAcceptedToAddPass === 'accept' && availablePasses === 0}
          >
            Confirmar
          </Button>
        </Box>
      </form>
    </>
  );
};

export default PatientCreationForm;
