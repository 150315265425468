import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 100,
    backgroundColor: 'rgba(11, 16, 21, .70)',
  },
  buttons: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  content: {
    maxWidth: '50%',
    height: '80%',
    overflow: 'scroll',
  },
  cropper: {
    width: '100%',
  },
}));
