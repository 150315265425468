import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch, batch } from 'react-redux';

import { Box, Grid } from '@material-ui/core';

import { Button } from 'components';

import {
  WellnubTextInput,
  WellnubNumberInput,
  WellnubCountryCodeInput,
} from '@wellnub/web-common';
import { yupResolver } from '@hookform/resolvers/yup';

import { setPatientData, setPatientMode } from 'redux/slices/patient.slice';

import { useCancelLinkRequest } from 'hooks/nutritionist.hook';
import { useUpdatePatientBasicInfo } from 'hooks/patient.hook';

import { FormSchema } from './LinkRequestByNutritionist.schema';

const LinkRequestByNutritionistForm = () => {
  const { data, mode } = useSelector(state => state.patient);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      name: data?.isMyPatientEditable ? data?.name : '',
      lastName: data?.isMyPatientEditable ? data?.lastName : '',
      phoneNumber: data?.isMyPatientEditable ? data?.phoneNumber : '',
      email: data?.isMyPatientEditable ? data?.email : '',
      countryCode: data?.isMyPatientEditable ? data?.countryCode : '52',
    },
  });

  const { mutate, isLoading } = useCancelLinkRequest();

  const { mutate: updateInfo, isLoading: isLoadingInfo } =
    useUpdatePatientBasicInfo();

  const dispatch = useDispatch();

  const onClickHandler = () => {
    mutate(data.requestId);
  };

  const onSubmitHandler = handleSubmit(fields => {
    const payload = {
      id: data._id,
      data: { ...fields },
    };
    updateInfo(payload);
  });

  // const numberFormatHandler = (value = '') => {
  //   const lastNumbers = value.slice(-4);
  //   return `** **** ${lastNumbers}`;
  // };

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(setPatientData({}));
        dispatch(setPatientMode('normal'));
      });
    };
  }, [dispatch]);

  return (
    <form style={{ marginTop: 8 }} onSubmit={onSubmitHandler}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            fullWidth
            name="name"
            label="Nombre"
            variant="outlined"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            fullWidth
            name="lastName"
            label="Apellido"
            variant="outlined"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            fullWidth
            // disabled={!data?.email?.includes('*')}
            type="email"
            label="Email"
            name="email"
            variant="outlined"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <WellnubCountryCodeInput
                fullWidth
                name="countryCode"
                label="Región"
                control={control}
              />
            </Grid>
            <Grid item xs={9}>
              <WellnubNumberInput
                fullWidth
                // disabled={!data?.phoneNumber?.includes('*')}
                defaultValue={
                  data?.isMyPatientEditable ? data?.phoneNumber : ''
                }
                format="## #### ####"
                name="phoneNumber"
                label="Teléfono"
                variant="outlined"
                control={control}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        display="flex"
        mt={2}
        alignItems="center"
        justifyContent="space-evenly"
      >
        {mode === 'editable' && (
          <Button
            variant="contained"
            type="submit"
            color="primary"
            loading={isLoadingInfo}
          >
            Actualizar
          </Button>
        )}
        <Button variant="outlined" loading={isLoading} onClick={onClickHandler}>
          Cancelar solicitud
        </Button>
      </Box>
    </form>
  );
};

export default LinkRequestByNutritionistForm;
