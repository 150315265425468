import { useSelector } from 'react-redux';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import { useStyles } from './PaymentsNavigation.styles';

const PaymentsNavigation = () => {
  const { isProfileComplete } = useSelector(state => state.user);

  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const classes = useStyles();

  const getTabValue = () => {
    if (pathname.includes('suscriptions')) return 'suscriptions';
    if (pathname.includes('passes')) return 'passes';
    if (pathname.includes('payment-methods')) return 'payment-methods';
    if (pathname.includes('billing')) return 'billing';
    return 'suscriptions';
  };

  return (
    <Tabs variant="fullWidth" value={getTabValue()} indicatorColor="primary">
      <Tab
        to={`${url}/suscriptions`}
        component={Link}
        value="suscriptions"
        label="Suscripciones"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        to={`${url}/passes`}
        component={Link}
        value="passes"
        label="Pases Inbody"
        disabled={!isProfileComplete}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        to={`${url}/payment-methods`}
        component={Link}
        value="payment-methods"
        label="Formas de pago"
        disabled={!isProfileComplete}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        to={`${url}/billing`}
        component={Link}
        value="billing"
        label="Facturación"
        disabled={!isProfileComplete}
        classes={{
          wrapper: classes.tab,
        }}
      />
    </Tabs>
  );
};

export default PaymentsNavigation;
