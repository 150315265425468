import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { Box, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import {
  WellnubTextInput,
  WellnubTable,
  WellnubCheckboxInput,
  WellnubSelectInput,
} from '@wellnub/web-common';

import { useStyles } from './MedicalHistoryLifeStyleForm.styles';

import { deleteLifeStyleSport } from 'redux/slices/forms.slice';
import { patientSelector } from 'redux/slices/patient/patient.selector';

import { useUpdate } from 'hooks/patient.hook';

import Button from '../../../Button/Button.component';
import AddPhysicalActivityForm from '../../AddPhysicalActivity/AddPhysicalActivityForm.component';

const TABLE_HEADERS = [
  'Acciones',
  'Tipo de ejercicio',
  'Frecuencia',
  'Horas a la semana',
  'Nota',
];

const SELECT_OPTIONS = [
  { id: 'physicalActivity-1', value: 'veryLow', label: 'Muy ligera' },
  { id: 'physicalActivity-2', value: 'low', label: 'Ligera' },
  { id: 'physicalActivity-3', value: 'medium', label: 'Medio' },
  { id: 'physicalActivity-4', value: 'high', label: 'Alto' },
  { id: 'physicalActivity-5', value: 'veryHigh', label: 'Muy alto' },
  {
    id: 'physicalActivity-6',
    value: 'competitive',
    label: 'Deportista competitivo',
  },
];

const STRESS_LEVEL_OPTIONS = [
  { id: 'stress-level-0', value: 'low', label: 'Bajo' },
  { id: 'stress-level-1', value: 'medium', label: 'Medio' },
  { id: 'stress-level-2', value: 'high', label: 'Alto' },
  { id: 'stress-level-3', value: 'veryHigh', label: 'Muy alto' },
];

const MedicalHistoryLifeStyleForm = ({ editable }) => {
  const [isToggleActive, setIsToggleActive] = useState(false);

  const { sports } = useSelector(state => state.forms.medicalHistory.lifeStyle);

  const { lifeStyle } = useSelector(state => state.patient.profile);

  const patient = useSelector(patientSelector);

  const { mutate, isLoading } = useUpdate();

  const { control, handleSubmit, watch, ...restMethods } = useForm({
    mode: 'onChange',
    defaultValues: {
      general: {
        studyLevel: lifeStyle?.general?.studyLevel ?? '',
        maritalStatus: lifeStyle?.general?.maritalStatus ?? '',
        job: lifeStyle?.general?.job ?? '',
        workingHourPerWeek: lifeStyle?.general?.workingHourPerWeek ?? '',
        stressLevel: lifeStyle?.general?.stressLevel ?? '',
      },
      phisicalActivity: {
        practicesSports: lifeStyle?.phisicalActivity?.practicesSports ?? false,
        level: lifeStyle?.phisicalActivity?.level ?? '',
      },
      otherHabits: {
        alcohol: {
          isActive: lifeStyle?.otherHabits?.alcohol?.isActive ?? false,
          frequency: lifeStyle?.otherHabits?.alcohol?.frequency ?? '',
        },
        tobacco: {
          isActive: lifeStyle?.otherHabits?.tobacco?.isActive ?? false,
          frequency: lifeStyle?.otherHabits?.tobacco?.frequency ?? '',
        },
        drugs: {
          isActive: lifeStyle?.otherHabits?.drugs?.isActive ?? false,
          frequency: lifeStyle?.otherHabits?.drugs?.frequency ?? '',
        },
        coffee: {
          isActive: lifeStyle?.otherHabits?.coffee?.isActive ?? false,
          frequency: lifeStyle?.otherHabits?.coffee?.frequency ?? '',
        },
      },
      notes: lifeStyle?.notes ?? '',
      sport: {
        activity: '',
        daysPerWeek: '',
        duration: '',
        note: '',
      },
    },
  });

  const [
    isTableVisible,
    isAlcoholVisible,
    isTobaccoVisible,
    isDrugsVisible,
    isCofeeVisible,
  ] = watch([
    'phisicalActivity.practicesSports',
    'otherHabits.alcohol.isActive',
    'otherHabits.tobacco.isActive',
    'otherHabits.drugs.isActive',
    'otherHabits.coffee.isActive',
  ]);

  const dispatch = useDispatch();

  const classes = useStyles();

  const TABLE_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteLifeStyleSport(id));
      },
    },
  ];

  const onSubmitHandler = data => {
    const payload = {
      lifeStyle: {
        general: { ...data.general },
        phisicalActivity: {
          ...data.phisicalActivity,
          sports: sports.map(sport => ({
            ...sport.data,
          })),
        },
        otherHabits: { ...data.otherHabits },
        notes: data.notes,
      },
    };
    mutate({
      id: patient._id,
      data: payload,
    });
  };

  const onToggleHandler = () => {
    setIsToggleActive(prevState => !prevState);
  };

  return (
    <FormProvider
      control={control}
      handleSubmit={handleSubmit}
      watch={watch}
      {...restMethods}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Box component="section">
          <Typography variant="h5" className={classes.sectionTitle}>
            Estilo de vida general
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12} md={6}>
              <WellnubTextInput
                fullWidth
                disabled={!editable}
                name="general.studyLevel"
                variant="outlined"
                label="Escolaridad"
                control={control}
                helperText="Último grado de estudios concluídos"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WellnubTextInput
                fullWidth
                disabled={!editable}
                name="general.maritalStatus"
                variant="outlined"
                label="Estado civil"
                control={control}
                helperText="Estado civil (soltero, casado, viudo, etc.)"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WellnubTextInput
                fullWidth
                disabled={!editable}
                name="general.job"
                variant="outlined"
                label="Ocupación"
                control={control}
                helperText="Ocupación"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              &nbsp;
            </Grid>
            <Grid item xs={12} md={6}>
              <WellnubTextInput
                fullWidth
                disabled={!editable}
                name="general.workingHourPerWeek"
                variant="outlined"
                label="Horas laborales semanales"
                control={control}
                helperText="Horas de trabajo semanales"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WellnubSelectInput
                fullWidth
                disabled={!editable}
                name="general.stressLevel"
                variant="outlined"
                label="Nivel de estrés"
                options={STRESS_LEVEL_OPTIONS}
                control={control}
                helperText="Selecciona una opción"
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Actividad física
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12} sm={6}>
              <WellnubSelectInput
                fullWidth
                name="phisicalActivity.level"
                variant="outlined"
                disabled={!editable}
                label="Nivel de actividad física"
                options={SELECT_OPTIONS}
                control={control}
                helperText="Selecciona una opción"
              />
            </Grid>
            <Grid item xs={12}>
              <WellnubCheckboxInput
                disabled={!editable}
                name="phisicalActivity.practicesSports"
                label="¿Practica algún deporte o actividad física regular?"
                control={control}
                defaultChecked={
                  lifeStyle?.phisicalActivity?.practicesSports ?? false
                }
              />
            </Grid>
          </Grid>
          {isTableVisible && (
            <Box mt={4}>
              <WellnubTable
                headers={TABLE_HEADERS}
                rows={sports}
                actions={TABLE_ACTIONS}
                disabled={!editable}
              />
              <Box mt={2}>
                <Button
                  disabled={!editable}
                  variant="outlined"
                  color="primary"
                  startIcon={isToggleActive ? <RemoveIcon /> : <AddIcon />}
                  onClick={onToggleHandler}
                >
                  Agregar nueva actividad física
                </Button>
              </Box>
              {isToggleActive && (
                <Box mt={2}>
                  <AddPhysicalActivityForm />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Otros hábitos
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={4}>
              <WellnubCheckboxInput
                disabled={!editable}
                name="otherHabits.alcohol.isActive"
                label="¿Consume alcohol regularmente?"
                control={control}
                defaultChecked={
                  lifeStyle?.otherHabits?.alcohol?.isActive ?? false
                }
              />
            </Grid>
            <Grid item xs={8}>
              {isAlcoholVisible && (
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  label="Frecuencia"
                  name="otherHabits.alcohol.frequency"
                  control={control}
                  helperText="# de eventos a la semana o mes, cantidad de alcohol por evento"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <WellnubCheckboxInput
                disabled={!editable}
                name="otherHabits.tobacco.isActive"
                label="¿Fuma tabaco regularmente?"
                control={control}
                defaultChecked={
                  lifeStyle?.otherHabits?.tobacco?.isActive ?? false
                }
              />
            </Grid>
            <Grid item xs={8}>
              {isTobaccoVisible && (
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  label="Frecuencia"
                  name="otherHabits.tobacco.frequency"
                  control={control}
                  helperText="# de eventos a la semana o mes, # de cigarrillos por evento"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <WellnubCheckboxInput
                disabled={!editable}
                name="otherHabits.drugs.isActive"
                label="¿Consume drogas regularmente?"
                control={control}
                defaultChecked={
                  lifeStyle?.otherHabits?.drugs?.isActive ?? false
                }
              />
            </Grid>
            <Grid item xs={8}>
              {isDrugsVisible && (
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  label="Frecuencia"
                  name="otherHabits.drugs.frequency"
                  control={control}
                  helperText="# de eventos a la semana o mes, tipo de droga"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <WellnubCheckboxInput
                disabled={!editable}
                name="otherHabits.coffee.isActive"
                label="¿Toma café regularmente?"
                control={control}
                defaultChecked={
                  lifeStyle?.otherHabits?.coffee?.isActive ?? false
                }
              />
            </Grid>
            <Grid item xs={8}>
              {isCofeeVisible && (
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  label="Frecuencia"
                  name="otherHabits.coffee.frequency"
                  control={control}
                  helperText="# de tazas a la semana"
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box component="section" mt={4}>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12}>
              <WellnubTextInput
                fullWidth
                multiline
                rows={2}
                name="notes"
                disabled={!editable}
                variant="outlined"
                label="Notas adicionales estilo de vida del paciente"
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
        {editable && (
          <Box mt={4} component="section" textAlign="right">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Guardar
            </Button>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default MedicalHistoryLifeStyleForm;