import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    services: [],
    mode: 'normal',
    target: {},
    editableService: {},
    warningMode: 'normal',
  },
  medicalHistory: {
    lifeStyle: {
      sports: [],
    },
    medicalProfile: {
      conditions: [],
      medications: [],
      surgeries: [],
      contraceptives: [],
      hormonalContraceptives: [],
    },
    dietProfile: {
      defaultDiets: [],
      defaultAlergies: [],
      defaultIntolerances: [],
      favFood: [],
      unfavFood: [],
      suplements: [],
      defaultMeals: [],
    },
  },
  createOffice: {
    isOpen: false
  }
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setDefaultServices: (state, action) => {
      state.profile.services = action.payload;
    },
    addProfileService: (state, action) => {
      state.profile.services.push(action.payload);
    },
    updateService: (state, action) => {
      const index = state.profile.services.findIndex(
        item => item.service === action.payload.service,
      );
      state.profile.services[index] = {
        ...action.payload,
      };
    },
    deleteService: (state, action) => {
      state.profile.services = state.profile.services.filter(
        service => service.service !== action.payload,
      );
    },
    setMode: (state, action) => {
      state.profile.mode = action.payload;
    },
    setEditableID: (state, action) => {
      state.profile.editID = action.payload;
    },
    setEditableService: (state, action) => {
      state.profile.editableService = action.payload;
    },
    // ---- MEDICAL HISTORY ----
    addLifeStyleSport: (state, action) => {
      const sport = {
        id: `lifestyle-sport-${state.medicalHistory.lifeStyle.sports.length}`,
        data: { ...action.payload },
      };
      state.medicalHistory.lifeStyle.sports.push(sport);
    },
    deleteLifeStyleSport: (state, action) => {
      const sports = state.medicalHistory.lifeStyle.sports.filter(
        sport => sport.id !== action.payload,
      );
      state.medicalHistory.lifeStyle.sports = sports;
    },
    addMedicalProfileCondition: (state, action) => {
      const condition = {
        id: `medicalprofile-condition-${state.medicalHistory.medicalProfile.conditions.length}`,
        data: { ...action.payload },
      };
      state.medicalHistory.medicalProfile.conditions.push(condition);
    },
    deleteMedicalProfileCondition: (state, action) => {
      const conditions = state.medicalHistory.medicalProfile.conditions.filter(
        condition => condition.id !== action.payload,
      );
      state.medicalHistory.medicalProfile.conditions = conditions;
    },
    addMedicalProfileMedication: (state, action) => {
      const medication = {
        id: `medicalprofile-medication-${state.medicalHistory.medicalProfile.medications.length}`,
        data: { ...action.payload },
      };
      state.medicalHistory.medicalProfile.medications.push(medication);
    },
    deleteMedicalProfileMedication: (state, action) => {
      const medications =
        state.medicalHistory.medicalProfile.medications.filter(
          medication => medication.id !== action.payload,
        );
      state.medicalHistory.medicalProfile.medications = medications;
    },
    addMedicalProfileSurgery: (state, action) => {
      const surgery = {
        id: `medicalprofile-surgery-${state.medicalHistory.medicalProfile.surgeries.length}`,
        data: { ...action.payload },
      };
      state.medicalHistory.medicalProfile.surgeries.push(surgery);
    },
    deleteMedicalProfileSurgery: (state, action) => {
      const surgeries = state.medicalHistory.medicalProfile.surgeries.filter(
        surgery => surgery.id !== action.payload,
      );
      state.medicalHistory.medicalProfile.surgeries = surgeries;
    },
    addMedicalProfileContraceptive: (state, action) => {
      const contraceptive = {
        id: `medicalprofile-contraceptive-${state.medicalHistory.medicalProfile.contraceptives.length}`,
        data: { ...action.payload },
      };
      state.medicalHistory.medicalProfile.contraceptives.push(contraceptive);
    },
    deleteMedicalProfileContraceptive: (state, action) => {
      const contraceptives =
        state.medicalHistory.medicalProfile.contraceptives.filter(
          contraceptive => contraceptive.id !== action.payload,
        );
      state.medicalHistory.medicalProfile.contraceptives = contraceptives;
    },
    addMedicalProfileHormonalContraceptive: (state, action) => {
      const contraceptive = {
        id: `medicalprofile-hormonalcontraceptive-${state.medicalHistory.medicalProfile.hormonalContraceptives.length}`,
        data: { ...action.payload },
      };
      state.medicalHistory.medicalProfile.hormonalContraceptives.push(
        contraceptive,
      );
    },
    deleteMedicalProfileHormonalContraceptive: (state, action) => {
      const contraceptives =
        state.medicalHistory.medicalProfile.hormonalContraceptives.filter(
          contraceptive => contraceptive.id !== action.payload,
        );
      state.medicalHistory.medicalProfile.hormonalContraceptives =
        contraceptives;
    },
    addDietProfileDefaultDiets: (state, action) => {
      const mapped = action.payload.map(diet => ({
        id: `dietprofile-diet-${diet}`,
        data: {
          type: diet,
        },
      }));
      const joined = [
        ...state.medicalHistory.dietProfile.defaultDiets,
        ...mapped,
      ];
      state.medicalHistory.dietProfile.defaultDiets = joined;
    },
    setDietProfileDefaultDiets: (state, action) => {
      state.medicalHistory.dietProfile.defaultDiets = action.payload;
    },
    addDietProfileDiet: (state, action) => {
      state.medicalHistory.dietProfile.defaultDiets.push({
        id: `dietprofile-diet-${state.medicalHistory.dietProfile.defaultDiets.length}`,
        data: {
          type: action.payload,
        },
      });
    },
    deleteDietProfileDiet: (state, action) => {
      const filtered = state.medicalHistory.dietProfile.defaultDiets.filter(
        diet => diet.data.type !== action.payload,
      );
      state.medicalHistory.dietProfile.defaultDiets = filtered;
    },
    addDietProfileAlergy: (state, action) => {
      state.medicalHistory.dietProfile.defaultAlergies.push({
        id: `dietprofile-alergy-${state.medicalHistory.dietProfile.defaultAlergies.length}`,
        data: {
          type: action.payload,
        },
      });
    },
    setDietProfileDefaultAlergies: (state, action) => {
      state.medicalHistory.dietProfile.defaultAlergies = action.payload;
    },
    deleteDietProfileAlergy: (state, action) => {
      const filtered = state.medicalHistory.dietProfile.defaultAlergies.filter(
        diet => diet.data.type !== action.payload,
      );
      state.medicalHistory.dietProfile.defaultAlergies = filtered;
    },
    addDietProfileIntolerance: (state, action) => {
      state.medicalHistory.dietProfile.defaultIntolerances.push({
        id: `dietprofile-intolerance-${state.medicalHistory.dietProfile.defaultIntolerances.length}`,
        data: {
          type: action.payload,
        },
      });
    },
    setDietProfileDefaultIntolerances: (state, action) => {
      state.medicalHistory.dietProfile.defaultIntolerances = action.payload;
    },
    deleteDietProfileIntolerance: (state, action) => {
      const filtered =
        state.medicalHistory.dietProfile.defaultIntolerances.filter(
          diet => diet.data.type !== action.payload,
        );
      state.medicalHistory.dietProfile.defaultIntolerances = filtered;
    },
    deleteDietProfileDefaultDiet: (state, action) => {
      const diets = state.medicalHistory.dietProfile.defaultDiets.filter(
        diet => diet.id !== action.payload,
      );
      state.medicalHistory.dietProfile.defaultDiets = diets;
    },
    addDietProfileDefaultAlergies: (state, action) => {
      const mapped = action.payload.map(alergie => ({
        id: `dietprofile-alergie-${alergie}`,
        data: {
          type: alergie,
        },
      }));
      const joined = [
        ...state.medicalHistory.dietProfile.defaultAlergies,
        ...mapped,
      ];
      state.medicalHistory.dietProfile.defaultAlergies = joined;
    },
    deleteDietProfileDefaultAlergie: (state, action) => {
      const alergie = state.medicalHistory.dietProfile.defaultAlergies.filter(
        alergie => alergie.id !== action.payload,
      );
      state.medicalHistory.dietProfile.defaultAlergies = alergie;
    },
    addDietProfileDefaultIntolerances: (state, action) => {
      const mapped = action.payload.map(i => ({
        id: `dietprofile-intolerance-${i}`,
        data: {
          type: i,
        },
      }));
      const joined = [
        ...state.medicalHistory.dietProfile.defaultIntolerances,
        ...mapped,
      ];
      state.medicalHistory.dietProfile.defaultIntolerances = joined;
    },
    deleteDietProfileDefaultIntolerance: (state, action) => {
      const intolerance =
        state.medicalHistory.dietProfile.defaultIntolerances.filter(
          i => i.id !== action.payload,
        );
      state.medicalHistory.dietProfile.defaultIntolerances = intolerance;
    },
    addDietProfileFavFood: (state, action) => {
      const food = {
        id: `dietprofile-favfood-${state.medicalHistory.dietProfile.favFood.length}`,
        data: action.payload,
      };
      state.medicalHistory.dietProfile.favFood.push(food);
    },
    setDietProfileFavFood: (state, action) => {
      state.medicalHistory.dietProfile.favFood = action.payload;
    },
    deleteDietProfileFavFood: (state, action) => {
      const food = state.medicalHistory.dietProfile.favFood.filter(
        item => item.id !== action.payload,
      );
      state.medicalHistory.dietProfile.favFood = food;
    },
    setDietProfileUnfavFood: (state, action) => {
      state.medicalHistory.dietProfile.unfavFood = action.payload;
    },
    addDietProfileUnfavFood: (state, action) => {
      const food = {
        id: `dietprofile-unfavfood-${state.medicalHistory.dietProfile.unfavFood.length}`,
        data: action.payload,
      };
      state.medicalHistory.dietProfile.unfavFood.push(food);
    },
    deleteDietProfileUnfavFood: (state, action) => {
      const food = state.medicalHistory.dietProfile.unfavFood.filter(
        item => item.id !== action.payload,
      );
      state.medicalHistory.dietProfile.unfavFood = food;
    },
    addDietProfileSuplement: (state, action) => {
      const suplement = {
        id: `dietprofile-suplement-${state.medicalHistory.dietProfile.suplements.length}`,
        data: { ...action.payload },
      };
      state.medicalHistory.dietProfile.suplements.push(suplement);
    },
    setDietProfileSupplements: (state, action) => {
      state.medicalHistory.dietProfile.suplements = action.payload;
    },
    deleteDietProfileSuplement: (state, action) => {
      const suplements = state.medicalHistory.dietProfile.suplements.filter(
        suplement => suplement.id !== action.payload,
      );
      state.medicalHistory.dietProfile.suplements = suplements;
    },
    // ---- INDIVIDUALS ----
    setLifeStyleSports: (state, action) => {
      state.medicalHistory.lifeStyle.sports = action.payload;
    },
    setMedicalProfileConditions: (state, action) => {
      state.medicalHistory.medicalProfile.conditions = action.payload;
    },
    setMedicalProfileMedications: (state, action) => {
      state.medicalHistory.medicalProfile.medications = action.payload;
    },
    setMedicalProfileSurgeries: (state, action) => {
      state.medicalHistory.medicalProfile.surgeries = action.payload;
    },
    setMedicalProfileOralContraceptives: (state, action) => {
      state.medicalHistory.medicalProfile.contraceptives = action.payload;
    },
    setMedicalProfileHormonalContraceptives: (state, action) => {
      state.medicalHistory.medicalProfile.hormonalContraceptives =
        action.payload;
    },
    setMedicalProfileDefaultMeals: (state, action) => {
      state.medicalHistory.dietProfile.defaultMeals = action.payload;
    },
    setEditableTarget: (state, action) => {
      state.profile.target = action.payload;
    },
    setWarningMode: (state, action) => {
      state.profile.warningMode = action.payload;
    },
    setIsCreateOfficeFormOpen: (state, action) => {
      state.createOffice.isOpen = action.payload
    }
  },
});

export const {
  addProfileService,
  updateService,
  deleteService,
  setMode,
  setEditableID,
  setDefaultServices,
  setEditableService,
  addLifeStyleSport,
  deleteLifeStyleSport,
  addMedicalProfileCondition,
  deleteMedicalProfileCondition,
  addMedicalProfileMedication,
  deleteMedicalProfileMedication,
  addMedicalProfileSurgery,
  deleteMedicalProfileSurgery,
  addMedicalProfileContraceptive,
  deleteMedicalProfileContraceptive,
  addMedicalProfileHormonalContraceptive,
  deleteMedicalProfileHormonalContraceptive,
  addDietProfileDefaultDiets,
  deleteDietProfileDefaultDiet,
  addDietProfileDefaultAlergies,
  deleteDietProfileDefaultAlergie,
  addDietProfileDefaultIntolerances,
  deleteDietProfileDefaultIntolerance,
  addDietProfileFavFood,
  deleteDietProfileFavFood,
  addDietProfileUnfavFood,
  deleteDietProfileUnfavFood,
  addDietProfileSuplement,
  deleteDietProfileSuplement,
  setLifeStyleSports,
  setMedicalProfileConditions,
  setMedicalProfileMedications,
  setMedicalProfileSurgeries,
  setMedicalProfileOralContraceptives,
  setMedicalProfileHormonalContraceptives,
  addDietProfileDiet,
  deleteDietProfileDiet,
  addDietProfileAlergy,
  deleteDietProfileAlergy,
  addDietProfileIntolerance,
  deleteDietProfileIntolerance,
  setDietProfileFavFood,
  setDietProfileUnfavFood,
  setDietProfileDefaultDiets,
  setDietProfileDefaultAlergies,
  setDietProfileDefaultIntolerances,
  setDietProfileSupplements,
  setMedicalProfileDefaultMeals,
  setEditableTarget,
  setWarningMode,
  setIsCreateOfficeFormOpen
} = formsSlice.actions;

export const formsReducer = formsSlice.reducer;