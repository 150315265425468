import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import { isEmpty } from 'lodash';
import { compareDesc } from 'date-fns';

import { PatientList } from 'components';

import { getInactivePatientsSelector } from 'redux/slices/patients/patients.selector';

const useStyles = makeStyles(theme => ({
  helperBox: {
    padding: theme.spacing('5px', 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.primary.light}`,
  },
}));

export const InactivePatientsPage = () => {
  const [list, setList] = useState([]);
  const [order, setOrder] = useState('alphabetic');
  const [searchText, setSearchText] = useState('');

  const patients = useSelector(getInactivePatientsSelector);

  const classes = useStyles();

  const searchChangeHandler = event => {
    setSearchText(event.target.value);
  };

  const onChangeOrderHandler = event => {
    setOrder(event.target.value);
  };

  const filterList = useCallback(
    (list, searchParams) =>
      list.filter(
        item =>
          item.phoneNumber.includes(searchParams) ||
          item.name.toLowerCase().includes(searchParams.toLowerCase()) ||
          item.lastName.toLowerCase().includes(searchParams.toLowerCase()),
      ),
    [],
  );

  useEffect(() => {
    if (order === 'recent') {
      const orderedList = [...list].sort((a, b) =>
        compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
      );
      setList(orderedList);
    }
    if (order === 'alphabetic') {
      const orderedList = [...list].sort((a, b) => {
        if (a.lastName > b.lastName) return 1;
        if (a.lastName < b.lastName) return -1;
        return 0;
      });
      setList(orderedList);
    }
  }, [order]);

  useEffect(() => {
    if (order === 'recent') {
      const orderedList = [...patients].sort((a, b) =>
        compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
      );
      setList(orderedList);
    }
    if (order === 'alphabetic') {
      const orderedList = [...patients].sort((a, b) => {
        if (a.lastName > b.lastName) return 1;
        if (a.lastName < b.lastName) return -1;
        return 0;
      });
      setList(orderedList);
    }
  }, [patients]);

  useEffect(() => {
    if (searchText) {
      const searchList = filterList(patients, searchText);
      setList(searchList);
    } else {
      if (order === 'recent') {
        const orderedList = [...patients].sort((a, b) =>
          compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
        );
        setList(orderedList);
      }
      if (order === 'alphabetic') {
        const orderedList = [...patients].sort((a, b) => {
          if (a.lastName > b.lastName) return 1;
          if (a.lastName < b.lastName) return -1;
          return 0;
        });
        setList(orderedList);
      }
    }
  }, [searchText]);

  return (
    <>
      {isEmpty(patients) && (
        <Box className={classes.helperBox}>
          <Typography variant="body2">
            Aún no hay pacientes inactivos
          </Typography>
        </Box>
      )}
      {!isEmpty(patients) && (
        <>
          <Grid container style={{ marginBottom: 16 }}>
            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                variant="outlined"
                label="Buscar paciente"
                onChange={searchChangeHandler}
              />
            </Grid>
            <Grid item xs sm>
              &nbsp;
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="order-select">Ordenar</InputLabel>
                <Select
                  label="Ordenar"
                  labelId="order-select"
                  value={order}
                  onChange={onChangeOrderHandler}
                >
                  <MenuItem value="recent">Mas recientes</MenuItem>
                  <MenuItem value="alphabetic">Orden A-Z</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <PatientList patients={list} />
        </>
      )}
    </>
  );
};
