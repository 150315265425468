export const currencyFormatter = value => {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(value / 100);

  return `${amount}`;
};

export const toArray = (data, key) =>
  Object.keys(data)
    .filter(value => value.includes(key))
    .reduce((accumulator, current) => {
      const indicator = current.slice(-1);
      if (current.includes(indicator)) {
        accumulator[indicator] = {
          title: data[`${key}-title-${indicator}`],
          school: data[`${key}-school-${indicator}`],
        };
      }
      return accumulator;
    }, []);

export const toActives = (data, key) =>
  Object.entries(data)
    .filter(entry => entry[0].includes(key))
    .reduce((accumulator, current) => {
      const [spec, isActive] = current;
      if (isActive) {
        return [...accumulator, spec.slice(4)];
      }
      return accumulator;
    }, []);

export const toValidNumber = mask => mask.replace(/[ ]/g, '');

export const getCroppedImage = (sourceImage, cropConfig, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = sourceImage.naturalWidth / sourceImage.width;
  const scaleY = sourceImage.naturalHeight / sourceImage.height;
  canvas.width = cropConfig.width;
  canvas.height = cropConfig.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    sourceImage,
    cropConfig.x * scaleX,
    cropConfig.y * scaleY,
    cropConfig.width * scaleX,
    cropConfig.height * scaleY,
    0,
    0,
    cropConfig.width,
    cropConfig.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      const croppedFile = new File([blob], fileName, {
        type: 'image/jpeg',
      });
      croppedFile.path = fileName;
      resolve(croppedFile);
    }, 'image/jpeg');
  });
};

export const getImageFile = async url => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: 'image/jpeg',
  };
  return new File([data], 'test.jpg', metadata);
};

export const validatePhoneNumber = value => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber) {
    if (value.includes('+52')) {
      const rest = value.substring(3);
      return rest.length === 10 || 'El número debe ser de 10 dígitos';
    }
    if (value.includes('+1')) {
      const [, ...rest] = value.split(' ');
      const last = rest.join('').replace(/[^\d]/g, '');
      return last.length === 10 || 'El número debe ser de 10 dígitos';
    } else {
      return 'Selecciona un código de área válido';
    }
  } else {
    return 'Este campo es obligatorio';
  }
};
