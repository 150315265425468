import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  WellnubTable,
  WellnubCheckboxInput,
  WellnubTextInput,
  WellnubNumberInput,
} from '@wellnub/web-common';

import {
  deleteDietProfileDefaultDiet,
  deleteDietProfileDefaultAlergie,
  deleteDietProfileDefaultIntolerance,
  addDietProfileFavFood,
  deleteDietProfileFavFood,
  addDietProfileUnfavFood,
  deleteDietProfileUnfavFood,
  deleteDietProfileSuplement,
  addDietProfileDiet,
  deleteDietProfileDiet,
  addDietProfileAlergy,
  deleteDietProfileAlergy,
  addDietProfileIntolerance,
  deleteDietProfileIntolerance,
} from 'redux/slices/forms.slice';

import { useUpdate } from 'hooks/patient.hook';

import Button from '../../../Button/Button.component';
import AddSuplementForm from '../../AddSuplement/AddSuplementForm.component';

import { useStyles } from './MedicalHistoryDietProfileForm.styles';

const DIET_LIST = [
  {
    id: 'diet-1',
    value: 'Alta en carbohidratos',
    label: 'Alta en carbohidratos',
  },
  { id: 'diet-2', value: 'Atkins', label: 'Atkins' },
  { id: 'diet-3', value: 'Ayuno intermitente', label: 'Ayuno intermitente' },
  { id: 'diet-4', value: 'Baja en fibra', label: 'Baja en fibra' },
  { id: 'diet-5', value: 'Baja en grasa', label: 'Baja en grasa' },
  {
    id: 'diet-6',
    value: 'Bajo en carbohidratos',
    label: 'Bajo en carbohidratos',
  },
  { id: 'diet-7', value: 'Basada en plantas', label: 'Basada en plantas' },
  { id: 'diet-8', value: 'Cetogénica', label: 'Cetogénica' },
  { id: 'diet-9', value: 'De la milpa', label: 'De la milpa' },
  { id: 'diet-1', value: 'FODMAP', label: 'FODMAP' },
  { id: 'diet-1', value: 'Hiper-proteíca', label: 'Hiper-proteíca' },
  { id: 'diet-2', value: 'Hipo-calórica', label: 'Hipo-calórica' },
  { id: 'diet-3', value: 'Kosher', label: 'Kosher' },
  { id: 'diet-4', value: 'Libre de gluten', label: 'Libre de gluten' },
  { id: 'diet-5', value: 'Macrobiótica', label: 'Macrobiótica' },
  { id: 'diet-6', value: 'Macros', label: 'Macros' },
  { id: 'diet-7', value: 'Mediterranea', label: 'Mediterranea' },
  { id: 'diet-8', value: 'Paleolitica', label: 'Paleolitica' },
  { id: 'diet-9', value: 'Pescetariana', label: 'Pescetariana' },
  { id: 'diet-1', value: 'Vegana', label: 'Vegana' },
];

const ALERGIES_LIST = [
  { id: 'alergie-1', value: 'Aguacate', label: 'Aguacate' },
  { id: 'alergie-2', value: 'Almeja', label: 'Almeja' },
  { id: 'alergie-3', value: 'Almendras', label: 'Almendras' },
  { id: 'alergie-4', value: 'Cacahuate', label: 'Cacahuate' },
  { id: 'alergie-5', value: 'Calamar', label: 'Calamar' },
  { id: 'alergie-6', value: 'Camarón', label: 'Camarón' },
  { id: 'alergie-7', value: 'Chocolate', label: 'Chocolate' },
  { id: 'alergie-8', value: 'Durazno', label: 'Durazno' },
  { id: 'alergie-9', value: 'Fresa', label: 'Fresa' },
  { id: 'alergie-1', value: 'Granada', label: 'Granada' },
  { id: 'alergie-1', value: 'Huevo', label: 'Huevo' },
  { id: 'alergie-2', value: 'Kiwi', label: 'Kiwi' },
  { id: 'alergie-3', value: 'Langosta', label: 'Langosta' },
  { id: 'alergie-4', value: 'Langostino', label: 'Langostino' },
  { id: 'alergie-5', value: 'Leche de vaca', label: 'Leche de vaca' },
  { id: 'alergie-6', value: 'Mango', label: 'Mango' },
  { id: 'alergie-7', value: 'Manzana', label: 'Manzana' },
  { id: 'alergie-8', value: 'Naranja', label: 'Naranja' },
  { id: 'alergie-9', value: 'Nuez', label: 'Nuez' },
  { id: 'alergie-1', value: 'Nuez de brasil', label: 'Nuez de brasil' },
  { id: 'alergie-1', value: 'Nuez de la india', label: 'Nuez de la india' },
  { id: 'alergie-2', value: 'Nuez de macadamia', label: 'Nuez de macadamia' },
  { id: 'alergie-3', value: 'Pera', label: 'Pera' },
  { id: 'alergie-4', value: 'Pescado', label: 'Pescado' },
  { id: 'alergie-5', value: 'Piña', label: 'Piña' },
  { id: 'alergie-6', value: 'Piñón', label: 'Piñón' },
  { id: 'alergie-7', value: 'Pistache', label: 'Pistache' },
  { id: 'alergie-8', value: 'Plátano', label: 'Plátano' },
  { id: 'alergie-9', value: 'Soya', label: 'Soya' },
  { id: 'alergie-1', value: 'Trigo', label: 'Trigo' },
];

const INTOLERANCES_LIST = [
  { id: 'intolerance-1', value: 'intolerance1', label: 'Cruciferas' },
  { id: 'intolerance-2', value: 'intolerance2', label: 'Gluten' },
  {
    id: 'intolerance-3',
    value: 'intolerance3',
    label: 'Huevo',
  },
  {
    id: 'intolerance-4',
    value: 'intolerance4',
    label: 'Lácteos',
  },
  {
    id: 'intolerance-5',
    value: 'intolerance5',
    label: 'Leguminosas',
  },
  {
    id: 'intolerance-6',
    value: 'intolerance6',
    label: 'Maíz',
  },
  {
    id: 'intolerance-7',
    value: 'intolerance7',
    label: 'Soya',
  },
];

const SUPLEMENTS_TABLE_HEADERS = [
  'Acciones',
  'Nombre',
  'Dosis',
  'Razón',
  'Nota',
];

const MedicalHistoryDietProfileForm = ({ editable }) => {
  const [isToggleDietList, setIsToggleDietList] = useState(false);
  const [isToggleAlergiesList, setIsToggleAlergiesList] = useState(false);
  const [isToggleIntolerancesList, setIsToggleIntolerancesList] =
    useState(false);
  const [isToggleSuplementActive, setIsToggleSuplementActive] = useState(false);

  const {
    defaultDiets,
    defaultAlergies,
    defaultIntolerances,
    favFood,
    unfavFood,
    suplements,
    defaultMeals,
  } = useSelector(state => state.forms.medicalHistory.dietProfile);

  const { dietetic } = useSelector(state => state.patient.profile);

  const { id } = useParams();

  const { control, handleSubmit, watch, setValue, getValues, ...restMethods } =
    useForm({
      mode: 'onChange',
      defaultValues: {
        suplement: {
          name: '',
          dosage: '',
          reason: '',
          note: '',
        },
        food: '',
        aditionalNotes: dietetic?.aditionalNotes ?? '',
        defaultDiets: [],
        defaultAlergies: [],
        defaultIntolerances: [],
        meals: defaultMeals ?? [
          {
            value: '',
          },
          {
            value: '',
          },
          {
            value: '',
          },
        ],
        outsideMeal1: dietetic?.mealsLocation?.outWeekday ?? 0,
        outsideMeal2: dietetic?.mealsLocation?.homeWeekday ?? 0,
        outsideMeal3: 0,
        outsideMeal4: dietetic?.mealsLocation?.outWeekend ?? 0,
        outsideMeal5: dietetic?.mealsLocation?.homeWeekend ?? 0,
        outsideMeal6: 0,
        outsideMeal7: 0,
        outsideMeal8: 0,
        outsideMeal9: 0,
        otherDiet: '',
        otherAlergy: '',
        otherIntolerance: '',
        suplements: {
          usesSupplements: dietetic?.supplements?.usesSupplements ?? false,
        },
      },
    });

  const { mutate, isLoading } = useUpdate();

  const { fields, append } = useFieldArray({
    control,
    name: 'meals',
  });

  const {
    otherDiet,
    otherAlergy,
    otherIntolerance,
    outsideMeal1,
    outsideMeal2,
    outsideMeal4,
    outsideMeal5,
    outsideMeal7,
    outsideMeal8,
    suplements: { usesSupplements },
  } = watch();

  const classes = useStyles();

  const dispatch = useDispatch();

  const DIET_TYPE_TABLE_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteDietProfileDefaultDiet(id));
      },
    },
  ];

  const ALERGIES_TYPE_TABLE_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteDietProfileDefaultAlergie(id));
      },
    },
  ];

  const INTOLERANCES_TYPE_TABLE_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteDietProfileDefaultIntolerance(id));
      },
    },
  ];

  const SUPLEMENTS_TABLE_ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        dispatch(deleteDietProfileSuplement(id));
      },
    },
  ];

  const onSubmitHandler = data => {
    const payload = {
      dietProfile: {
        numberOfDailyMeals: data.numberOfDailyMeals,
        mealSchedule: data.meals.map(meal => ({ hour: meal.value })),
        mealsLocation: {
          outWeekday: data.outsideMeal1,
          outWeekend: data.outsideMeal4,
          homeWeekday: data.outsideMeal2,
          homeWeekend: data.outsideMeal5,
        },
        diets: defaultDiets.map(({ data }) => data.type),
        allergies: defaultAlergies.map(({ data }) => data.type),
        intolerances: defaultIntolerances.map(({ data }) => data.type),
        likedFood: favFood.map(({ data }) => data),
        dislikedFood: unfavFood.map(({ data }) => data),
        supplements: {
          usesSupplements: data.suplements.usesSupplements,
          suplements: suplements.map(({ data }) => ({
            name: data.name,
            dose: data.dosage,
            reason: data.reason,
            note: data.note,
          })),
        },
        aditionalNotes: data.aditionalNotes,
      },
    };
    mutate({
      id,
      data: payload,
    });
  };

  const onUpdateMealsHandler = () => {
    const { numberOfDailyMeals } = getValues();
    setValue('meals', []);
    [...Array(+numberOfDailyMeals)].forEach(_ => {
      append({ value: '' });
    });
  };

  const onToggleDietListHandler = () => {
    setIsToggleDietList(prevState => !prevState);
  };

  const onToggleAlergiesListHandler = () => {
    setIsToggleAlergiesList(prevState => !prevState);
  };

  const onToggleIntolerancesListHandler = () => {
    setIsToggleIntolerancesList(prevState => !prevState);
  };

  const onAddOtherDietHandler = () => {
    const diet = getValues('otherDiet');
    dispatch(addDietProfileDiet(diet));
    setTimeout(() => {
      setValue('otherDiet', '');
    }, 100);
  };

  const onAddOtherAlergyHandler = () => {
    const alergy = getValues('otherAlergy');
    dispatch(addDietProfileAlergy(alergy));
    setTimeout(() => {
      setValue('otherAlergy', '');
    }, 100);
  };

  const onAddOtherIntoleranceHandler = () => {
    const intolerance = getValues('otherIntolerance');
    dispatch(addDietProfileIntolerance(intolerance));
    setTimeout(() => {
      setValue('otherIntolerance', '');
    }, 100);
  };

  const onAddFavFoodHandler = () => {
    const food = getValues('food');
    dispatch(addDietProfileFavFood(food));
    setTimeout(() => {
      setValue('food', '');
    }, 100);
  };

  const onAddUnfavFoodHandler = () => {
    const food = getValues('food');
    dispatch(addDietProfileUnfavFood(food));
    setTimeout(() => {
      setValue('food', '');
    }, 100);
  };

  const onCheckedDietHandler = (checked, value) => {
    if (checked) {
      dispatch(addDietProfileDiet(value));
    } else {
      dispatch(deleteDietProfileDiet(value));
    }
  };

  const onCheckedAlergyHandler = (checked, value) => {
    if (checked) {
      dispatch(addDietProfileAlergy(value));
    } else {
      dispatch(deleteDietProfileAlergy(value));
    }
  };

  const onCheckedIntoleranceHandler = (checked, value) => {
    if (checked) {
      dispatch(addDietProfileIntolerance(value));
    } else {
      dispatch(deleteDietProfileIntolerance(value));
    }
  };

  const onDeleteFavFood = id => {
    dispatch(deleteDietProfileFavFood(id));
  };

  const onDeleteUnfavFood = id => {
    dispatch(deleteDietProfileUnfavFood(id));
  };

  const onToggleSuplementHandler = () => {
    setIsToggleSuplementActive(prevState => !prevState);
  };

  return (
    <FormProvider
      control={control}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      handleSubmit={handleSubmit}
      {...restMethods}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Box component="section">
          <Typography variant="h5" className={classes.sectionTitle}>
            Información general sobre ingesta de comida
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12} sm={3}>
              <WellnubNumberInput
                fullWidth
                disabled={!editable}
                defaultValue={dietetic?.numberOfDailyMeals ?? 0}
                name="numberOfDailyMeals"
                variant="outlined"
                format="#####"
                helperText="Número promedio de comidas al día"
                label="Comidas"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                disabled={!editable}
                color="primary"
                onClick={onUpdateMealsHandler}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box component="section" mt={4}>
          <Typography>Horarios de comida:</Typography>
          <Grid container spacing={4} className={classes.grid}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={4} key={field.id}>
                <WellnubTextInput
                  fullWidth
                  disabled={!editable}
                  variant="outlined"
                  name={`meals.${index}.value`}
                  label={`Comida ${index + 1}`}
                  control={control}
                  helperText="Horario de comida"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box component="section" mt={4}>
          <Typography>Comida en casa y fuera de casa a la semana:</Typography>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item sm={3}>
              &nbsp;
            </Grid>
            <Grid item sm={3} className={classes.dataHeader}>
              <Typography className={classes.dataHeader}>
                Fuera de casa
              </Typography>
            </Grid>
            <Grid item sm={3} className={classes.dataHeader}>
              <Typography>En casa</Typography>
            </Grid>
            <Grid item sm={3} className={classes.dataHeader}>
              <Typography>Total</Typography>
            </Grid>
            <Grid
              item
              sm={3}
              className={`${classes.dataHeader} ${classes.flex}`}
            >
              <Typography>Entre semana</Typography>
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                name="outsideMeal1"
                defaultValue={dietetic?.mealsLocation?.outWeekday ?? 0}
                disabled={!editable}
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                defaultValue={dietetic?.mealsLocation?.homeWeekday ?? 0}
                name="outsideMeal2"
                disabled={!editable}
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                disabled
                name="outsideMeal3"
                size="small"
                value={+outsideMeal1 + +outsideMeal2}
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid
              item
              sm={3}
              className={`${classes.dataHeader} ${classes.flex}`}
            >
              <Typography>Fin de semana</Typography>
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                name="outsideMeal4"
                defaultValue={dietetic?.mealsLocation?.outWeekend ?? 0}
                disabled={!editable}
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                name="outsideMeal5"
                defaultValue={dietetic?.mealsLocation?.homeWeekend ?? 0}
                disabled={!editable}
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                disabled
                name="outsideMeal6"
                value={+outsideMeal4 + +outsideMeal5}
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid
              item
              sm={3}
              className={`${classes.dataHeader} ${classes.flex}`}
            >
              <Typography>Total</Typography>
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                disabled
                name="outsideMeal7"
                value={+outsideMeal1 + +outsideMeal4}
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                disabled
                value={+outsideMeal2 + +outsideMeal5}
                name="outsideMeal8"
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
            <Grid item sm={3}>
              <WellnubNumberInput
                disabled
                value={+outsideMeal7 + +outsideMeal8}
                name="outsideMeal9"
                size="small"
                format="#####"
                variant="outlined"
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Tipo de dieta
          </Typography>
          <Box mt={4}>
            <WellnubTable
              headers={['Acciones', 'Tipo de dietas']}
              rows={defaultDiets}
              actions={DIET_TYPE_TABLE_ACTIONS}
              disabled={!editable}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="outlined"
              disabled={!editable}
              color="primary"
              startIcon={isToggleDietList ? <RemoveIcon /> : <AddIcon />}
              onClick={onToggleDietListHandler}
            >
              Agregar nueva dieta
            </Button>
          </Box>

          {isToggleDietList && (
            <>
              <FormGroup>
                <Grid container spacing={2} className={classes.grid}>
                  {DIET_LIST.map(diet => (
                    <Grid item xs={2} sm={2} key={diet.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e =>
                              onCheckedDietHandler(e.target.checked, diet.label)
                            }
                            color="primary"
                            checked={defaultDiets.some(
                              item => item.data.type === diet.label,
                            )}
                          />
                        }
                        label={diet.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              <Box mt={2} textAlign="right" display="flex" alignItems="center">
                <WellnubTextInput
                  name="otherDiet"
                  variant="outlined"
                  label="Otro"
                  control={control}
                  helperText="Otro tipo de dieta"
                />
                <Box ml={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!otherDiet}
                    onClick={onAddOtherDietHandler}
                  >
                    Agregar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Alergias
          </Typography>
          <Box mt={4}>
            <WellnubTable
              headers={['Acciones', 'Alergias']}
              rows={defaultAlergies}
              actions={ALERGIES_TYPE_TABLE_ACTIONS}
              disabled={!editable}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="outlined"
              disabled={!editable}
              color="primary"
              startIcon={isToggleAlergiesList ? <RemoveIcon /> : <AddIcon />}
              onClick={onToggleAlergiesListHandler}
            >
              Agregar nueva alergia
            </Button>
          </Box>
          {isToggleAlergiesList && (
            <>
              <FormGroup>
                <Grid container spacing={2} className={classes.grid}>
                  {ALERGIES_LIST.map(alergy => (
                    <Grid item xs={2} sm={2} key={alergy.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e =>
                              onCheckedAlergyHandler(
                                e.target.checked,
                                alergy.label,
                              )
                            }
                            color="primary"
                            checked={defaultAlergies.some(
                              item => item.data.type === alergy.label,
                            )}
                          />
                        }
                        label={alergy.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              <Box mt={2} textAlign="right" display="flex" alignItems="center">
                <WellnubTextInput
                  name="otherAlergy"
                  variant="outlined"
                  label="Otro"
                  control={control}
                  helperText="Otro tipo de alergia"
                />
                <Box ml={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!otherAlergy}
                    onClick={onAddOtherAlergyHandler}
                  >
                    Agregar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Intolerancias
          </Typography>
          <Box mt={4}>
            <WellnubTable
              headers={['Acciones', 'Intolerancias']}
              rows={defaultIntolerances}
              actions={INTOLERANCES_TYPE_TABLE_ACTIONS}
              disabled={!editable}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="outlined"
              disabled={!editable}
              color="primary"
              startIcon={
                isToggleIntolerancesList ? <RemoveIcon /> : <AddIcon />
              }
              onClick={onToggleIntolerancesListHandler}
            >
              Agregar nueva intolerancia
            </Button>
          </Box>
          {isToggleIntolerancesList && (
            <>
              <FormGroup>
                <Grid container spacing={2} className={classes.grid}>
                  {INTOLERANCES_LIST.map(intolerance => (
                    <Grid item xs={2} sm={2} key={intolerance.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e =>
                              onCheckedIntoleranceHandler(
                                e.target.checked,
                                intolerance.label,
                              )
                            }
                            color="primary"
                            checked={defaultIntolerances.some(
                              item => item.data.type === intolerance.label,
                            )}
                          />
                        }
                        label={intolerance.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              <Box mt={2} textAlign="right" display="flex" alignItems="center">
                <WellnubTextInput
                  name="otherIntolerance"
                  variant="outlined"
                  label="Otro"
                  control={control}
                  helperText="Otro tipo de intolerancia"
                />
                <Box ml={4}>
                  <Button
                    disabled={!otherIntolerance}
                    variant="outlined"
                    color="primary"
                    onClick={onAddOtherIntoleranceHandler}
                  >
                    Agregar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Alimentos preferidos y no preferidos
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12} sm={6}>
              <WellnubTextInput
                fullWidth
                disabled={!editable}
                name="food"
                variant="outlined"
                label="Alimento"
                helperText="Alimento a agregar"
                control={control}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12} sm={6}>
              <Box mb={1} textAlign="center">
                <Button
                  variant="contained"
                  disabled={!editable}
                  color="primary"
                  onClick={onAddFavFoodHandler}
                >
                  Agregar a preferidos
                </Button>
              </Box>
              <Typography
                variant="h6"
                className={`${classes.favTitle} ${classes.favTitlePrimary}`}
              >
                :) Alimentos preferidos
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={1} textAlign="center">
                <Button
                  variant="outlined"
                  disabled={!editable}
                  color="primary"
                  onClick={onAddUnfavFoodHandler}
                >
                  Agregar a evitar
                </Button>
              </Box>
              <Typography
                variant="h6"
                className={`${classes.favTitle} ${classes.favTitleSecondary}`}
              >
                :( Alimentos a evitar
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <List dense className={classes.list}>
                {favFood.map(food => (
                  <ListItem key={food.id}>
                    <ListItemText primary={food.data} />
                    <ListItemSecondaryAction>
                      <IconButton
                        disabled={!editable}
                        edge="end"
                        onClick={() => onDeleteFavFood(food.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <List dense className={classes.list}>
                {unfavFood.map(food => (
                  <ListItem key={food.id}>
                    <ListItemText primary={food.data} />
                    <ListItemSecondaryAction>
                      <IconButton
                        disabled={!editable}
                        edge="end"
                        onClick={() => onDeleteUnfavFood(food.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
        <Box component="section" mt={4}>
          <Typography variant="h5" className={classes.sectionTitle}>
            Suplementos
          </Typography>
          <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12}>
              <WellnubCheckboxInput
                fullWidth
                disabled={!editable}
                name="suplements.usesSupplements"
                label="¿El paciente toma algún suplemento o complemento?"
                control={control}
                defaultChecked={dietetic?.supplements?.usesSupplements ?? false}
              />
            </Grid>
          </Grid>
          {usesSupplements && (
            <Box mt={4}>
              <WellnubTable
                headers={SUPLEMENTS_TABLE_HEADERS}
                rows={suplements}
                actions={SUPLEMENTS_TABLE_ACTIONS}
                disabled={!editable}
              />
              <Box mt={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={
                    isToggleSuplementActive ? <RemoveIcon /> : <AddIcon />
                  }
                  onClick={onToggleSuplementHandler}
                >
                  Agregar nuevo suplemento
                </Button>
              </Box>
              {isToggleSuplementActive && (
                <Box mt={2}>
                  <AddSuplementForm />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box component="section" mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <WellnubTextInput
                fullWidth
                multiline
                rows={2}
                disabled={!editable}
                name="aditionalNotes"
                variant="outlined"
                label="Notas adicionales al perfil dietético del paciente"
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
        {editable && (
          <Box component="section" mt={4} textAlign="right">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Guardar
            </Button>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default MedicalHistoryDietProfileForm;
