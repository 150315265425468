import { array, number, object, string } from 'yup';

const REQUIRED_MESSAGE = 'Este campo es obligatorio';

export const FormSchema = object({
  service: string().required(REQUIRED_MESSAGE),
  mode: array().min(1, 'Debes de seleccionar al menos una opción'),
  price: string().required(REQUIRED_MESSAGE),
  minutesLenght: number()
    .integer()
    .min(15, 'La duración del servicio debe ser al menos 15 minutos')
    .required(REQUIRED_MESSAGE),
  description: string(),
});
