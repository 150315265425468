import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  mode: 'normal',
  isAddedFromVirtualAgenda: false,
  profile: {
    general: {},
    lifeStyle: {},
    medical: {},
    dietetic: {},
    nutritionalPlans: [],
    nutritionalPlanSelected: {
      id: '',
    },
    biochemicalPlans: [],
    biochemicalPlanSelected: {
      id: '',
    },
  },
  isAlreadyRegistered: false,
  instructionsID: '',
  placeholder: {
    email: '',
    phoneNumber: '',
    dataForRegister: {},
    publicField: '',
    countryCode: '52',
  },
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatientMode: (state, action) => {
      state.mode = action.payload;
    },
    setPatientData: (state, action) => {
      state.data = action.payload;
    },
    setPatientRegisterStatus: (state, action) => {
      state.isAlreadyRegistered = action.payload;
    },
    setEmailPlaceholder: (state, action) => {
      state.placeholder.email = action.payload;
    },
    setPhoneNumberPlaceholder: (state, action) => {
      state.placeholder.phoneNumber = action.payload;
    },
    setCountryCodePlaceholder: (state, action) => {
      state.placeholder.countryCode = action.payload;
    },
    setPatientGeneralProfile: (state, action) => {
      state.profile.general = action.payload;
    },
    setPatientMedicalProfile: (state, action) => {
      state.profile.medical = action.payload;
    },
    setPatientLifeStyleProfile: (state, action) => {
      state.profile.lifeStyle = action.payload;
    },
    setPatientDieteticProfile: (state, action) => {
      state.profile.dietetic = action.payload;
    },
    setNutritionalPlans: (state, action) => {
      state.profile.nutritionalPlans = action.payload;
    },
    setNutritionalPlanId: (state, action) => {
      state.profile.nutritionalPlanSelected.id = action.payload;
    },
    setBiochemicalPlans: (state, action) => {
      state.profile.biochemicalPlans = action.payload;
    },
    setBiochemicalPlanId: (state, action) => {
      state.profile.biochemicalPlanSelected.id = action.payload;
    },
    setPatientDataForRegister: (state, action) => {
      state.placeholder.dataForRegister = action.payload;
    },
    setPatientPublicField: (state, action) => {
      state.placeholder.publicField = action.payload;
    },
    setInstructionsID: (state, action) => {
      state.instructionsID = action.payload;
    },
    setIsAddedFromVirtualAgenda: (state, action) => {
      state.isAddedFromVirtualAgenda = action.payload
    }
  },
});

export const {
  setInstructionsID,
  setPatientMode,
  setPatientData,
  setPatientRegisterStatus,
  setEmailPlaceholder,
  setPhoneNumberPlaceholder,
  setPatientGeneralProfile,
  setPatientMedicalProfile,
  setPatientLifeStyleProfile,
  setPatientDieteticProfile,
  setNutritionalPlans,
  setNutritionalPlanId,
  setBiochemicalPlans,
  setBiochemicalPlanId,
  setPatientDataForRegister,
  setPatientPublicField,
  setCountryCodePlaceholder,
  setIsAddedFromVirtualAgenda
} = patientSlice.actions;

export const patientReducer = patientSlice.reducer;