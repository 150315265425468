import { createSelector } from '@reduxjs/toolkit';

export const patientSelector = createSelector(
  [state => state.wnPatient.data],
  patient => patient,
);

export const patientInbodyResultsSelector = createSelector(
  [state => state.wnPatient.data?.inbodyResults ?? []],
  inbodyResults => inbodyResults,
);

export const patientBiochemicalResultsSelector = createSelector(
  [state => state.wnPatient.medicalPlans.biochemical],
  biochemicalPlans => biochemicalPlans,
);
