import { wellnub } from '../wellnub.api';

export const getPatient = id => wellnub.get(`/v1/nutritionist/patient/${id}`);

export const requestUpdate = payload =>
  wellnub.patch(`/v1/nutritionist/patient/${payload.id}`, payload.data);

export const getPatients = () => wellnub.get('/v1/nutritionist/patient');

export const requestCreation = async payload => {
  const { data } = await wellnub.post(
    `/v1/nutritionist/patient?shouldAddPass=${payload.shouldAddPass}&addedFromCalendar=${payload.addedFromCalendar}`,
    payload.data,
  );
  return data;
};

export const requestVerification = payload =>
  wellnub.post('/v1/nutritionist/patient/verify', payload);

export const requestAddPass = id =>
  wellnub.post(`/v1/nutritionist/patient/addPass/${id}`);

export const requestActivation = id =>
  wellnub.post(`/v1/nutritionist/patient/activate/${id}`);

export const requestInactivation = id =>
  wellnub.delete(`/v1/nutritionist/patient/activate/${id}`);

export const requestLinkPatient = async payload => {
  const { data } = await wellnub.post(
    `/v1/nutritionist/linkRequest?shouldAddPass=${payload.shouldAddPass}&addedFromCalendar=${payload.addedFromCalendar}`,
    payload.data,
  );
  return data;
};

export const requestAddNutritionalPlan = async payload => {
  const { data } = await wellnub.post(
    '/v1/nutritionist/nutritionPlan',
    payload,
  );
  return data;
};

export const requestGetNutritionalPlans = async id => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/nutritionPlan/byPatient/${id}`,
  );
  return data;
};

export const requestDeleteNutritionalPlan = id =>
  wellnub.delete(`/v1/nutritionist/nutritionPlan/${id}`);

export const requestAddBiochemicalPlan = async payload => {
  const { data } = await wellnub.post('/v1/nutritionist/analysis', payload);
  return data;
};

export const requestGetBiochemicalPlans = async id => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/analysis/byPatient/${id}`,
  );
  return data;
};

export const requestDeleteBiochemicalPlan = id =>
  wellnub.delete(`/v1/nutritionist/analysis/${id}`);

export const getGeneralInstructions = async id => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/patient/registerInstructions/${id}`,
  );
  return data;
};

export const patchUpdatePatientBasicInfo = async payload => {
  const { data } = await wellnub.patch(
    `/v1/nutritionist/patient/basicInfo/${payload.id}`,
    payload.data,
  );
  return data;
};