import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setMode } from 'redux/slices/forms.slice';
import { useDeleteNutritionistOffice } from 'hooks/nutritionist.hook';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { nanoid } from '@reduxjs/toolkit';

const DeleteOfficeWarningPanel = () => {
  const [affectedPatients, setAffectedPatients] = useState([]);

  const { warningMode, target } = useSelector(state => state.forms.profile);

  const { patients } = useSelector(state => state.affectedPatients);

  const { mutate: deleteOffice, isLoading } = useDeleteNutritionistOffice();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    if (warningMode === 'editable') {
      dispatch(
        setModalStatus({
          name: 'AFFECTED_PATIENTS_WARNING',
          isActive: false,
        }),
      );
      dispatch(setMode('editable'));
      return;
    }
    if (warningMode === 'delete') {
      deleteOffice(target._id);
    }
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'AFFECTED_PATIENTS_WARNING',
        isActive: false,
      }),
    );
  };

  useEffect(() => {
    if (isEmpty(patients)) {
      setAffectedPatients([]);
      return;
    }
    const affectedPatients = patients.map(item => {
      const [email, name, office] = item.split(' - ');
      return { email, name, office: office };
    });
    setAffectedPatients(affectedPatients);
  }, [patients]);

  return (
    <Box>
      <Typography align="center" variant="h5">
        ¿Estás seguro que deseas{' '}
        {warningMode === 'editable' ? 'editar' : 'eliminar'} este consultorio?
      </Typography>
      <br />
      {!isEmpty(affectedPatients) ? (
        <>
          <Typography align="center">
            Estos pacientes ya agendaron una consulta y se encuentran fuera de
            tus nuevos consultorios.
          </Typography>
          <Typography align="center">
            Las consultas no se modificarán, por favor comunícate directamente
            con ellos si quieres reagendar.
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Consultorio</TableCell>
                  <TableCell align="center">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {affectedPatients.map(patient => (
                  <TableRow key={nanoid()}>
                    <TableCell align="center">{patient.name}</TableCell>
                    <TableCell align="center">{patient.office}</TableCell>
                    <TableCell align="center">{patient.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography align="center">Esta acción no se puede revertir</Typography>
      )}
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button variant="contained" onClick={onAcceptHandler} color="primary">
          Aceptar
        </Button>
        <Button variant="outlined" onClick={onCancelHandler} color="primary">
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteOfficeWarningPanel;
