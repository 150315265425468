import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import OfficesRow from '../../Rows/NutritionistOffice/OfficesRow.component';
import { useStyles } from './OfficesTable.styles';

const OfficesTable = ({ offices }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell align="center" className={classes.header}>
              Acciones
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Nombre
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Modalidad
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Dirección
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Instrucciones/Link
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offices.map(item => (
            <OfficesRow key={item._id} office={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfficesTable;
