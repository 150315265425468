import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  appbar: {
    boxShadow: 'unset',
  },
  tabs: {
    backgroundColor: '#FAFAFA',
  },
  tab: {
    color: theme.palette.primary.light,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 12,
  },
  selected: {
    fontWeight: 'bold',
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  subtitle: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  center: {
    textAlign: 'center',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  spacing: {
    paddingBottom: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(4),
  },
  helperBox: {
    padding: theme.spacing('5px', 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.primary.light}`,
  },
  helperSubtitle: {
    textAlign: 'center',
  },
  block: {
    display: 'block',
  },
  asLink: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    cursor: 'pointer',
  },
  withInvitationsTabBadge: {
    display: 'flex',
    flexDirection: 'row',
    '&::after': {
      display: ({ hasInvitationsTabBadge }) =>
        hasInvitationsTabBadge ? 'block' : 'none',
      content: '""',
      width: '10px',
      height: '10px',
      backgroundColor: '#ff4262',
      borderRadius: '50%',
    },
  },
  withActiveTabBadge: {
    display: 'flex',
    flexDirection: 'row',
    '&::after': {
      display: ({ hasActivePatientsTabBadge }) =>
        hasActivePatientsTabBadge ? 'block' : 'none',
      content: '""',
      width: '10px',
      height: '10px',
      backgroundColor: '#ff4262',
      borderRadius: '50%',
    },
  },
  alert: {
    position: 'absolute',
    width: 'max-content',
    top: 0,
    left: 50,
  },
  alertWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
}));
