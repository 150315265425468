import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';

import NumberFormat from 'react-number-format';

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { nutritionistServicesSelector } from 'redux/slices/user.selector';

import {
  WellnubSelectInput,
  WellnubCheckboxGroupInput,
  WellnubTextInput,
} from '@wellnub/web-common';

import { usePatchProfile } from 'hooks/nutritionist.hook';

import { FormSchema } from './AddServiceForm.schema';

import { useStyles } from './AddServiceForm.styles';
import { setEditableTarget, setMode } from '../../../redux/slices/forms.slice';

const DURATION_OPTIONS = [
  { id: 'duration-1', value: '15', label: '15 min' },
  { id: 'duration-2', value: '30', label: '30 min' },
  { id: 'duration-3', value: '45', label: '45 min' },
  { id: 'duration-4', value: '60', label: '1 hora' },
  { id: 'duration-5', value: '75', label: '1.15 min' },
  { id: 'duration-6', value: '90', label: '1.30 min' },
  { id: 'duration-7', value: '105', label: '1.45 min' },
  { id: 'duration-8', value: '120', label: '2 horas' },
  { id: 'duration-9', value: '135', label: '2.15 min' },
  { id: 'duration-10', value: '150', label: '2.30 min' },
  { id: 'duration-11', value: '165', label: '2.45 min' },
  { id: 'duration-12', value: '180', label: '3 horas' },
  { id: 'duration-13', value: '195', label: '3.15 min' },
  { id: 'duration-14', value: '210', label: '3.30 min' },
  { id: 'duration-15', value: '225', label: '3.45 min' },
  { id: 'duration-16', value: '240', label: '4 horas' },
  { id: 'duration-17', value: '255', label: '4.15 min' },
  { id: 'duration-18', value: '270', label: '4.30 min' },
  { id: 'duration-19', value: '285', label: '4.45 min' },
  { id: 'duration-20', value: '300', label: '5 horas' },
];

const AddServiceForm = ({ editableData, handleVisibility }) => {
  const [modes, setModes] = useState([]);
  const [services, setServices] = useState([]);
  const [hasError, setHasError] = useState(false);

  const nutritionistServices = useSelector(
    state => state.user.nutritionistServices,
  );

  const { services: activeServices } = useSelector(
    nutritionistServicesSelector,
  );

  const { mutate: updateProfile, isLoading } = usePatchProfile();

  const { control, handleSubmit, watch, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      service: editableData?.service ?? '',
      mode: editableData?.mode ?? ['online'],
      price: editableData?.price ?? 0,
      minutesLenght: editableData?.minutesLenght ?? '15',
      description: editableData?.description ?? '',
    },
  });

  const dispatch = useDispatch();

  const classes = useStyles();

  const [serviceWatcher] = watch(['service', 'minutesLenght']);

  const onSubmitHandler = handleSubmit(data => {
    let services;
    if (!isEmpty(editableData)) {
      services = [
        ...activeServices
          .filter(service => service._id !== editableData._id)
          .map(({ _id, ...rest }) => ({ _id, ...rest })),
        { ...data, isPricePublic: true },
      ];
    } else {
      services = [
        ...activeServices.map(({ _id, ...rest }) => ({ _id, ...rest })),
        { ...data, isPricePublic: true },
      ];
    }
    const payload = {
      nutritionistProfile: {
        services,
      },
    };
    batch(() => {
      dispatch(setEditableTarget({}));
      dispatch(setMode('normal'));
      handleVisibility(false);
    });
    updateProfile(payload);
    reset();
  });

  useEffect(() => {
    const services = nutritionistServices.map(item => ({
      id: item._id,
      value: item._id,
      label: item.name,
      ...item,
    }));
    const modes = [
      {
        id: 'mode-1',
        label: 'Online',
        value: 'online',
        checked: editableData?.mode?.includes('online') ?? false,
      },
      {
        id: 'mode-2',
        label: 'Presencial',
        value: 'site',
        checked: editableData?.mode?.includes('site') ?? false,
      },
      {
        id: 'mode-3',
        label: 'A domicilio',
        value: 'home',
        checked: editableData?.mode?.includes('home'),
      },
    ];
    setServices(services);
    setModes(modes);
  }, [nutritionistServices, editableData]);

  useEffect(() => {
    if (
      !isEmpty(serviceWatcher) &&
      activeServices.some(item => item.service === serviceWatcher)
    ) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [serviceWatcher, activeServices]);

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Tipo de servicio *
          </Typography>
          <Typography className={classes.subtitle}>
            ¿Qué tipos de servicios ofreces?
          </Typography>
          <Box mt={2}>
            <WellnubSelectInput
              fullWidth
              variant="outlined"
              label="Nombre del servicio"
              options={services}
              name="service"
              control={control}
            />
          </Box>
          {hasError && isEmpty(editableData) && (
            <Typography variant="caption" style={{ color: 'red' }}>
              Este servicio ya está registrado
            </Typography>
          )}
        </Grid>

        {/* ---- MODALIDAD ---- */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Modalidad *
          </Typography>
          <Typography className={classes.subtitle}>
            Señala todas la modalidades en las que está disponible este servicio
          </Typography>
          <Box mt={2}>
            <WellnubCheckboxGroupInput
              row
              control={control}
              name="mode"
              options={modes}
            />
          </Box>
        </Grid>

        {/* ---- PRECIO ---- */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Precio *
          </Typography>
          <Typography className={classes.subtitle}>
            MXN incluyendo IVA
          </Typography>
          <Box mt={2}>
            <Controller
              name="price"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Box>
                  <NumberFormat
                    fullWidth
                    thousandSeparator
                    prefix={'$'}
                    color="primary"
                    variant="outlined"
                    value={value}
                    inputProps={{
                      min: 0,
                    }}
                    customInput={TextField}
                    error={Boolean(error)}
                    onValueChange={e => onChange(e.value)}
                  />
                  <FormHelperText style={{ color: 'red' }}>
                    {error?.message}
                  </FormHelperText>
                </Box>
              )}
            />
          </Box>
        </Grid>

        {/* ---- DURACIÓN ---- */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.title}>
            Duración *
          </Typography>
          <Typography className={classes.subtitle}>
            Duración establecida en minutos
          </Typography>
          <Box mt={2}>
            <WellnubSelectInput
              fullWidth
              control={control}
              name="minutesLenght"
              label="Duración"
              variant="outlined"
              options={DURATION_OPTIONS}
            />
          </Box>
        </Grid>

        {/* ---- DESCRIPCIÓN ---- */}
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            Breve descripción del servicio
          </Typography>
          <Typography className={classes.subtitle}>Opcional</Typography>
          <Box mt={2}>
            <WellnubTextInput
              multiline
              fullWidth
              name="description"
              control={control}
              label="Descripción"
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>

      <Box pt={2} textAlign="right">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading || (hasError && isEmpty(editableData))}
        >
          Guardar
        </Button>
      </Box>
    </form>
  );
};

export default AddServiceForm;
