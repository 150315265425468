import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay, add, set } from 'date-fns';
import { es } from 'date-fns/locale';
import DatePicker from 'react-datepicker';

import { isEmpty } from 'lodash';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { setModalStatus } from 'redux/slices/modal.slice';

import { setCalendarEvent } from 'redux/slices/calendarEvents/calendarEvents.slice';
import { usePatients } from 'hooks/patient.hook';
import {
  useNutritionist,
  useGetServices,
  useGetNutritionistOffices,
  useGetLinkRequests,
  useGetAppointmentCounters,
} from 'hooks/nutritionist.hook';

import {
  CALENDAR_EVENT_ACTION_TYPES,
  VIRTUAL_AGENDA_RESTRICTIONS,
} from 'utils/constants';

import CalendarEventWrapper from '../../components/CalendarEvent/CalendarEvent.component';

import { useStyles } from './VirtualAgenda.styles';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { NavLink } from 'react-router-dom';
import {
  nutritionistOfficesScheduleSelector,
  nutritionistOfficesSelector,
  nutritionistServicesSelector,
} from '../../redux/slices/user.selector';
import {
  virtualAgendaAppointmentsSelector,
  virtualAgendaRestrictionSelector,
} from '../../redux/slices/virtualAgenda/virtualAgenda.selector';

const VirtualAgendaPage = () => {
  const [date, setDate] = useState(new Date());
  const [businessDays, setBusinessDays] = useState([]);

  const { isLoading, isError } = usePatients();

  const { isLoading: isLoadingLinkRequest, isError: isErrorOnLinkRequest } =
    useGetLinkRequests();

  const {
    isLoading: isLoadingCounters,
    isError: isErrorCounters,
    data: counters,
  } = useGetAppointmentCounters();

  const { isLoading: isLoadingOffices, isError: isErrorOnOffices } =
    useGetNutritionistOffices();

  const { isLoading: isLoadingNutritionist, isError: isErrorInNutritionist } =
    useNutritionist(5);

  const { isLoading: isLoadingServices, isError: isErrorInServices } =
    useGetServices();

  const appointments = useSelector(virtualAgendaAppointmentsSelector);
  const { services } = useSelector(nutritionistServicesSelector);
  const offices = useSelector(nutritionistOfficesSelector);
  const schedules = useSelector(nutritionistOfficesScheduleSelector);
  const virtualAgendaRestritcion = useSelector(
    virtualAgendaRestrictionSelector,
  );

  const classes = useStyles({
    withNotification: counters?.new || counters?.mine,
  });

  const dispatch = useDispatch();

  const locales = {
    es,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const onAddNewEventHandler = () => {
    if (isEmpty(services)) {
      dispatch(
        setModalStatus({
          name: 'NO_SERVICES_WARNING',
          isActive: true,
        }),
      );
      return;
    }
    if (isEmpty(offices)) {
      dispatch(
        setModalStatus({
          name: 'NO_OFFICES_WARNING',
          isActive: true,
        }),
      );
      return;
    }
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'CALENDAR_EVENT',
          isActive: true,
        }),
      );
      dispatch(
        setCalendarEvent({
          action: CALENDAR_EVENT_ACTION_TYPES.CREATE,
          title: 'Nuevo Evento',
          start: set(new Date(), { hours: 8, minutes: 0 }).toString(),
          end: set(new Date(), { hours: 8, minutes: 0 }).toString(),
        }),
      );
    });
  };

  const onSelectEventHandler = useCallback(
    event => {
      const { startDate, endDate, ...rest } = event;
      if (isEmpty(services)) {
        dispatch(
          setModalStatus({
            name: 'NO_SERVICES_WARNING',
            isActive: true,
          }),
        );
        return;
      }
      if (isEmpty(offices)) {
        dispatch(
          setModalStatus({
            name: 'NO_OFFICES_WARNING',
            isActive: true,
          }),
        );
        return;
      }
      batch(() => {
        dispatch(
          setModalStatus({
            name: 'CALENDAR_EVENT',
            isActive: true,
          }),
        );
        dispatch(
          setCalendarEvent({
            ...rest,
            action: CALENDAR_EVENT_ACTION_TYPES.EDIT,
            start: event.startDate.toString(),
            end: event.endDate
              ? event.endDate.toString()
              : add(new Date(event.startDate), { hours: 1 }).toString(),
          }),
        );
      });
    },
    [dispatch, offices, services],
  );

  const onSelectSlotHandler = useCallback(
    event => {
      if (virtualAgendaRestritcion === VIRTUAL_AGENDA_RESTRICTIONS.MID) {
        dispatch(
          setModalStatus({
            name: 'VIRTUAL_AGENDA_RESTRICTION_WARNING',
            isActive: true,
          }),
        );
      } else {
        if (isEmpty(services)) {
          dispatch(
            setModalStatus({
              name: 'NO_SERVICES_WARNING',
              isActive: true,
            }),
          );
          return;
        }
        if (isEmpty(offices)) {
          dispatch(
            setModalStatus({
              name: 'NO_OFFICES_WARNING',
              isActive: true,
            }),
          );
          return;
        }
        batch(() => {
          dispatch(
            setModalStatus({
              name: 'CALENDAR_EVENT',
              isActive: true,
            }),
          );
          dispatch(
            setCalendarEvent({
              action: CALENDAR_EVENT_ACTION_TYPES.CREATE,
              title: 'Nuevo Evento',
              start: set(new Date(event.start), {
                hours: 8,
                minutes: 0,
              }).toString(),
              end: set(new Date(event.end), {
                hours: 8,
                minutes: 0,
              }).toString(),
            }),
          );
        });
      }
    },
    [dispatch, services, offices],
  );

  const getNow = useCallback(() => date, [date]);

  const onSelectDateHandler = date => {
    setDate(date);
  };

  const dayPropGetter = useCallback(
    date => {
      const day = new Date(date).getDay();
      return {
        style: {
          backgroundColor: !businessDays.includes(day) ? '#f0f0f0' : 'initial',
        },
      };
    },
    [businessDays],
  );

  useEffect(() => {
    if (!isEmpty(schedules)) {
      const businessDays = schedules.reduce((accumulator, current) => {
        accumulator = [...accumulator, ...current.schedule.days];
        return accumulator;
      }, []);
      setBusinessDays([...new Set(businessDays)]);
    }
  }, [schedules]);

  if (
    isLoading ||
    isLoadingNutritionist ||
    isLoadingServices ||
    isLoadingOffices ||
    isLoadingLinkRequest ||
    isLoadingCounters
  ) {
    return (
      <Box mt={4} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (
    isError ||
    isErrorInNutritionist ||
    isErrorInServices ||
    isErrorOnOffices ||
    isErrorOnLinkRequest ||
    isErrorCounters
  ) {
    return (
      <Box mt={4} display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6">
          No se pudo cargar la información, intenta más tarde
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        variant="outlined"
        onClick={onAddNewEventHandler}
        disabled={virtualAgendaRestritcion === VIRTUAL_AGENDA_RESTRICTIONS.MID}
      >
        Agregar nuevo evento
      </Button>
      <Box display="flex" alignItems="center" justifyContent="end">
        <Box display="flex" alignItems="center">
          <Typography
            variant="h6"
            style={{ color: '#ff4262', paddingRight: 32 }}
          >
            ESTÁS UTILIZANDO LA VERSIÓN BETA DE LA AGENDA VIRTUAL
          </Typography>
          <Box
            style={{
              backgroundColor: '#377990',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
            }}
          ></Box>
          <Typography variant="body2" style={{ marginLeft: '10px' }}>
            Online
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" pl={4}>
          <Box
            style={{
              backgroundColor: '#409037',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
            }}
          ></Box>
          <Typography variant="body2" style={{ marginLeft: '10px' }}>
            Presencial/A domicilio
          </Typography>
        </Box>
      </Box>
      <Grid container style={{ marginTop: 32 }}>
        <Grid item xs={12} sm={3}>
          <DatePicker inline locale={es} onChange={onSelectDateHandler} />
          <Box my={4} textAlign="center">
            <Typography variant="h6" className={classes.withNotification}>
              Citas por confirmar
            </Typography>
            <Typography variant="body2">
              Mis pacientes: {counters.mine}
            </Typography>
            <Typography variant="body2">
              Pacientes nuevos: {counters.new}
            </Typography>
          </Box>
          <Box textAlign="center">
            {virtualAgendaRestritcion !== VIRTUAL_AGENDA_RESTRICTIONS.MID && (
              <Typography
                component={NavLink}
                to="/settings/virtual-agenda/availability"
                variant="h6"
                color="primary"
                style={{ textDecoration: 'underline' }}
              >
                Ir a configuración de agenda
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Calendar
            selectable
            getNow={getNow}
            culture="es"
            localizer={localizer}
            style={{ height: '60vh' }}
            startAccessor="start"
            defaultView="week"
            endAccessor="end"
            dayPropGetter={dayPropGetter}
            onSelectEvent={onSelectEventHandler}
            onSelectSlot={onSelectSlotHandler}
            messages={{
              week: 'Semana',
              work_week: 'Semana de trabajo',
              day: 'Día',
              month: 'Mes',
              previous: 'Anterior',
              next: 'Siguiente',
              today: 'Hoy',
              agenda: 'Agenda',
              date: 'Fecha',
              time: 'Horario',
              event: 'Evento',
              showMore: total => `+${total} más`,
            }}
            events={appointments
              .filter(item =>
                ['pending', 'accepted', 'rescheduled'].includes(item.status),
              )
              .map(event => ({
                ...event,
                type: event.type,
                comment: event?.comment ?? '',
                title:
                  event.type === 'other'
                    ? event?.title ?? 'Evento'
                    : `${event.patient?.name} ${event.patient?.lastName}`,
                start: event.startDate ? new Date(event.startDate) : new Date(),
                end: event.endDate
                  ? new Date(event.endDate)
                  : add(new Date(event.startDate), { hours: 1 }),
              }))}
            components={{
              eventWrapper: CalendarEventWrapper,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VirtualAgendaPage;
