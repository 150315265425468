import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(4),
    color: theme.palette.primary.light,
  },
  subtitle: {
    fontSize: 12,
    marginBottom: theme.spacing(2),
  },
  subtitleSpecial: {
    margin: 0,
  },
  formSection: {
    paddingBottom: theme.spacing(4),
  },
  titleNoSpace: {
    textTransform: 'uppercase',
    color: theme.palette.primary.light,
  },
  lowercase: {
    textTransform: 'lowercase',
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  modes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  mode: {
    width: 25,
  },
  modeWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  action: {
    fontWeight: 'bold',
    color: theme.palette.action.main,
  },
  wrapper: {
    position: 'relative',
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.light,
  },
}));
