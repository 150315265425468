import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useDeleteNutritionistOfficeSchedule } from 'hooks/nutritionist.hook';
import {isEmpty} from "lodash";
import {format} from "date-fns";
import {es} from "date-fns/locale";

const DeleteNutritionistOfficeSchedulePanel = () => {
  const { target } = useSelector(state => state.forms.profile);
  const { patients } = useSelector(state => state.affectedPatients);

  const dispatch = useDispatch();

  const { mutate: deleteSchedule, isLoading } =
    useDeleteNutritionistOfficeSchedule();

  const onAcceptHandler = () => {
    deleteSchedule(target._id);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'DELETE_NUTRITIONIST_OFFICE_SCHEDULE',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="h6" align="center" style={{marginBottom: 8}}>¿Estás seguro que deseas eliminar este horario?</Typography>
      {!isEmpty(patients)
        && (
          <>
            <Typography variant="body2" align="center" style={{marginBottom: 8}}>Estos pacientes ya agendaron una consulta y se encuentran fuera de tus nuevos horarios de consulta</Typography>
            <Typography variant="body2" align="center" style={{marginBottom: 8}}>Las consultas no se modificarán, por favor comunícate directamente con ellos si quieres reagendar</Typography>
            
            {patients.map(patient => (
              <Typography variant="body2" align="center" key={patient._id} style={{marginBottom: 4}}>
                {patient.patient.name} {patient.patient.lastName} - {patient.office.name} - {format(
                new Date(patient?.startDate ?? null),
                "dd/MM/yyyy",
                {
                  locale: es,
                },
              )}
              </Typography>
            ))}
          </>
        )}
      
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Confirmar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteNutritionistOfficeSchedulePanel;