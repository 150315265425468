import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import { nutritionistOfficesSelector } from 'redux/slices/user.selector';

import {
  WellnubTimeInput,
  WellnubCheckboxGroupInput,
} from '@wellnub/web-common';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreateNutritionistOfficeSchedule,
  useUpdateNutritionistOfficeSchedule,
} from 'hooks/nutritionist.hook';

import { FormSchema } from './AddOpeningHouOfficeScheduleForm.schema';
import { isEmpty } from 'lodash';

const DAYS_OPTIONS = [
  {
    id: 'DAYS-1',
    label: 'Lu',
    value: 1,
  },
  {
    id: 'DAYS-2',
    label: 'Ma',
    value: 2,
  },
  {
    id: 'DAYS-3',
    label: 'Mi',
    value: 3,
  },
  {
    id: 'DAYS-4',
    label: 'Ju',
    value: 4,
  },
  {
    id: 'DAYS-5',
    label: 'Vi',
    value: 5,
  },
  {
    id: 'DAYS-6',
    label: 'Sa',
    value: 6,
  },
  {
    id: 'DAYS-7',
    label: 'Do',
    value: 0,
  },
];

const AddOpeningHourOfficeScheduleForm = ({
  mode = 'CREATE',
  schedule = {},
}) => {
  const [officesOptions, setOfficesOptions] = useState([]);
  const [isMultipleOnsiteSelected, setIsMultipleOnsiteSelected] =
    useState(false);

  const offices = useSelector(nutritionistOfficesSelector);

  const dispatch = useDispatch();

  const { mutate: createSchedule, isLoading } =
    useCreateNutritionistOfficeSchedule();

  const { mutate: updateSchedule, isLoading: isLoadingUpdate } =
    useUpdateNutritionistOfficeSchedule();

  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      days: schedule?.schedule?.days ?? [],
      offices: schedule?.offices ?? [],
      startTime: new Date(
        2022,
        1,
        1,
        Number(schedule?.schedule?.startTime?.split(':')?.[0] ?? 1),
        Number(schedule?.schedule?.startTime?.split(':')?.[1] ?? 0),
        0,
      ),
      endTime: new Date(
        2022,
        1,
        1,
        Number(schedule?.schedule?.endTime?.split(':')?.[0] ?? 2),
        Number(schedule?.schedule?.endTime?.split(':')?.[1] ?? 0),
        0,
      ),
      duration: '15',
    },
  });

  const [officesWatcher] = watch(['offices']);

  const onSubmitHandler = handleSubmit(data => {
    const payload = {
      schedule: {
        days: data.days,
        startTime: format(data.startTime, 'HH:mm'),
        endTime: format(data.endTime, 'HH:mm'),
      },
      offices: data.offices,
    };
    if (mode === 'CREATE') {
      createSchedule(payload);
      return;
    }
    if (mode === 'UPDATE') {
      updateSchedule({
        id: schedule?._id ?? '',
        data: payload,
      });
    }
  });

  useEffect(() => {
    const options = offices.map(item => ({
      id: item._id,
      label: `${item.name} - ${
        { online: 'En línea', onsite: 'Presencial', home: 'A domicilio' }[
          item.mode
        ]
      }`,
      mode: item.mode,
      value: item._id,
    }));
    setOfficesOptions(options);
  }, [offices]);

  useEffect(() => {
    if (!isEmpty(officesWatcher)) {
      const selectedOffices = officesWatcher
        .map(item => officesOptions.find(el => el.id === item) ?? null)
        .filter(item => item?.mode === 'onsite');
      setIsMultipleOnsiteSelected(selectedOffices.length > 1);
    }
  }, [officesWatcher, officesOptions]);

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <WellnubTimeInput
            fullWidth
            control={control}
            name="startTime"
            label="Hora inicio"
            inputVariant="outlined"
            keyboardIcon={<AlarmIcon />}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <WellnubTimeInput
            fullWidth
            control={control}
            name="endTime"
            label="Hora fin"
            inputVariant="outlined"
            minutesStep={15}
            keyboardIcon={<AlarmIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" align="center">
            Días de la semana
          </Typography>
          <WellnubCheckboxGroupInput
            row
            name="days"
            control={control}
            options={DAYS_OPTIONS}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant="h6">Consultorios</Typography>
        <WellnubCheckboxGroupInput
          row
          name="offices"
          control={control}
          options={officesOptions}
        />
        {isMultipleOnsiteSelected && (
          <Box>
            <Typography variant="caption" style={{ color: 'red' }}>
              No se puede seleccionar más de un consultorio presencial.
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={isLoading || isMultipleOnsiteSelected || isLoadingUpdate}
        >
          {mode === 'CREATE' && 'Guardar'}
          {mode === 'UPDATE' && 'Actualizar'}
        </Button>
      </Box>
    </form>
  );
};

export default AddOpeningHourOfficeScheduleForm;
