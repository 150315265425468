import { batch, useDispatch } from 'react-redux';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setEditableTarget } from 'redux/slices/forms.slice';
import EditIcon from '@material-ui/icons/Edit';
import { setWnUserEditableOfficeHours } from '../../../redux/slices/user/user.slice';

const OfficeScheduleDaysOffRow = ({ dayOff }) => {
  const dispatch = useDispatch();

  const onDeleteHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'DELETE_NUTRITIONIST_OFFICE_DAY_OFF',
          isActive: true,
        }),
      );
      dispatch(setEditableTarget(dayOff));
    });
  };

  const onEditHandler = () => {
    batch(() => {
      dispatch(setEditableTarget(dayOff));
      dispatch(
        setModalStatus({
          name: 'EDIT_NUTRITIONIST_DAYS_OFF',
          isActive: true,
        }),
      );
    });
  };

  return (
    <TableRow>
      {/* ---- ACCIONES ----- */}
      <TableCell align="center">
        <Tooltip title="Eliminar">
          <IconButton size="small" onClick={onDeleteHandler}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar">
          <IconButton size="small" onClick={onEditHandler}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>

      {/* ---- HORARIO ----- */}
      <TableCell align="center">
        <Typography variant="caption">
          {format(new Date(dayOff.startDate), 'p')} -{' '}
          {format(new Date(dayOff.endDate), 'p')}
        </Typography>
      </TableCell>

      {/* ---- DÍA ----- */}
      <TableCell align="center">
        <Typography variant="caption">
          {format(new Date(dayOff.startDate), 'PP', { locale: es })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default OfficeScheduleDaysOffRow;
