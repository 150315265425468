import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  row: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  modes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 25,
    marginLeft: theme.spacing(1),
  },
}));
