import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(2),
  },
  limit: {
    maxWidth: '50ch',
  },
}));
