import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.primary.light}`,
  },
  tab: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.primary.main,
    wordWrap: 'break-word',
  },
}));
