import Slider from 'react-slick';
import { useSelector } from 'react-redux';

import TestimonialCard from '../../Cards/Testimonial/TestimonialCard.component';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TestimonialCarrousel = () => {
  const { testimonials } = useSelector(state => state.testimonials);

  return (
    <Slider {...settings}>
      {testimonials.map(testimonial => (
        <TestimonialCard key={testimonial.name} testimonial={testimonial} />
      ))}
    </Slider>
  );
};

export default TestimonialCarrousel;
