import { date, object } from 'yup';

export const FORM_SCHEMA = object({
  startTime: date(),
  endTime: date().when(
    'startTime',
    (startTime, schema) =>
      startTime &&
      schema.min(
        startTime,
        'La hora de fin no puede ser menor a la hora de inicio',
      ),
  ),
  day: date(),
});
