import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  logo: {
    width: 120,
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  navbar: {
    paddingLeft: '2rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  gap: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
}));
