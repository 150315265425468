import { useDispatch } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import { useUnsubscribe } from 'hooks/payments.hook';

import { setModalStatus } from 'redux/slices/modal.slice';

import Button from '../../Button/Button.component';

const UnsubscribeForm = () => {
  const { mutate, isLoading, isSuccess } = useUnsubscribe();

  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    mutate();
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'UNSUBSCRIBE',
        isActive: false,
      }),
    );
  };

  if (isSuccess) {
    dispatch(
      setModalStatus({
        name: 'UNSUBSCRIBE',
        isActive: false,
      }),
    );
  }

  return (
    <Box>
      <Typography>¿Confirmas que deseas cancelar tu suscripción?</Typography>
      <Box pt={2}>
        <Typography>
          Tus slots de pacientes actuales estarán disponibles hasta tu próxima
          fecha de corte.{' '}
        </Typography>
        <Typography>
          A partir de hoy se suspenderán los cargos mensuales a tu tarjeta.
        </Typography>
      </Box>
      <Box
        pt={2}
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={onConfirmHandler}
        >
          Confirmar
        </Button>
        <Box ml={2}>
          <Button variant="outlined" color="primary" onClick={onCancelHandler}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UnsubscribeForm;
