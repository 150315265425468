import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import {
  nutritionistOfficesScheduleSelector,
  nutritionistScheduleSettingsSelector,
} from 'redux/slices/user.selector';
import { isEmpty } from 'lodash';

const CompleteVirtualAgendaSettingsWarningPanel = () => {
  const nutritionistSchedule = useSelector(nutritionistOfficesScheduleSelector);
  const nutritionistSettings = useSelector(
    nutritionistScheduleSettingsSelector,
  );

  const { push } = useHistory();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: 'COMPLETE_VIRTUAL_AGENDA_SETTINGS_WARNING',
        isActive: false,
      }),
    );
    setTimeout(() => {
      if (Object.values(nutritionistSettings).some(value => value === null)) {
        push('/settings/virtual-agenda/availability');
        return;
      }
      if (isEmpty(nutritionistSchedule)) {
        push('/settings/virtual-agenda/office-hours');
      }
    }, 500);
  };

  return (
    <Box>
      <Typography align="center" variant="h5">
        ¡Agenda Virtual!
      </Typography>
      <br />
      <Typography align="center">
        Por favor configura todas estas secciones para que puedas disfrutar de
        la agenda virtual que ofrece tu plan ilimitado Wellnub
      </Typography>
      <br />
      <Box textAlign="center">
        <Button variant="contained" color="primary" onClick={onAcceptHandler}>
          Entiendo
        </Button>
      </Box>
    </Box>
  );
};

export default CompleteVirtualAgendaSettingsWarningPanel;
