import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { date, object, string } from 'yup';
import { set } from 'date-fns';
import { isEmpty } from 'lodash';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  WellnubSelectInput,
  WellnubDateInput,
  WellnubTimeInput,
} from '@wellnub/web-common';
import {
  nutritionistOfficesSelector,
  nutritionistServicesSelector,
} from 'redux/slices/user.selector';
import { useProposeNewDateForAppointment } from 'hooks/nutritionist.hook';

const ERROR_MESSAGE = 'Este campo es obligatorio';

const FormSchema = object({
  service: string().required(ERROR_MESSAGE),
  mode: string().required(ERROR_MESSAGE),
  date: date().required(ERROR_MESSAGE),
  startTime: date().required(ERROR_MESSAGE),
  endTime: date()
    .default(null)
    .when('startTime', (startTime, yup) =>
      yup.min(
        startTime,
        'La hora de fin no puede ser menor a la hora de inicio',
      ),
    )
    .required(ERROR_MESSAGE),
  office: string().required(ERROR_MESSAGE),
});

const ProposeNewDateForCalendarEventPanel = () => {
  const [serviceOptions, setServiceOptions] = useState([]);
  const [modeOptions, setModeOptions] = useState([]);
  const [officeOptions, setOfficeOptions] = useState([]);

  const { editableEvent } = useSelector(state => state.virtualAgenda);
  const { services } = useSelector(nutritionistServicesSelector);
  const offices = useSelector(nutritionistOfficesSelector);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      service: editableEvent.service._id,
      mode: editableEvent.mode,
      date: new Date(editableEvent.start),
      startTime: new Date(editableEvent.start),
      endTime: new Date(editableEvent.end),
      office: editableEvent.office._id,
    },
    resolver: yupResolver(FormSchema),
  });

  const { mutate: proposeNewAppointment, isLoading: isProposing } =
    useProposeNewDateForAppointment();

  const [serviceWatcher] = watch(['service']);

  const onSubmitHandler = handleSubmit(data => {
    const { date, startTime, endTime, ...restData } = data;
    const payload = {
      ...restData,
      startDate: set(date, {
        hours: startTime.getHours(),
        minutes: startTime.getMinutes(),
        seconds: 0,
      }),
    };
    proposeNewAppointment({
      id: editableEvent._id,
      data: payload,
    });
    // console.log('El payload es: ', { payload, id: editableEvent._id });
  });

  useEffect(() => {
    if (!isEmpty(services)) {
      const options = services.map(item => ({
        ...item,
        id: item._id,
        value: item._id,
        label: item.name,
      }));
      setServiceOptions(options);
    }
  }, [services]);

  useEffect(() => {
    if (!isEmpty(serviceWatcher) && !isEmpty(services)) {
      const options =
        services
          .find(item => item._id === serviceWatcher)
          ?.mode?.map(item => ({
            id: item,
            value: item,
            label: {
              online: 'En línea',
              site: 'Presencial',
              home: 'A domicilio',
            }[item],
          })) ?? [];
      setModeOptions(options);
    }
  }, [serviceWatcher, services]);

  useEffect(() => {
    if (!isEmpty(offices)) {
      const options = offices.map(item => ({
        ...item,
        id: item._id,
        value: item._id,
        label: item.name,
      }));
      setOfficeOptions(options);
    }
  }, [offices]);

  return (
    <form onSubmit={onSubmitHandler}>
      <Box mb={2}>
        <Typography variant="h6">Datos de la cita</Typography>
        <Grid container spacing={2} style={{ marginTop: '8px' }}>
          <Grid item xs={12} sm={6}>
            <WellnubSelectInput
              fullWidth
              variant="outlined"
              name="service"
              label="Servicio"
              control={control}
              options={serviceOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubSelectInput
              fullWidth
              variant="outlined"
              name="mode"
              label="Modalidad"
              control={control}
              options={modeOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubDateInput
              fullWidth
              name="date"
              inputVariant="outlined"
              label="Fecha"
              format="dd/MM/yyyy"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <WellnubTimeInput
                  fullWidth
                  name="startTime"
                  label="Hora de inicio"
                  control={control}
                  inputVariant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WellnubTimeInput
                  fullWidth
                  name="endTime"
                  label="Hora de fin"
                  control={control}
                  inputVariant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <WellnubSelectInput
              fullWidth
              name="office"
              variant="outlined"
              label="Consultorio"
              control={control}
              options={officeOptions}
            />
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isProposing}
        >
          Proponer actualización
        </Button>
      </Box>
    </form>
  );
};

export default ProposeNewDateForCalendarEventPanel;
