import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    paddingBottom: theme.spacing(4),
    textTransform: 'uppercase',
    color: theme.palette.primary.light,
  },
  sectionTitleNoSpace: {
    textTransform: 'uppercase',
    color: theme.palette.primary.light,
  },
}));
