import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useForm, Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { isEmpty } from 'lodash';
import {
  set,
  isBefore,
  getHours,
  getMinutes,
  isEqual,
  format,
  add,
  areIntervalsOverlapping,
  isAfter,
} from 'date-fns';
import {
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Typography,
  Radio,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  FormHelperText,
  Checkbox,
  DialogTitle,
  DialogContent,
  Dialog,
  useMediaQuery,
  ListSubheader,
} from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import { es, fil } from 'date-fns/locale';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';

import { WellnubSelectInput, WellnubTextInput } from '@wellnub/web-common';

import {
  getActivePatientsSelector,
  getInactivePatientsSelector,
} from 'redux/slices/patients/patients.selector';

import {
  useAcceptCalendarEvent,
  useCreateCalendarEvent,
  useDeleteCalendarEvent,
  useUpdateCalendarEvent,
} from 'hooks/nutritionist.hook';

import { CALENDAR_EVENT_ACTION_TYPES } from 'utils/constants';
import CloseIcon from '@material-ui/icons/Close';
import { nutritionistOfficesSelector } from 'redux/slices/user.selector';
import { setModalStatus } from 'redux/slices/modal.slice';
import {
  setEditableEvent,
  setPendingAppointmentAfterCreatePatient,
} from 'redux/slices/virtualAgenda/virtualAgenda.slice';

import PatientVerificationForm from '../Patient/Verification/PatientVerificationForm.component';
import {
  setIsAddedFromVirtualAgenda,
  setPatientDataForRegister,
} from '../../../redux/slices/patient.slice';

const useStyles = makeStyles(theme => ({
  menuPaper: { maxHeight: 200 },
  header: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    textTransform: 'uppercase',
    flexGrow: 1,
  },
  dialog: {
    maxWidth: '900px',
    zIndex: 100,
  },
}));

const CalendarEventForm = ({ event }) => {
  const [officeOptions, setOfficeOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [activeService, setActiveService] = useState({});
  // Variants: NORMAL | PENDING_BY_NUTRITIONIST | PENDING_BY_PATIENT | ACCEPTED
  const [modalVariant, setModalVariant] = useState('NORMAL');
  const [
    isInactivePatientModalWarningOpen,
    setIsInactivePatientModalWarningOpen,
  ] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [appointmentDates, setAppointmentDates] = useState([]);
  const [areIntervalsOverlaping, setAreIntervalsOverlaping] = useState(false);

  const patients = useSelector(getActivePatientsSelector);
  const inactivePatients = useSelector(getInactivePatientsSelector);
  const { data: pendingPatients } = useSelector(state => state.invitations);

  const { profile } = useSelector(state => state.user);

  const { data: services } = useSelector(state => state.serviceList);

  const offices = useSelector(nutritionistOfficesSelector);

  const { events: appointments } = useSelector(state => state.virtualAgenda);

  const { data, mode } = useSelector(
    state => state.virtualAgenda.pendingAppointmentAfterCreatePatient,
  );

  const { mutate: createCalendarEvent, isLoading: isLoadingCreate } =
    useCreateCalendarEvent();

  const { mutate: acceptCalendarEvent, isLoading: isAcceptingEvent } =
    useAcceptCalendarEvent();

  const { mutate: deleteCalendarEvent, isLoading: isLoadingDelete } =
    useDeleteCalendarEvent();

  const { mutate: updateCalendarEvent, isLoading: isLoadingUpdate } =
    useUpdateCalendarEvent();

  const isCreateEvent = event.action === CALENDAR_EVENT_ACTION_TYPES.CREATE;

  const isExtraSmallSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const { control, handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: {
      title:
        mode === 'persist' ? data?.title : isCreateEvent ? '' : event.title,
      isAllDay: false,
      type:
        mode === 'persist'
          ? data?.type
          : isCreateEvent
          ? 'patient'
          : event.type,
      service:
        mode === 'persist'
          ? data?.service
          : isCreateEvent
          ? ''
          : event?.service?.service?._id ?? '',
      mode: mode === 'persist' ? data?.mode : isCreateEvent ? '' : event.mode,
      date: mode === 'persist' ? new Date(data?.date) : new Date(event.start),
      comment:
        mode === 'persist' ? data?.comment : isCreateEvent ? '' : event.comment,
      timeStart:
        mode === 'persist' ? new Date(data?.timeStart) : new Date(event.start),
      timeEnd:
        mode === 'persist' ? new Date(data?.timeEnd) : new Date(event.end),
      patient: isCreateEvent ? '' : event?.patient?._id,
      office:
        mode === 'persist'
          ? data?.office
          : isCreateEvent
          ? offices[0]?._id ?? ''
          : event?.office?._id ?? '',
    },
  });

  const classes = useStyles();

  const [
    serviceId,
    eventType,
    isAllDayWatcher,
    timeStartWarcher,
    dateWatcher,
    timeEndWatcher,
    patientWatcher,
    modeWatcher,
  ] = watch([
    'service',
    'type',
    'isAllDay',
    'timeStart',
    'date',
    'timeEnd',
    'patient',
    'mode',
  ]);

  const onSubmitHandler = handleSubmit(data => {
    const { type, service, mode, patient, comment, title, isAllDay, office } =
      data;
    if (inactivePatients.some(item => item._id === patient)) {
      setIsInactivePatientModalWarningOpen(true);
      return;
    }
    const startDate = set(data.date, {
      hours: isAllDay ? 0 : data.timeStart.getHours(),
      minutes: isAllDay ? 0 : data.timeStart.getMinutes(),
    });
    const endDate = set(data.date, {
      hours: isAllDay ? 23 : data.timeEnd.getHours(),
      minutes: isAllDay ? 59 : data.timeEnd.getMinutes(),
    });
    const patientName = patients.find(item => item._id === patient);
    const serviceId =
      serviceList.find(item => item.service === service)?._id ?? '';
    let payload;
    if (type === 'patient') {
      payload = {
        type,
        patient,
        service: serviceId,
        startDate,
        endDate,
        mode,
        comment,
        office,
        timeZone:
          profile?.nutritionistProfile?.settings?.timeZone ??
          Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }
    if (type === 'other') {
      payload = {
        type,
        comment,
        endDate,
        startDate,
        title,
        timeZone:
          profile?.nutritionistProfile?.settings?.timeZone ??
          Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }
    if (isCreateEvent) {
      createCalendarEvent(payload);
    } else {
      updateCalendarEvent({
        id: event._id,
        data: payload,
      });
    }
  });

  const onEditEventHandler = () => {
    setIsEditable(prevState => !prevState);
  };

  const onDeleteEventHandler = () => {
    deleteCalendarEvent(event._id);
  };

  const onAddPatientOpenHandler = () => {
    const { date, timeStart, timeEnd, ...restData } = getValues();
    batch(() => {
      dispatch(
        setPendingAppointmentAfterCreatePatient({
          mode: 'persist',
          data: {
            date: date.getTime(),
            timeStart: timeStart.getTime(),
            timeEnd: timeEnd.getTime(),
            ...restData,
          },
        }),
      );
      dispatch(
        setModalStatus({
          name: 'PATIENT_VERIFICATION',
          isActive: true,
        }),
      );
      dispatch(setIsAddedFromVirtualAgenda(true));
      dispatch(setPatientDataForRegister({}));
    });
  };

  const onConfirmAppointmentHandler = () => {
    acceptCalendarEvent(event._id);
  };

  const onOpenRejectWarningModalHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'REJECT_CALENDAR_EVENT_WARNING',
          isActive: true,
        }),
      );
      dispatch(setEditableEvent(event));
    });
  };

  useEffect(() => {
    if (event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT && !isEditable)
      return;
    if (serviceId && !isEmpty(profile)) {
      const services = profile.nutritionistProfile.services;
      const service = services.find(item => item.service === serviceId);
      if (service) {
        const endDate = add(timeStartWarcher, {
          minutes: service.minutesLenght,
        });
        setValue('timeEnd', endDate);
        setSelectedService(service);
      }
    }
  }, [serviceId, profile, getValues, setValue, timeStartWarcher]);

  useEffect(() => {
    if (!isEmpty(profile) && !isEmpty(services)) {
      const unmapped = profile.nutritionistProfile.services;
      const mapped = unmapped.map(service => {
        const target = services.find(item => item._id === service.service);
        return {
          ...service,
          id: target._id,
          label: target?.name,
          value: target._id,
        };
      });
      setServiceList(mapped);
    }
  }, [profile, services]);

  useEffect(() => {
    if (!isEmpty(serviceId) && !isEmpty(serviceList)) {
      const service = serviceList.find(service => service.id === serviceId);
      const mappedService = {
        ...service,
        modes:
          service?.mode?.map(mode => ({
            id: nanoid(),
            label: {
              online: 'Online',
              site: 'Presencial',
              home: 'A domicilio',
            }[mode],
            value: { online: 'online', site: 'onsite', home: 'home' }[mode],
          })) ?? [],
      };
      setActiveService(mappedService);
    }
  }, [serviceId, serviceList]);

  useEffect(() => {
    if (!isEmpty(event) && 'status' in event) {
      const { status, nextActionRole } = event;
      if (status === 'accepted') {
        setModalVariant('ACCEPTED');
        return;
      }
      if (
        ['pending', 'rescheduled'].includes(status) &&
        nextActionRole === 'client'
      ) {
        setModalVariant('PENDING_BY_PATIENT');
        return;
      }
      if (
        ['pending', 'rescheduled'].includes(status) &&
        nextActionRole === 'nutritionist'
      ) {
        setModalVariant('PENDING_BY_NUTRITIONIST');
      }
    }
  }, [event]);

  useEffect(() => {
    if (!isEmpty(appointments)) {
      const appointmentDates = appointments.map(appointment => ({
        start: new Date(appointment.startDate),
        end: new Date(appointment.endDate),
      }));
      setAppointmentDates(appointmentDates);
    }
  }, [appointments]);

  useEffect(() => {
    if (!isEmpty(appointmentDates)) {
      const start = set(dateWatcher, {
        hours: timeStartWarcher.getHours(),
        minutes: timeStartWarcher.getMinutes(),
      });
      const end = set(dateWatcher, {
        hours: timeEndWatcher.getHours(),
        minutes: timeEndWatcher.getMinutes(),
      });
      if (isAfter(end, start)) {
        const areSomeOverlapping = appointmentDates.some(interval =>
          areIntervalsOverlapping(
            { start: interval.start, end: interval.end },
            { start, end },
            { inclusive: true },
          ),
        );
        setAreIntervalsOverlaping(areSomeOverlapping);
      }
    }
  }, [dateWatcher, timeStartWarcher, timeEndWatcher, appointmentDates]);

  useEffect(() => {
    if (!isEmpty(modeWatcher)) {
      const filteredOffices = offices
        ?.filter(office => office.mode === modeWatcher)
        ?.map(office => ({
          id: office._id,
          value: office._id,
          label: office.name,
        }));
      setOfficeOptions(filteredOffices);
      setValue('office', filteredOffices?.[0]?.id ?? '');
    }
  }, [modeWatcher, offices, setValue]);

  return (
    <form onSubmit={onSubmitHandler}>
      {/*<Box textAlign="center">*/}
      {/*  <Button*/}
      {/*    variant="contained"*/}
      {/*    color="primary"*/}
      {/*    onClick={() => {*/}
      {/*      console.log(event);*/}
      {/*      console.log('Pacientes: ', [*/}
      {/*        ...patients,*/}
      {/*        ...pendingPatients.map(item => item.patient),*/}
      {/*      ]);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Show event*/}
      {/*  </Button>*/}
      {/*</Box>*/}
      <Box mb={2}>
        <Typography variant="h6">Tipo de cita</Typography>
        <Grid container spacing={2} style={{ marginTop: 8 }}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="type"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <RadioGroup row name="type" value={value} onChange={onChange}>
                  <FormControlLabel
                    value="patient"
                    label="Cita con paciente"
                    control={<Radio color="primary" />}
                    disabled={
                      event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                      !isEditable
                    }
                  />
                  <FormControlLabel
                    value="other"
                    label="Otra"
                    control={<Radio color="primary" />}
                    disabled={
                      event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                      !isEditable
                    }
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {['PENDING_BY_PATIENT', 'ACCEPTED'].includes(modalVariant) && (
              <Tooltip title={isEditable ? 'Cancelar' : 'Editar'}>
                <IconButton onClick={onEditEventHandler}>
                  {isEditable ? <CancelIcon /> : <EditIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        {/* **** SERVICIO ****  */}
        <Typography variant="h6">Datos de la cita</Typography>
        <Grid container spacing={2} style={{ marginTop: 8 }}>
          {eventType !== 'other' && (
            <Grid item xs={12} sm={6}>
              {['NORMAL', 'PENDING_BY_PATIENT', 'ACCEPTED'].includes(
                modalVariant,
              ) ? (
                <WellnubSelectInput
                  fullWidth
                  variant="outlined"
                  name="service"
                  rules={{
                    required: 'Este campo es obligatorio',
                  }}
                  label="Servicios"
                  control={control}
                  options={serviceList}
                  disabled={
                    event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                    !isEditable
                  }
                />
              ) : (
                <Typography variant="body2">
                  Servicio: {event.service?.service?.name ?? 'Mi servicio'}
                </Typography>
              )}
            </Grid>
          )}

          {/* **** MODALIDAD **** */}
          {eventType !== 'other' && (
            <Grid item xs={12} sm={6}>
              {['NORMAL', 'PENDING_BY_PATIENT', 'ACCEPTED'].includes(
                modalVariant,
              ) ? (
                <WellnubSelectInput
                  fullWidth
                  variant="outlined"
                  name="mode"
                  rules={{
                    required: 'Este campo es obligatorio',
                  }}
                  label="Modalidad"
                  disabled={
                    isEmpty(activeService) ||
                    (event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                      !isEditable)
                  }
                  control={control}
                  options={activeService?.modes ?? []}
                />
              ) : (
                <Typography variant="body2">
                  Modalidad:{' '}
                  {
                    {
                      online: 'En línea',
                      onsite: 'Presencial',
                      home: 'A domicilio',
                    }[event?.office?.mode ?? 'on']
                  }
                </Typography>
              )}
            </Grid>
          )}

          {eventType === 'other' && (
            <Grid item xs={12}>
              <WellnubTextInput
                fullWidth
                control={control}
                name="title"
                disabled={
                  event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                  !isEditable
                }
                variant="outlined"
                label="Titulo"
                rules={{ required: 'Este campo es obligatorio' }}
              />
            </Grid>
          )}

          {/* **** FECHA **** */}
          <Grid item xs={12} sm={6}>
            {['NORMAL', 'PENDING_BY_PATIENT', 'ACCEPTED'].includes(
              modalVariant,
            ) ? (
              <Controller
                control={control}
                name="date"
                rules={{
                  required: 'Este campo es obligatorio',
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      disabled={
                        event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                        !isEditable
                      }
                      label="Fecha"
                      format="dd/MM/yyyy"
                      value={value}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => onChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            ) : (
              <Typography variant="body2">
                Fecha:&nbsp;
                {format(
                  new Date(event?.start ?? null),
                  "dd/MM/yyyy 'a las' h:mm a",
                  { locale: es },
                )}
              </Typography>
            )}
          </Grid>

          {/* **** HORARIO **** */}
          <Grid item xs={12} sm={6}>
            {['NORMAL', 'PENDING_BY_PATIENT', 'ACCEPTED'].includes(
              modalVariant,
            ) && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="timeStart"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          fullWidth
                          ampm={true}
                          disabled={
                            isAllDayWatcher ||
                            (event.action ===
                              CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                              !isEditable)
                          }
                          variant="inline"
                          inputVariant="outlined"
                          label="Inicio"
                          value={value}
                          onChange={onChange}
                          keyboardIcon={<AlarmIcon />}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="timeEnd"
                    rules={{
                      validate: value => {
                        const start = new Date(2000, 10, 10, 0, 0, 0);
                        const end = new Date(2000, 10, 10, 0, 0, 0);
                        start.setHours(
                          getHours(timeStartWarcher),
                          getMinutes(timeStartWarcher),
                        );
                        end.setHours(getHours(value), getMinutes(value));
                        const addedDateEnd = add(end, {
                          minutes: selectedService?.minutesLenght ?? 0,
                        });
                        if (isAllDayWatcher) {
                          return true;
                        }
                        if (isEqual(start, addedDateEnd)) {
                          return 'Los horarios no deben de ser iguales';
                        }
                        if (isBefore(addedDateEnd, start)) {
                          return 'La hora de fin no debe ser menor o igual a la hora de inicio';
                        }
                        return true;
                      },
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          error={!!error}
                          fullWidth
                          disabled={
                            isAllDayWatcher ||
                            (event.action ===
                              CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                              !isEditable)
                          }
                          ampm={true}
                          variant="inline"
                          inputVariant="outlined"
                          label="Fin"
                          value={value}
                          onChange={e => {
                            setSelectedService({});
                            return onChange(e);
                          }}
                          keyboardIcon={<AlarmIcon />}
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {error?.message ?? ''}
                        </FormHelperText>
                      </MuiPickersUtilsProvider>
                    )}
                  />

                  {eventType !== 'other' && !isEmpty(selectedService) && (
                    <Typography variant="caption" style={{ color: 'green' }}>
                      Ajustamos tus horarios acorde a la duración del servicio
                      seleccionado
                    </Typography>
                  )}
                </Grid>
                {areIntervalsOverlaping &&
                  event.action === 'CREATE_CALENDAR_EVENT' && (
                    <Box textAlign="center" width="100%">
                      <Typography variant="caption" style={{ color: 'red' }}>
                        Este horario ya está ocupado por otra cita
                      </Typography>
                    </Box>
                  )}
              </Grid>
            )}
          </Grid>

          {/* **** CONSULTORIO **** */}
          {eventType !== 'other' && (
            <Grid item xs={12} sm={6}>
              {['NORMAL', 'PENDING_BY_PATIENT', 'ACCEPTED'].includes(
                modalVariant,
              ) ? (
                <WellnubSelectInput
                  fullWidth
                  label="Consultorio"
                  variant="outlined"
                  name="office"
                  control={control}
                  options={officeOptions}
                  disabled={
                    (event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                      !isEditable) ||
                    isEmpty(modeWatcher)
                  }
                />
              ) : (
                <Typography variant="body2">
                  Consultorio: {event.office?.name ?? ''}
                </Typography>
              )}
            </Grid>
          )}
          {eventType === 'other' && (
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Controller
                control={control}
                name="isAllDay"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    value={value}
                    onChange={onChange}
                    disabled={
                      event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                      !isEditable
                    }
                    label="Elegir todo el día"
                    control={<Checkbox color="primary" />}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      {/* **** PACIENTE **** */}
      {eventType !== 'other' && (
        <Box mb={2}>
          <Typography variant="h6">Datos del paciente</Typography>
          {['NORMAL', 'PENDING_BY_PATIENT', 'ACCEPTED'].includes(
            modalVariant,
          ) ? (
            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="patient"
                  rules={{
                    required: 'Este campo es obligatorio',
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="label">Buscar paciente</InputLabel>
                      <Select
                        label="Buscar paciete"
                        labelId="label"
                        value={value}
                        disabled={
                          event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT
                        }
                        error={!!error}
                        onChange={onChange}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                      >
                        <ListSubheader disableSticky>
                          Pacientes activos
                        </ListSubheader>
                        {[...patients]
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(patient => (
                            <MenuItem key={patient._id} value={patient._id}>
                              {patient.name} {patient.lastName}
                            </MenuItem>
                          ))}
                        <ListSubheader disableSticky>
                          Pacientes pendientes
                        </ListSubheader>
                        {[
                          ...new Set([
                            ...pendingPatients.map(item => item.patient._id),
                          ]),
                        ]
                          .map(
                            id =>
                              pendingPatients.find(p => p.patient._id === id)
                                ?.patient ?? {},
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(patient => (
                            <MenuItem key={patient._id} value={patient._id}>
                              {patient.name} {patient.lastName}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ color: 'red' }}>
                        {error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {['NORMAL'].includes(modalVariant) && (
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddPatientOpenHandler}
                    disabled={!isEmpty(patientWatcher)}
                  >
                    Agregar paciente
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Nombre: {event?.patient?.name ?? ''}{' '}
                  {event?.patient?.lastName ?? ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Email: {event?.patient?.email ?? ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Teléfono: {event?.patient?.phoneNumber ?? ''}
                </Typography>
              </Grid>
            </Grid>
          )}

          {/* **** COMENTARIOS **** */}
          {event.status !== 'rescheduled' && (
            <Grid container style={{ marginTop: 16 }}>
              <Grid item xs={12}>
                <Controller
                  name="comment"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 300,
                      message: 'El mensaje es muy largo',
                    },
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={value}
                        disabled={
                          event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT &&
                          !isEditable
                        }
                        onChange={onChange}
                        label="Comentarios (opcional)"
                        variant="outlined"
                        inputRef={{
                          maxLenght: 300,
                        }}
                        error={!!error}
                        helperText={
                          error
                            ? error.message
                            : `${value.length} de 300 caracteres`
                        }
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      )}

      {event.action === CALENDAR_EVENT_ACTION_TYPES.CREATE && (
        <Box textAlign="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoadingCreate}
          >
            Agregar cita
          </Button>
        </Box>
      )}

      {modalVariant === 'PENDING_BY_NUTRITIONIST' ? (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-evenly">
            <Button
              variant="outlined"
              color="primary"
              onClick={onOpenRejectWarningModalHandler}
              disabled={isAcceptingEvent}
            >
              Rechazar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirmAppointmentHandler}
              disabled={isAcceptingEvent}
            >
              Confirmar
            </Button>
          </Box>
        </>
      ) : (
        event.action === CALENDAR_EVENT_ACTION_TYPES.EDIT && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!isEditable || isLoadingUpdate}
                type="submit"
              >
                Actualizar
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setIsModalOpen(true);
                }}
                disabled={isLoadingDelete}
              >
                Eliminar evento
              </Button>
            </Box>
            <Dialog
              open={isModalOpen}
              fullScreen={isExtraSmallSize}
              classes={{
                paperWidthSm: classes.dialog,
              }}
              onClose={() => {
                setIsModalOpen(false);
              }}
            >
              <DialogTitle disableTypography classes={{ root: classes.header }}>
                <Typography variant="h6" className={classes.title}>
                  Confirmación
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Typography>
                  ¿Estás seguro que deseas eliminar el evento?
                </Typography>
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Button
                    variant="contained"
                    onClick={onDeleteEventHandler}
                    color="primary"
                  >
                    Confirmar
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </>
        )
      )}
      <Dialog
        open={isInactivePatientModalWarningOpen}
        fullScreen={isExtraSmallSize}
        classes={{
          paperWidthSm: classes.dialog,
        }}
        onClose={() => {
          setIsInactivePatientModalWarningOpen(false);
        }}
      >
        <DialogTitle disableTypography classes={{ root: classes.header }}>
          <Typography variant="h6" className={classes.title}>
            Aviso importante
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              setIsInactivePatientModalWarningOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Este paciente está dentro de tus pacientes{' '}
            <span style={{ fontFamily: 'Montserrat', fontWeight: 700 }}>
              inactivos
            </span>
            , por favor actívalo para poder agendar una consulta
          </Typography>
          <Box mt={2} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsInactivePatientModalWarningOpen(false);
              }}
            >
              Aceptar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default CalendarEventForm;
