import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    height: 300,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 7px 10px rgba(0, 0, 0, 0.3)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: ({ image }) => (image ? `url(${image})` : 'none'),
    '& .title': {
      padding: theme.spacing(2, 0),
      textAlign: 'center',
      textTransform: 'uppercase',
      opacity: 1,
      color: theme.palette.secondary.main,
      backgroundColor: `rgba(129, 178, 143, 0.6)`,
      transition: 'opacity 250ms linear',
    },
    '& .content': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: `rgba(11, 16, 21, .80)`,
      transform: 'translateY(-100%)',
      transition: 'opacity 250ms linear, transform 250ms ease',
    },
    '&:hover .title': {
      opacity: 0,
    },
    '&:hover .content': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  text: {
    maxWidth: '50ch',
  },
}));
