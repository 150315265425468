import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';

const InactivePatientWithAppointmentWarningPanel = () => {
  const dispatch = useDispatch();
  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: 'INACTIVE_PATIENT_WITH_APPOINTMENT_WARNING',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="h6" align="center">
        No puedes desactivar a un paciente con cita
      </Typography>
      <Typography align="center" style={{ marginTop: '16px' }}>
        Tienes una cita agendada para este paciente, si deseas desactivarlo por
        favor cancela la cita primero.
      </Typography>
      <Box mt={2} textAlign="center">
        <Button variant="contained" color="primary" onClick={onAcceptHandler}>
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default InactivePatientWithAppointmentWarningPanel;
