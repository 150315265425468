import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  block: {
    display: 'block',
  },
  item: {
    paddingBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  check: {
    marginRight: theme.spacing(1),
  },
}));
