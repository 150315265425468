import { Box, Container, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.light,
    paddingBottom: theme.spacing(2),
  },
}));

const VerificationCompletePage = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box pt={4} textAlign="center">
        <Typography variant="h3" className={classes.title}>
          ¡Gracias por verificar tu correo!
        </Typography>
        <Typography>
          Nuestro equipo ha recibido tu información, en breve nos pondremos en
          contacto contigo.
        </Typography>
      </Box>
    </Container>
  );
};

export default VerificationCompletePage;
