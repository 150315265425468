import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';

const CompleteProfileWarningPanel = () => {
  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    dispatch(
      setModalStatus({
        name: 'PROFILE_WARNING',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography
        variant="h5"
        style={{ textAlign: 'center', paddingBottom: 16 }}
      >
        ¡Bienvenid@ a Wellnub! - Gracias por unirte a la mejor plataforma para
        nutriólogos
      </Typography>
      <Typography
        variant="h6"
        style={{
          textTransform: 'uppercase',
          textAlign: 'center',
          paddingBottom: 16,
        }}
      >
        Te invitamos a detallar tu perfil profesional
      </Typography>
      <Typography align="center">
        Tú decides la información que quieras compartir. Sin embargo, mientras
        más información nos compartas, los pacientes podrán conocerte mejor.
      </Typography>
      <Box textAlign="center" mt={2}>
        <Button variant="contained" color="primary" onClick={onConfirmHandler}>
          Empezar!
        </Button>
      </Box>
    </Box>
  );
};

export default CompleteProfileWarningPanel;
