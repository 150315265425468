import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Typography} from "@material-ui/core";
import {setModalStatus} from "redux/slices/modal.slice";

const UnseenAppointmentsWarningPanel = () => {
  const { unseenAppointments } = useSelector(state => state.virtualAgenda);
  
  const dispatch = useDispatch();
  
  const onAcceptHandler = () => {
    dispatch(setModalStatus({
      isActive: false,
      name: 'UNSEEN_REJECTED_AND_CANCELLED_APPOINTMENTS_ALERT'
    }))
  }
  
  return (
    <Box>
      <Typography variant="h6" align="center" style={{marginBottom: 16}}>
        Tu agenda virtual presenta la siguiente actividad
      </Typography>
      {unseenAppointments.map(item => (
        <Typography variant="body2" align="center">
          {item?.patient?.name} {item?.patient?.lastName} - {{
            cancelled: 'Canceló la cita',
            rescheduled: 'Reagendó la cita'
          }[item.status]}
        </Typography>
      ))}
      <Box textAlign="center" mt={2}>
        <Button variant="contained" color="primary" onClick={onAcceptHandler}>
          Entendido
        </Button>
      </Box>
    </Box>
  )
};

export default UnseenAppointmentsWarningPanel;