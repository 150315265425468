import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    textAlign: 'center',
    color: '#0b1015',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  name: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(1.5),
  },
  photo: {
    width: '150px',
    height: '150px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  description: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  socialMediaWrapper: {
    marginTop: theme.spacing(1.5),
    justifyContent: 'center',
  },
  title: {},
}));
