import { useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import { Button } from 'components';

import { useCancelLinkRequest } from 'hooks/nutritionist.hook';

const CancelLinkRequest = () => {
  const { data } = useSelector(state => state.patient);

  const { mutate, isLoading } = useCancelLinkRequest();

  const onClickHandler = () => {
    mutate(data.requestId);
  };

  return (
    <Box>
      <Typography>
        ¿Confirmas cancelar la solicitud de este paciente?
      </Typography>
      <Typography>No se podrá revertir esta acción</Typography>
      <Box mt={2} textAlign="center">
        <Button
          onClick={onClickHandler}
          variant="contained"
          color="primary"
          loading={isLoading}
        >
          Confirmar
        </Button>
      </Box>
    </Box>
  );
};

export default CancelLinkRequest;
