import { useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import Button from '../../Button/Button.component';

import { useDeleteCard } from 'hooks/payments.hook';

const DeleteCardForm = () => {
  const { target } = useSelector(state => state.payments);

  const { mutate: deleteCard, isLoading } = useDeleteCard();

  const onClickHandler = event => {
    event.preventDefault();
    deleteCard(target.id);
  };

  return (
    <>
      <Typography>¿Estás seguro de eliminar esta forma de pago?</Typography>
      <Box pt={2} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={onClickHandler}
        >
          Confirmar
        </Button>
      </Box>
    </>
  );
};

export default DeleteCardForm;
