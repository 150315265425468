import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { VIRTUAL_AGENDA_RESTRICTIONS } from 'utils/constants';
import { isFuture } from 'date-fns';

export const virtualAgendaAppointmentsSelector = createSelector(
  [state => state.virtualAgenda.events],
  events => events,
);

export const virtualAgendaRestrictionSelector = createSelector(
  [
    state => state.virtualAgenda.events,
    state => state.user.profile.nutritionistProfile?.canUseAgenda,
  ],
  (events, canUseAgenda) => {
    if (canUseAgenda) {
      return VIRTUAL_AGENDA_RESTRICTIONS.LOW;
    } else {
      if (isEmpty(events)) {
        return VIRTUAL_AGENDA_RESTRICTIONS.HIGH;
      } else {
        return VIRTUAL_AGENDA_RESTRICTIONS.MID;
      }
    }
  },
);

export const upcommingAcceptedEventsSelector = createSelector(
  [state => state.virtualAgenda.events],
  events => {
    return events.filter(
      event =>
        event.status === 'accepted' && isFuture(new Date(event.startDate)),
    );
  },
);
