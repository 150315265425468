import { object, string } from 'yup';

const REQUIRED_MESSAGE = 'Este campo es obligatorio';

export const FormSchema = object({
  name: string().when('mode', {
    is: mode => ['online', 'onsite'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
  mode: string()
    .oneOf(
      ['online', 'onsite', 'home'],
      'Debes seleccionar al menos una opción',
    )
    .required(REQUIRED_MESSAGE),
  link: string().when('mode', {
    is: mode => ['online'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
  street: string().when('mode', {
    is: mode => ['onsite'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
  neighborhood: string().when('mode', {
    is: mode => ['onsite'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
  hall: string().when('mode', {
    is: mode => ['onsite'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
  city: string().when('mode', {
    is: mode => ['onsite'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
  state: string().when('mode', {
    is: mode => ['onsite'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
  zipCode: string().when('mode', {
    is: mode => ['onsite'].includes(mode),
    then: string().required(REQUIRED_MESSAGE),
  }),
});
