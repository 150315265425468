import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';

import { useDeleteNutritionistOffice } from 'hooks/nutritionist.hook';

import { setModalStatus } from 'redux/slices/modal.slice';

const DeleteNutritionistOfficePanel = () => {
  const { target } = useSelector(state => state.forms.profile);

  const dispatch = useDispatch();

  const { mutate: deleteOffice, isLoading } = useDeleteNutritionistOffice();

  const onConfirmHandler = () => {
    deleteOffice(target._id);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'DELETE_NUTRITIONIST_OFFICE',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Estás seguro que deseas borrar este consultorio?</Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmHandler}
          disabled={isLoading}
        >
          Confirmar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteNutritionistOfficePanel;
