import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { upcommingAcceptedEventsSelector } from 'redux/slices/virtualAgenda/virtualAgenda.selector';
import { format } from 'date-fns';

const VirtualAgendaBlockedPage = () => {
  const upcommingEvents = useSelector(upcommingAcceptedEventsSelector);

  return (
    <Box pt={2}>
      <Typography>
        Tu agenda virtual fue desactivada, a continuación podrás consultar las
        citas que ya tenías agendadas con tus pacientes.
      </Typography>
      <br />
      <Typography variant="h6">Citas confirmadas:</Typography>
      <br />
      <ul>
        {upcommingEvents.map(item => (
          <li style={{ paddingBottom: 16 }}>
            <Typography>
              Paciente: {item.patient.name} {item.patient.lastName}
            </Typography>
            <Typography>Servicio: {item.service.service.name}</Typography>
            <Typography>Consultorio: {item.office.name}</Typography>
            <Typography>
              Fecha: {format(new Date(item.startDate), 'dd/MM/yyyy')}
            </Typography>
          </li>
        ))}
      </ul>
      <br />
      <Typography>
        Si tienes alguna duda no dudes en comunicarte con nosotros.
      </Typography>
    </Box>
  );
};

export default VirtualAgendaBlockedPage;
