import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dropArea: {
    padding: theme.spacing(1),
    position: 'relative',
    height: 370,
    cursor: 'pointer',
    border: `1px dashed ${theme.palette.primary.light}`,
    '& .placeholder': {
      position: 'absolute',
      display: 'flex',
      top: 8,
      left: 8,
      right: 8,
      bottom: 8,
      opacity: 0.5,
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'opacity 150ms linear',
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(11, 16, 21, .70)',
    },
    '&:hover .placeholder': {
      opacity: 1,
    },
  },
  preview: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: ({ url }) => (url ? `url(${url})` : 'none'),
  },
  progress: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
