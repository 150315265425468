import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inbodyPasses: [],
  payments: [],
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setInbodyPassesHistory: (state, action) => {
      state.inbodyPasses = action.payload;
    },
    setPaymentsHistory: (state, action) => {
      state.payments = action.payload;
    },
  },
});

export const { setInbodyPassesHistory, setPaymentsHistory } =
  historySlice.actions;

export const HistoryReducer = historySlice.reducer;
