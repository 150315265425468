import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: 8,
    height: '100%',
  },
  name: {
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
  },
  passes: {
    textAlign: 'center',
    fontSize: 13,
  },
  button: {
    fontSize: 12,
  },
  primary: {
    color: theme.palette.action.main,
  },
  secondary: {
    color: theme.palette.primary.light,
  },
  center: {
    textAlign: 'center',
  },
  chip: {
    backgroundColor: '#ff4262',
    color: '#f2f2f2',
    transition: 'transform linear 150ms',
    marginBottom: theme.spacing(1),
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#ff4262',
    },
  },
  chipNewInbody: {
    backgroundColor: '#A0D1D8',
    color: '#0b1015',
    transition: 'transform linear 150ms',
    marginBottom: theme.spacing(1),
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#A0D1D8',
    },
  },
  chipAlter: {
    backgroundColor: '#9CE17F',
    color: '#009d48',
    transition: 'transform linear 150ms',
    marginBottom: theme.spacing(1),
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#9CE17F',
    },
  },
  badge: {
    backgroundColor: '#4DACBD',
    color: '#275962',
    marginBottom: theme.spacing(1),
  },
  badgePasses: {
    backgroundColor: '#A5DEE3',
    color: '#275962',
    marginBottom: theme.spacing(1),
  },
  badgeNoAssist: {
    backgroundColor: '#E9425E',
    color: '#7F2535',
    marginBottom: theme.spacing(1),
  },
}));
