import { useSelector } from 'react-redux';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import FaceIcon from '@material-ui/icons/Face';
import { AppBar, Tab, Tabs, useMediaQuery } from '@material-ui/core';

import { patientSelector } from 'redux/slices/patient/patient.selector';

import { useStyles } from './PatientNavigation.styles';

const PatientNavigation = () => {
  const patient = useSelector(patientSelector);
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const isExtraSmallSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const getTabValue = () => {
    if (pathname.includes('nutrition')) return 'nutrition';
    if (pathname.includes('progress')) return 'progress';
    if (pathname.includes('medical-history')) return 'medical-history';
    return 'progress';
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      classes={{ root: classes.appBar }}
    >
      <Tabs variant="fullWidth" value={getTabValue()} indicatorColor="primary">
        {!isExtraSmallSize && (
          <Tab
            disabled
            label={`${patient.name} ${patient.lastName}`}
            icon={<FaceIcon />}
          />
        )}
        <Tab
          value="nutrition"
          component={Link}
          label="Planes nutricionales"
          to={`${url}/nutrition`}
          classes={{
            wrapper: classes.tab,
          }}
        />
        <Tab
          value="progress"
          component={Link}
          label="Progreso"
          to={`${url}/progress`}
          classes={{
            wrapper: classes.tab,
          }}
        />
        <Tab
          value="medical-history"
          component={Link}
          label="Historial Médico"
          to={`${url}/medical-history`}
          classes={{
            wrapper: classes.tab,
          }}
        />
      </Tabs>
    </AppBar>
  );
};

export default PatientNavigation;
